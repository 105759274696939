import { useState, useEffect } from 'react';

import _ from 'lodash';

import { getPageOfSquads, getSquadDevices } from '../../actions/squad-actions';

import Button from '../misc/Button';

const EXAMPLE_DATA = [
    {id: '1', name: 'Dev One'},
    {id: '2', name: 'Dev Two'},
    {id: '3', name: 'Dev Three'},
    {id: '4', name: 'Dev Four'},
    {id: '5', name: 'Dev Five'},
    {id: '6', name: 'Dev Six'},
    {id: '7', name: 'Dev Seven'},
    {id: '8', name: 'Dev Eight'},
    {id: '9', name: 'Dev Nine'},
]

/**
 * Selector modal component for use when selecting a squad.
 *
 * Squad array format::
 *
 *     [
 *         {id: <ID>, name: <NAME>},
 *         ...
 *     ]
 *
 * @param props {Object}
 *      - allDevices: {Array} Devices avaliable for selection as part of the squad.
 *      - squad: {Array} Current squad selection so items can be pre-selected.
 *      - savedSquad: {String} ID of a selected pre-configured squad.
 *      - idKey: {String} Key to use as the ID for the device.
 *      - nameKey: {String} Key to use as the display name for the device.
 *      - numCols: {Integer} Number of columns to use when displayed in a grid format.
 *      - onSquadSelectChange: {Callable} Gets called when window is opened and closed.
 *      - onSquad: {Callable} Gets called when a squad selection has been confirmed.
 *      - onSavedSquad: {Callable} When a pre-configured squad is (de)selected.
 */
export default function SquadSelector(props = {}) {
    const {
        allDevices = EXAMPLE_DATA,
        squad = [],
        savedSquad = null,
        event = null,
        idKey = 'id',
        nameKey = 'name',
        onSquadSelectChange = (_sel) => {},
        onSquad = (_squad) => {},
        onSavedSquad = (_ss) => {},
    } = props;

    const basicSort = (a, b) => {
        let a_ = parseInt(a[nameKey]);
        let b_ = parseInt(b[nameKey]);
        if (a_ === NaN) { a_ = a[nameKey] }
        if (b_ === NaN) { b_ = b[nameKey] }
        return a_ === b_ ? 0 : a_ > b_ ? 1 : -1;
    };
    const basicFilter = (a) => {
        return a && a[idKey] && a[nameKey];
    };

    const [isOpen, setIsOpen] = useState(false);
    const [curSel, setCurSel] = useState(squad);
    const [confSquads, setConfSquads] = useState([]);
    const [curConfSquad, setCurConfSquad] = useState(savedSquad);
    const [devicesToShow, setDevicesToShow] = useState(
        _.uniqWith(
            allDevices.filter(basicFilter).sort(basicSort),
            (a, b) => a[idKey] === b[idKey]
        )
    );

    const setDtsIdsFunc = () => devicesToShow.map((d) => d[idKey]);
    const [dtsIds, setDtsIds] = useState(setDtsIdsFunc());
    const setCurIdsFunc = () => curSel.filter((d) => !!d).map((d) => d[idKey]);
    const [curIds, setCurIds] = useState(setCurIdsFunc());

    const deviceClicked = (id, newCheckState) => {
        newCheckState = !newCheckState;
        const obj = devicesToShow.find((x) => x[idKey] === id);
        if (newCheckState && !curIds.includes(id)) {
            setCurSel([].concat(curSel, [obj]));
        }
        else if (!newCheckState && curIds.includes(id)) {
            setCurSel(curSel.filter((x) => x?.[idKey] !== id));
        }
        if (curConfSquad !== '') {
            setCurConfSquad('');
        }
    };
    const changeModalState = (state) => {
        setIsOpen(state);
        onSquadSelectChange(state);
    };

    const handleOk = () => {
        changeModalState(false);
        onSquad(curSel);
        onSavedSquad(curConfSquad);
    };

    const onClear = () => {
        setCurSel([]);
        setCurConfSquad(null)
        onSquad([]);
    };

    const addMissing = (newBatch) => {
        const missingDevices = newBatch.filter(
            basicFilter
        ).filter((d) => (
            !dtsIds.includes(d[idKey])
        ));
        if (missingDevices.length) {
            setDevicesToShow(devicesToShow.concat(missingDevices).sort(basicSort));
        }
    };

    

    // Loading of squad information happends here when it is selected from the drop down
    const onSquadOptChange = (e) => {
        const found = confSquads.find((x) => x.id === e.target.value);
        setCurConfSquad(found ?? null);
        if (found) {
            console.debug('SS: Requesting devices for squad', found.id)
            getSquadDevices(found.id).then((res) => {
                res = res.map((x) => ({
                    ...x,
                    [idKey]: x.nativeDeviceId.replace(/:/g, ''),
                    [nameKey]: x.friendlyName,
                })).map((dev) => { // check against the event's recruits
                    if(event?.recruits.length > 0) {
                        return (event.recruits.find(({id}) => id === dev.currentRecruit) ? dev: undefined);
                    }
                    else {
                        return dev;
                    }
                }).filter(val => val !== null && val !== undefined);
                console.debug('SS: Got devices based on squad:', res);
                setCurSel(res);
                addMissing(res);
                //filter by event
                console.log(`Result: ${JSON.stringify(res)}`);
            });
        }
    };

    /*----------------------------------------*/

    /* Query squad list on mount */
    useEffect(() => {
        getPageOfSquads(0, {
            limit: 999,
            deleted: false,
            includeMeta: true,
        }).then((res) => {
            console.debug('SS: Got squads', res.data);
            setConfSquads(res.data.filter((x) => event.squads.find((y) => y.id == x.id)));
        }).catch((err) => {
            console.error('SS: Failed to query squds:', err);
        });
    }, [event]);

    useEffect(() => setDtsIds(setDtsIdsFunc()), [devicesToShow]);
    useEffect(() => setCurIds(setCurIdsFunc()), [curSel]);

    /* When new devices are detected add new to the list */
    useEffect(() => {
        addMissing(allDevices);
    }, [allDevices]);

    /* squad changes outside, change it here */
    useEffect(() => {
        setCurSel(squad);
    }, [squad]);

    /* saved squad id changes outside, change it here */
    useEffect(() => {
        setCurConfSquad(savedSquad);
    }, [savedSquad]);

    /* When dialog opens, ensure state matches that of outside forces */
    useEffect(() => {
        if (isOpen) {
            setCurSel(squad);
            setCurConfSquad(savedSquad);
        }
    }, [isOpen]);

    /*----------------------------------------*/

    const confSquadOpts = [{id: '', name: '--- Squads ---'}].concat(confSquads).map((x, i) => (
        <option key={`squad-opts-${i}`} value={x.id}>
            {x.name}
        </option>
    ));

    return (
        <div className="container">
            <div className="container has-text-centered">
                <div className="select">
                    <select value={curConfSquad?.id ?? ''} onChange={onSquadOptChange}>
                        {confSquadOpts}
                    </select>
                </div>
            </div>

            <div className="block"></div>

            <div className="container has-text-centered">
                {
                    devicesToShow.map((d, di) => (
                        <span className="p-4">
                            <Button
                                key={`squad-sel-${di}-${d[nameKey]}`}
                                activeColor={curIds.includes(d[idKey]) ? 'is-primary' : ''}
                                value={curIds.includes(d[idKey])}
                                onClick={(v) => deviceClicked(d[idKey], v)}
                            >
                            {d[nameKey]}
                            </Button>
                        </span>
                    ))
                }
            </div>

            <div className="block"></div>

            <footer className="card-footer">
                <Button classList={["m-2"]} activeColor="is-success" onClick={handleOk}>
                    Set
                </Button>
                <Button classList={["m-2"]} onClick={onClear}>
                    Clear
                </Button>
            </footer>
        </div>
    );
}
