export const NO_DEVICE_SELECTED = -1;
export const MISSING_DATA_STRING = "XXX";
export const RISK_LEVEL_COLOR_INVALID = "#d0d0d0";
export const RISK_LEVEL_COLORS = {
    0: "#707070", // Dark Grey
    1: "#009210", // Green
    2: "#BBBB00", // Yellow
    3: "#FF0000", // Red
    4: "#000000", // Black
    5: "#1E90FF", // Dodger Blue
    slatesafety: "#f2692d", // SlateSafety ORANGE
    inactive: RISK_LEVEL_COLOR_INVALID,
    poi: "#00F", // Dodger Blue
    commhub: "#00F",
};
export const REALTIME_INACTIVE_TIME_MS = loadInactiveTime();
export const SELECTED_DEVICE_ZOOM_LEVEL = 15;
export const SELECTED_ICON_SIZE = 0.55;
export const NOT_SELECTED_ICON_OPACITY = 0.55;
export const DEFAULT_ICON_SIZE = 0.30;
export const DEG_C = "\u{2103}";
export const DEG_F = "\u{2109}"
export const TIMESTAMP_KEY = 'timeOfReceipt';
export const SINCE_LAST_UPDATE_BUFFER = 5500;
export const DEFAULT_SHOW_DEVICE_TYPES = ['oban', 'watch']
/**
 * @param envVar {String}       Value from environment variable.
 * @param defaultVal {String}   Fallback value to use in place of the environment variable.
 *
 * @returns {Integer}           Value in milliseconds converted from minutes value.
 */
function fromMinutes(envVar, defaultVal) {
    envVar = (!!envVar) ? envVar : defaultVal;
    const intVal = parseInt(envVar, 10)
    return intVal * 60 * 1000;
}

/**
 * @param envVar {String}       Value from environment variable.
 * @param defaultVal {String}   Fallback value to use in place of the environment variable.
 *
 * @returns {Integer}           Value in milliseconds converted from minutes value.
 */
function fromMilliseconds(envVar, defaultVal) {
    envVar = (!!envVar) ? envVar : defaultVal;
    const intVal = parseInt(envVar, 10)
    return intVal;
}

/*
 * Special process for loading inactive time to meet some various needs
 */
function loadInactiveTime() {
    let value = fromMilliseconds(window._env_.REALTIME_INACTIVE_TIME_MS, '0')
    if (value === NaN || value == 0) {
        value = fromMinutes(window._env_.REALTIME_INACTIVE_TIME_MIN, '2');
    }
    return value;
}

export function riskToColor(risk, { isInactive = false, deviceType = null } = {}) {
    if (deviceType && deviceType in RISK_LEVEL_COLORS) {
        return (isInactive) ? RISK_LEVEL_COLOR_INVALID : RISK_LEVEL_COLORS[deviceType];
    }
    if (isInactive || risk === undefined) {
        return RISK_LEVEL_COLOR_INVALID;
    }
    const key = risk;
    const riskColor = RISK_LEVEL_COLORS[key];
    return riskColor || RISK_LEVEL_COLOR_INVALID;
}
