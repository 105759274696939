import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleDoubleRight, faAngleLeft, faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";

import Button from './Button';

export default class SelectList extends React.Component {
    static defaultProps = {
        label: "Select List",
        list: [
            { value: "val0", display: "Value 0" },
            { value: "val1", display: "Value 1" },
            { value: "val2", display: "Value 2" },
            { value: "val3", display: "Value 3" },
            { value: "val4", display: "Value 4" },
            { value: "val5", display: "Value 5" },
            { value: "val6", display: "Value 6" },
            { value: "val7", display: "Value 7" },
            { value: "val8", display: "Value 8" },
            { value: "val9", display: "Value 9" },
            { value: "val10", display: "Value 10" },
            { value: "val11", display: "Value 11" },
            { value: "val12", display: "Value 12" },
            { value: "val13", display: "Value 13" },
            { value: "val14", display: "Value 14" },
            { value: "val15", display: "Value 15" }
        ],
        selected: [
            "val1",
            "val2",
            "val3"
        ],
        onChange: (selectedIds, notSelectedIds, list) => { },
        onSelect: selectedId => {},
        onUnselect: unselectedId => {},
        onSelectAll: () => {},
        onUnselectAll: () => {}
    }

    constructor(props) {
        super(props);

        this.state = {
            selected: [],
            notSelected: []
        }
        
        this.handleChange = this.handleChange.bind(this);
        
        this.selectAllItems = this.selectAllItems.bind(this);
        this.unselectAllItems = this.unselectAllItems.bind(this);

        this.selectItem = this.selectItem.bind(this);
        this.unselectItem = this.unselectItem.bind(this);
    }

    handleChange() { 
        const selectedIds = this.state.selected?.map(v => v.value);
        const notSelectedIds = this.state.notSelected?.map(v => v.value);
        const list = [...this.state.selected, ...this.state.notSelected];
        this.props.onChange(selectedIds, notSelectedIds, list);
    }

    selectAllItems(e) {
        e.preventDefault();
        this.props.onSelectAll();
    }

    unselectAllItems(e) {
        e.preventDefault();
        this.props.onUnselectAll();
    }

    selectItem(value, e) {
        e.preventDefault();
        this.props.onSelect(value);
    }

    unselectItem(value, e) {
        e.preventDefault();
        this.props.onUnselect(value);
    }

    render() {
        const notSelected = [];
        const selected = [];

        this.props.list?.forEach(v => {
            if (this.props.selected?.includes(v.value)) {
                selected.push(v);
            } else {
                notSelected.push(v);
            }
        });

        const notSelectedList = notSelected?.map((v, i) => {
            return (
                <tr key={`select-list-not-selected-${v.value}-${i}`}>
                    <td>{v?.display || v.value}</td>
                    <td>
                        <button className="button is-small has-text-success" title="Select" onClick={e => this.selectItem(v.value, e)}><FontAwesomeIcon icon={faAngleRight} /></button>
                    </td>
                </tr>
            )
        }) || <tr><th>None</th><td></td></tr>;

        const selectedList = selected?.map((v, i) => {
            return (
                <tr key={`select-list-not-selected-${v.value}-${i}`}>
                    <td>
                        <button className="button is-small has-text-danger" title="Unselect" onClick={e => this.unselectItem(v.value, e)}><FontAwesomeIcon icon={faAngleLeft} /></button>
                    </td>
                    <td>{v?.display || v.value}</td>
                </tr>
            )
        }) || <tr><th>None</th><td></td></tr>;

        return (
            <div className="field">
                <label className="label">{this.props.label}</label>

                <div className="control">
                    <div className="textarea has-fixed-size scroll-box">


                        <div className="columns">
                            <div className="column is-half">
                                <table className="table is-fullwidth is-bordered is-striped is-hoverable has-text-centered">
                                    <thead >
                                        <tr>
                                            <th>Not Selected [{notSelected?.length}]</th>
                                            <th><button className="button is-small has-text-success" title="Select All" onClick={this.selectAllItems}><FontAwesomeIcon icon={faAngleDoubleRight} /></button></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {notSelectedList}
                                    </tbody>
                                </table>
                            </div>

                            <div className="column is-half">
                                <table className="table is-fullwidth is-bordered is-striped is-hoverable has-text-centered">
                                    <thead>
                                        <tr>
                                            <th><button className="button is-small has-text-danger" title="Unselect All" onClick={this.unselectAllItems}><FontAwesomeIcon icon={faAngleDoubleLeft} /></button></th>
                                            <th>Selected [{selected?.length}]</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {selectedList}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
