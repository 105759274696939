import React, { useRef, useEffect } from "react";
import mapboxgl, {AttributionControl, NavigationControl, ScaleControl} from "mapbox-gl";
import {
    NO_DEVICE_SELECTED,
    SELECTED_DEVICE_ZOOM_LEVEL,
} from "./real-time-constants";

import {ENV} from "../../util/env-util";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = "pk.eyJ1IjoiZGFyZWxsYW5vNiIsImEiOiJjazV6ZnFlaXgwZWV5M25wN3RzdG1tZzlzIn0.kDodoQGVmuwbj2YWJGm5PQ";

// 37.3215 -96.4106 3.77

const g_mapStyle = (ENV.mapsEnable === '1')
    ? `https://${ENV.mapsURL}/v1/style/${ENV.mapsStyle}.json`
    : 'mapbox://styles/mapbox/satellite-streets-v11';
const g_mapStyleEmpty = (ENV.mapsEnable === '1')
    ? `https://${ENV.mapsURL}/v1/style/empty.json`
    : 'mapbox://styles/mapbox/empty-v8';


/*------------------------------------------------------------------------------------------------*/

function mapMoveSync(map1, map2) {
    map2.current?.jumpTo?.({
        center: map1.current.getCenter(),
        zoom: map1.current.getZoom(),
        bearing: map1.current.getBearing(),
        pitch: map1.current.getPitch(),
    })
}

/*------------------------------------------------------------------------------------------------*/
/*
 * Map callbacks
 */

function onMapLoad(map, cb) {
    cb(map.current);
}

function onMapMove(map, cb) {
    const longitude = map.current.getCenter().lng.toFixed(4);
    const latitude = map.current.getCenter().lat.toFixed(4);
    const zoom = map.current.getZoom().toFixed(2);

    cb(longitude, latitude, zoom, map.current);
}

function onMapMouse(map, direction) {
    if (direction === 'enter') {
        map.current.getCanvas().style.cursor = 'pointer';
    }
    else if (direction === 'leave') {
        map.current.getCanvas().style.cursor = '';
    }
}

function onMapClick(mapRef, e, props) {
    const map = mapRef.current;
    const {
        selectedDevice = NO_DEVICE_SELECTED,
        deviceData = [],
        menuCollapsed = true,
        menuCollapsedCallback = ((_e, _v) => {}),
        selectDeviceCallback = ((_d, _i) => {}),
        unselectDeviceCallback = (() => {}),
    } = props;

    const coordinates = e.features[0].geometry.coordinates.slice();
    const deviceId = e.features[0].properties.deviceId;

    if (deviceId !== NO_DEVICE_SELECTED) {
        // Something selected
        if (deviceId !== selectedDevice) {
            // New device selected
            const device = deviceData.find(dev => dev.id === deviceId);
            selectDeviceCallback(device, deviceId);
        }
        if (menuCollapsed) {
            // Menu was collapsed
            menuCollapsedCallback({}, false);
        }
        map.flyTo({
            center: coordinates,
            zoom: SELECTED_DEVICE_ZOOM_LEVEL,
        })
    }
    else {
        // Deselected
        if (deviceId !== selectedDevice) {
            unselectDeviceCallback();
        }
    }
}

/*------------------------------------------------------------------------------------------------*/

export default function MapboxMap(props) {
    const {
        // Initial settings
        startingLongitude = -96.41,
        startingLatitude = 37.32,
        startingZoom = 3.75,
        mapStyle = g_mapStyle,
        mapHeight = "100vh",
        mapWidth = "100vw",
        fullscreen = false,
        showScale = true,
        scaleMaxWidth = 80,
        scaleUnits = "metric",
        styleClasses = ["send-to-background"],
        // Callbacks
        handleMapInitialize = ((_map) => {}),
        handleMapOnLoad = ((_map) => {}),
        handleMove = ((_longitude, _latitude, _zoom, _map) => {}),
        menuCollapsedCallback = ((_e, _v) => {}), // TODO change to wp click
        selectDeviceCallback = ((_d, _i) => {}), // TODO change to wp click
        unselectDeviceCallback = (() => {}), // TODO change to wp click
        // Data
        selectedDevice = NO_DEVICE_SELECTED,
        deviceData = [],
        menuCollapsed = false, // TODO shouldn't need this
    } = props;

    const mapContainer = useRef(null);
    const map = useRef(null);
    const mapBase = useRef(null);
    const scale = useRef(null);
    const deviceDataRef = useRef(deviceData);

    /*----------------------------------------*/

    useEffect(() => {
        if (map.current) {
            return;
        }

        const startingCenter = [startingLongitude, startingLatitude];

        mapBase.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapStyle,
            center: startingCenter,
            zoom: startingZoom,
        });

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: g_mapStyleEmpty,
            center: startingCenter,
            zoom: startingZoom,
        });

        map.current.on('load', () => {
            onMapLoad(map, handleMapOnLoad);
        });
        map.current.on('move', () => {
            mapMoveSync(map, mapBase)
            onMapMove(map, handleMove);
        });

        map.current.on('click', 'marker-icons', (e) => {
            onMapClick(map, e, {
                selectedDevice,
                deviceData: deviceDataRef.current,
                menuCollapsed,
                menuCollapsedCallback,
                selectDeviceCallback,
                unselectDeviceCallback,
            })
        })

        map.current.on('mouseenter', 'marker-icons', () => {
            onMapMouse(map, 'enter');
        });
        map.current.on('mouseleave', 'marker-icons', () => {
            onMapMouse(map, 'leave');
        });

        if (showScale) {
            scale.current = new ScaleControl({
                maxWidth: scaleMaxWidth,
                units: scaleUnits
            });
            map.current.addControl(scale.current, 'bottom-right');
            map.current.addControl(new NavigationControl(), 'bottom-right');
            map.current.addControl(new AttributionControl({compact: true}), 'bottom-right');
        }

        handleMapInitialize(map.current);
    }, []);

    useEffect(() => {
        deviceDataRef.current = deviceData;
    }, [deviceData]);

    /*----------------------------------------*/

    const style = fullscreen ? {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "93vh"
    } : {
        width: mapWidth,
        height: mapHeight
    };

    const classes = ["mapbox-container", ...styleClasses];

    return (
        <div
            style={style}
            ref={mapContainer}
            className={classes.join(" ")}
        />
    );
}
