import React from "react";

import Button from "./Button";

export default class ConfirmationModal extends React.Component {
  static defaultProps = {
    confirmationText: (
      <div className="is-size-5">Are you sure about that?"</div>
    ),
    confirmButton: {
      text: "Confirm",
      color: "is-success",
      classes: [],
    },
    confirmButtonAction: (e) => console.log("Confirm Button Clicked!"),
    cancelButton: {
      text: "Cancel",
      color: undefined,
      classes: [],
    },
    cancelButtonAction: (e) => console.log("Cancel Button Clicked!"), // Generally, this will switch active to false.
    active: true,
    closeButton: true,
  };

  render() {
    const confirmClassList = [
      "mx-1",
      "level-item",
      ...(this.props.confirmButton?.classes ?? []),
      "is-half",
    ];

    const cancelClassList = [
      "mx-1",
      "level-item",
      ...(this.props.cancelButton?.classes ?? []),
      "is-half",
    ];

    return (
      <div className={`modal${this.props.active ? " is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="card p-5">
            <div className="tile is-ancestor">
              <div className="tile is-parent is-vertical">
                <div className="tile is-child">
                  {this.props.confirmationText}
                </div>
                <div className="tile is-parent">
                  <Button
                    activeColor={this.props.confirmButton?.color}
                    classList={confirmClassList}
                    onClick={() =>
                      this.props.confirmButtonAction({
                        preventDefault: () => {},
                      })
                    }
                  >
                    {this.props.confirmButton.text}
                  </Button>
                  <Button
                    activeColor={this.props.cancelButton?.color}
                    classList={cancelClassList}
                    onClick={() =>
                      this.props.cancelButtonAction({
                        preventDefault: () => {},
                      })
                    }
                  >
                    {this.props.cancelButton.text}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.closeButton ? (
          <Button
            type="modal-close"
            classList={["is-large"]}
            onClick={() =>
              this.props.cancelButtonAction({ preventDefault: () => {} })
            }
          ></Button>
        ) : null}
      </div>
    );
  }
}
