import React from "react";

export default class HrapsLogo extends React.Component {
    render() {
        return (
            <div className="hraps-logo">
                HRAPS
            </div>
        );
    }
}