import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import FormConfirmModal from '../../misc/FormConfirmModal';
import SimpleErrorModal from '../../misc/SimpleErrorModal';
import ExtraDetails from '../../misc/ExtraDetails';
import Button from '../../misc/Button';

import { createPoi, getPoi, updatePoi } from "../../../actions/poi-actions";
import { getOrganizations } from "../../../actions/organization-actions";

import _ from 'lodash';

function maybeFloat(inval) {
    if (inval === '' || inval == null) {
        return null;
    }
    return parseFloat(inval);
}

function PoiEdit(props = {}) {
    const {
        isUpdate = false,
    } = props;

    const poiId = props.match.params.poiId;

    // Form fields
    const [isPhysical, setIsPhysical] = useState(false);
    const [nativeDeviceId, setNativeDeviceId] = useState('');
    const [org, setOrg] = useState('');
    const [poiType, setPoiType] = useState('');
    const [friendlyName, setFriendlyName] = useState('');
    const [lastLatitude, setLastLatitude] = useState('');
    const [lastLongitude, setLastLongitude] = useState('');
    const [isDefault, setIsDefault] = useState(false);

    // Part of model, inverse of isPhysical
    const [isVirtual, setIsVirtual] = useState(true);

    // Other states
    const [orgs, setOrgs] = useState([]);
    const [poiTypes, setPoiTypes] = useState([
        ['waterstation', 'Waterstation'],
        ['startPoint', 'Start Point'],
        ['endPoint', 'End Point'],
    ]);
    const [modal, setModal] = useState(null);

    const setters = {
        isPhysical: setIsPhysical,
        nativeDeviceId: setNativeDeviceId,
        org: setOrg,
        poiType: setPoiType,
        friendlyName: setFriendlyName,
        lastLatitude: setLastLatitude,
        lastLongitude: setLastLongitude,
        isDefault: setIsDefault,
    };

    const handleInputChange = (e) => {
        const val = (e.target.type === 'checkbox') ? e.target.checked : e.target.value;
        console.debug(`${e.target.name} change to ${val}`)
        setters[e.target.name](val);
    };

    const onSubmitClicked = (e) => {
        e.preventDefault();

        const data = {
            nativeDeviceId,
            org,
            poiType,
            isVirtual,
            friendlyName: friendlyName !== '' ? friendlyName : null,
            lastLatitude: maybeFloat(lastLatitude),
            lastLongitude: maybeFloat(lastLongitude),
            isDefault,
        }

        const labels = {
            nativeDeviceId: 'MAC Address',
            org: 'Organization',
            poiType: 'POI Type',
            isPhysical: 'Is Physical',
            friendlyName: 'Friendly Name',
            lastLatitude: 'Last Latitude',
            lastLongitude: 'Last Longitude',
            isDefault: 'Defaulted POI',
        };

        let formData = { ...data, isPhysical };
        delete formData.isVirtual;
        formData = Object.fromEntries(Object.entries(formData).map(([k, v]) => [k, _.toString(v)]));

        setModal(<FormConfirmModal
            msg='Confirm form submission?'
            data={formData}
            labels={labels}
            onConfirm={() => {
                ((isUpdate) ? updatePoi(poiId, data) : createPoi(data)).then(_v => {
                    props.history.goBack();
                }).catch(err => {
                    setModal(<SimpleErrorModal
                        msg={`Failed to submit form data: ${err}`}
                        onClose={() => setModal(null)}
                    />);
                });
            }}
            onCancel={() => setModal(null)}
        />);
    };

    /*----------------------------------------*/

    useEffect(() => {
        getOrganizations().then((res) => {
            setOrgs(res.map(x => [x, x]));
        }).catch((err) => {
            console.error('Failed to query orgs:', err);
        });

        if (isUpdate) {
            getPoi(poiId).then((res) => {
                console.debug('Got poi data', res);
                Object.entries(res).forEach(([k, v]) => {
                    if (k === 'isVirtual') {
                        setIsPhysical(!v);
                    }
                    else if (k in setters) {
                        setters[k](v);
                    }
                })
            }).catch((err) => {
                console.error('Failed to get POI data:', err);
            });
        }
    }, []);

    useEffect(() => setIsVirtual(!isPhysical), [isPhysical]);

    /*----------------------------------------*/

    const submitButtonName = (isUpdate) ? 'Update' : 'Create';

    const deviceIdLabel = (isPhysical) ? 'MAC Address' : 'POI ID';

    const required = (<span style={{color: 'red'}}>*</span>);

    const poiTypeOpts = poiTypes.map((t, i) => (
        <option key={`poi-type-${i}`} value={t[0]}>{t[1]}</option>
    ));
    const orgOpts = orgs.map((t, i) => (
        <option key={`org-opt-${i}`} value={t[0]}>{t[1]}</option>
    ));

    return (<>
            <div>
                <div className="block"></div>

                <div className="container">
                    <div className="field">
                        <label className="label">{deviceIdLabel}: {required}</label>
                        <div className="control">
                            <input
                                type="text"
                                className={ ["input"].join(" ") }
                                name="nativeDeviceId"
                                value={nativeDeviceId}
                                onChange={handleInputChange}
                                />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Org {required}</label>
                        <div className="control">
                            <div className="select is-fullwidth">
                                <select name="org"
                                    value={org}
                                    onChange={handleInputChange}>
                                    <option disabled={true} value="">Select Organization</option>
                                    {orgOpts}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">POI Type {required}</label>
                        <div className="control">
                            <div className="select is-fullwidth">
                                <select name="poiType"
                                    value={poiType}
                                    onChange={handleInputChange}>
                                    <option disabled={true} value="">Select POI Type</option>
                                    {poiTypeOpts}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Friendly Name:</label>
                        <div className="control">
                            <input
                                type="text"
                                className={ ["input"].join(" ") }
                                name="friendlyName"
                                value={friendlyName}
                                onChange={handleInputChange}
                                />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Physical Device:</label>
                        <div className="control">
                            <input
                                type="checkbox"
                                className={ ["checkbox"].join(" ") }
                                name="isPhysical"
                                checked={isPhysical}
                                onChange={handleInputChange}
                                />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Latitude:</label>
                        <div className="control">
                            <input
                                type="text"
                                className={ ["input"].join(" ") }
                                name="lastLatitude"
                                value={lastLatitude}
                                onChange={handleInputChange}
                                />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Longitude:</label>
                        <div className="control">
                            <input
                                type="text"
                                className={ ["input"].join(" ") }
                                name="lastLongitude"
                                value={lastLongitude}
                                onChange={handleInputChange}
                                />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">
                            Defaulted POI: <ExtraDetails>
                                Will include this POI on Real-Time page without an event selected.
                            </ExtraDetails>
                        </label>
                        <div className="control">
                            <input
                                type="checkbox"
                                className={ ["checkbox"].join(" ") }
                                name="isDefault"
                                checked={isDefault}
                                onChange={handleInputChange}
                                />
                        </div>
                    </div>

                    <div className="field is-grouped">
                        <div className="control">
                            <Button
                                activeColor='is-success'
                                onClick={() => onSubmitClicked({preventDefault: () => {}})}
                            >{submitButtonName}</Button>
                        </div>
                        <div className="control">
                            <Button
                                onClick={() => props.history.goBack()}
                            >Cancel</Button>
                        </div>
                    </div>
                </div>

                {modal}
            </div>
    </>)
}

export default withRouter(PoiEdit);
