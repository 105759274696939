import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFontAwesomeLogoFull } from "@fortawesome/free-solid-svg-icons";

export default class IconText extends React.Component {
    static defaultProps = {
        icon: faFontAwesomeLogoFull,
        text: undefined,
        iconTextClasses: [],
        iconClasses: [],
        textClasses: [],
        iconAfterText: false
    }

    render() {
        const iconTextClasses = `icon-text${this.props.iconTextClasses?.length > 0 ? ` ${this.props.iconTextClasses.join(" ")}` : ""}`;
        const iconClasses = `icon${this.props.iconClasses?.length > 0 ? ` ${this.props.iconClasses.join(" ")}` : ""}`;
        const textClasses = this.props.textClasses?.length > 0 ? this.props.textClasses.join(" ") : "";

        const icon = <span className={iconClasses}><FontAwesomeIcon icon={this.props.icon} /></span>;
        const text = <span className={textClasses}>{this.props.text}</span>;

        return (
            <span className={iconTextClasses}>
                {this.props.iconAfterText ? <React.Fragment>{text}{icon}</React.Fragment> : <React.Fragment>{icon}{text}</React.Fragment>}
            </span>
        );
    }
}
