export function storeSessionData(name, data) {
    window.sessionStorage.setItem(name, JSON.stringify(data));
}

export function retrieveSessionData(name, data) {
    return JSON.parse(window.sessionStorage.getItem(name));
}

export function deleteSessionData(name) {
    window.sessionStorage.removeItem(name);
}