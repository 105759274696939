import React from "react";
import { Link, withRouter } from "react-router-dom";

import PathList from "../../util/path-list";
import ConfirmationModal from "../misc/ConfirmationModal";
import NotificationModal from "../misc/NotificationModal";

import {
    getAllEvents,
    requestEventDownload,
} from "../../actions/event-actions";

class EventDownload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emailAddress: "",
            eventName: "",
            confirmationModalOpen: false,

            dataSelectOpts: {
                heart: "HR/HSI/Confidence",
                temp: "Temperature",
                ect: "ECT",
                accel: "Acceleration",
                gps: "Real Time (GPS/Physio)",
                risk: "Risk",
                nii: "NII"
            },
            heart: true,
            temp: true,
            ect: true,
            accel: false,
            gps: false,
            risk: true,
            nii: true,

            eventNames: [],
            eventIds: [],

            errorMsg: "",
            errorMsgModalOpen: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChkBoxChange = this.handleChkBoxChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const eventId = this.props.match.params.eventId;

        getAllEvents({ throwOnError: true }).then(v => {
            console.debug("Got All Events ", v);
            const completed = v.filter(x => (x.status === "complete"));
            const eventName = completed
                .filter(x => (x.id === eventId))
                .map(x => (x.name))
                .shift();
            this.setState({
                eventNames: completed.map(x => (x.name)),
                eventIds: completed.map(x => (x.id)),
                eventName,
            });
        }).catch(err => {
            console.error("Failed to query events: ", err);
        });
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        console.debug(
            'State Changed ', this.state,
            ' with ', {[e.target.name]: e.target.value})
    }

    handleChkBoxChange(e) {
        this.setState({ [e.target.name]: e.target.checked });
        console.debug(
            'State Changed ', this.state,
            ' with ', {[e.target.name]: e.target.checked})
    }

    handleSubmit() {
        const data = {};
        data.email = this.state.emailAddress;
        data.eventId = this.getEventIdFromName(this.state.eventName);
        for (const key in this.state.dataSelectOpts) {
            data[key] = this.state[key];
        }
        Object.freeze(data);

        console.debug("Submit Confirmed, sending ", data);

        requestEventDownload(data, { throwOnError: true }).then(v => {
            console.debug(v);
            window.location.assign(PathList.retrospective);
        }).catch(err => {
            console.log(err);
            this.setState({
                confirmationModalOpen: false,
                errorMsg: err.message,
                errorMsgModalOpen: true,
            });
        });
    }

    getEventIdFromName(name) {
        const idx = this.state.eventNames.indexOf(name);
        const ret = this.state.eventIds[idx];
        console.debug('Obtained ', ret, ' from ', name);
        return ret;
    }

    render() {
        const eventSelect = this.state.eventNames.map(
            (v, i) => (
                <option
                    key={`event-selection-${i}-${v}`}
                    value={v}
                    >{v}</option>)
        );

        const dataSelect = Object.keys(this.state.dataSelectOpts).map(
            (v, i) => {return (
                <div className="control" key={`data-select-${i}`}>
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name={`${v}`}
                            defaultChecked={this.state[v]}
                            onChange={this.handleChkBoxChange}
                            />
                        {this.state.dataSelectOpts[v]}
                    </label>
                </div>
            )}
        );

        const required = <span className="has-text-danger">*</span>;

        const confirmationModal = this.state.confirmationModalOpen ?
            <ConfirmationModal
                active={this.state.confirmationModalOpen}
                confirmationText={(
                    <div>
                        <div className="title">
                            Confirm event download
                        </div>

                        <div className="block"/>

                        <div>
                            <span className="has-text-weight-bold">Email Address:</span> {this.state.emailAddress}
                        </div>

                        <div>
                            <span className="has-text-weight-bold">Event Name:</span> {this.state.eventName}
                        </div>

                        <div>
                            <span className="has-text-weight-bold">Event Id:</span> {this.getEventIdFromName(this.state.eventName)}
                        </div>

                        <div>
                            <span className="has-text-weight-bold">
                            Data Selection:
                            </span>
                            <ul>
                                {Object.keys(this.state.dataSelectOpts)
                                    .filter(k => this.state[k])
                                    .map(k => (
                                        <li key={`data-confirm-${k}`}>{this.state.dataSelectOpts[k]}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                )}
                confirmButtonAction={() => this.handleSubmit()}
                cancelButtonAction={() => this.setState({ confirmationModalOpen: false })}
                closeButton={false}
            /> :
            null;

        const errorMessageModal = this.state.errorMsgModalOpen ?
            <NotificationModal
                active={this.state.errorMsgModalOpen}
                confirmationText={(
                    <div>
                        <div className="title">
                            Error
                        </div>

                        <div className="block"/>

                        <div className="has-text-weight-bold has-text-danger">
                            {this.state.errorMsg}
                        </div>
                    </div>
                )}
                confirmButton={ {text: "Ok", classes: ["is-danger"]} }
                confirmButtonAction={() => this.setState({ errorMsgModalOpen: false })}
                cancelbuttonaction={() => this.setState({ errorMsgModalOpen: false })}
                closeButton={false}
            /> :
            null;

        return (
            <div>

                <div className="block"/>

                <div className="container">
                    <span>
                        Once specified data is collected and compressed, you will
                        receive an email with a download link.
                    </span>
                </div>

                <div className="block"/>

                <div className="container">

                    <div className="field">
                        <label className="label">Email Address {required}</label>
                        <div className="control">
                            <input
                                type="text"
                                className="input"
                                name="emailAddress"
                                value={this.state.emailAddress}
                                onChange={this.handleInputChange}
                                />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Event {required}</label>
                        <div className="control">
                            <select
                                type="text"
                                className="input"
                                name="eventName"
                                value={this.state.eventName}
                                onChange={this.handleInputChange}
                                >
                                <option disabled={true}
                                    key="event-select-default-opt"
                                    value=""
                                    >Select Event</option>
                                {eventSelect}
                            </select>
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Data: check all that apply</label>
                        {dataSelect}
                    </div>

                    <div className="field is-grouped">
                        <div className="control">
                            <input
                                type="button"
                                className="button is-success"
                                value="Submit"
                                onClick={_e => this.setState({ confirmationModalOpen: true })} />
                        </div>
                        <div className="control">
                            <Link to={PathList.retrospective} className="button">Cancel</Link>
                        </div>
                    </div>

                </div>

                {confirmationModal}

                {errorMessageModal}
            </div>
        );
    }
}

export default withRouter(EventDownload);
