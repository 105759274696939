import React from "react";

import QuickDashboard from "../misc/QuickDashboard";

import {
  faMobileAlt,
  faSatelliteDish,
  faUser,
  faUsers,
  faCalendarAlt,
  faHandshake,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";

import PathList from "../../util/path-list";

export default class Configuration extends React.Component {
  static defaultProps = {
    links: [
      {
        title: "Devices",
        destination: PathList.devices,
        icon: faMobileAlt,
      },
      {
        title: "Comm Hubs",
        destination: PathList.commHubs,
        icon: faSatelliteDish,
      },
      {
        title: "Recruits",
        destination: PathList.recruits,
        icon: faUser,
      },
      {
        title: "Pair",
        destination: PathList.pair,
        icon: faHandshake,
      },
      {
        title: "Events",
        destination: PathList.events,
        icon: faCalendarAlt,
      },
      {
        title: "POIs",
        destination: PathList.pois,
        icon: faMapMarker,
      },
      {
        title: "Squads",
        destination: PathList.squads,
        icon: faUsers,
      },
    ],
  };

  render() {
    return (
      <div>
        <div className="block" />
        <QuickDashboard title="Configuration" links={this.props.links} />
      </div>
    );
  }
}
