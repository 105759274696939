import React, { useEffect, useState } from "react";
import {
  faClock,
  faGlobe,
  faSatellite,
  faServer,
  faSignal,
  faStopwatch,
  faThumbsUp,
  faUserTag,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseSvg } from "./CommHubSvgs";

import { refreshStatus, getCommhubStatus } from "../../../actions/comm-hub-actions";

export default function CommHubStatusPanel({ mac, handleClose, handleToast }) {
  const [stats, setStats] = useState(null);
  const darkBox = {
    background: "black",
    color: "whitesmoke",
    borderRadius: "1em",
  };

  const lightBox = {
    border: ".1rem solid whitesmoke",
    borderRadius: "1em",
  };

  const closePanel = () => {
    handleClose();
  };

  const timeDisplay = (timestamp) => {
    if (!timestamp) return "Searching";
    const date = new Date(Number(timestamp));
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const getNewStatus = () => {
    refreshStatus(mac)
      .then((res) => {
        console.log("Fetched commhub settings");
        setStats(res);
        handleToast("success", `Status for ${mac} successfully retrieved`);
      })
      .catch((err) => {
        console.log("Failed to get active commhub settings", err);
        handleToast("failure", `Failed to retrieve status for ${mac}: ${err.message}`);
      });
  };

  useEffect(() => {
    getCommhubStatus().then((res) => {
      setStats(mac in res ? res[mac] : null)
    })
    // refreshStatus(mac).then((res) => {
    //   if (Object.keys(res).length > 0) {
    //     console.log(res)
    //     setStats(res[mac]);
    //   } else {
    //     setStats(null);
    //   }
    // }).catch((err) => {
    //   console.log(err)
    // });
  }, []);

  useEffect(() => {}, [stats]);

  return (
    <div className="column">
      <div className="list-widget">
        {stats ? (
          <div>
            {" "}
            <div className="toolbar is-spaced-bottom">
              <div
                className="toolbar-start"
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <h4 className="is-4 title" style={{ margin: 0 }}>
                  Status
                </h4>
                <h5 className="is-6 title">
                  Firmware:{" "}
                  {stats.firmwareVersion ? stats.firmwareVersion : "searching"}
                </h5>
              </div>
              <div className="toolbar-end">
                <button 
                  className="button icon-button"
                  onClick={getNewStatus}
                >
                  <FontAwesomeIcon icon={faSync} />
                </button>
                <button className="button icon-button ml-2" onClick={closePanel}>
                  {CloseSvg}
                </button>
              </div>
            </div>
            <div className="boards-grid-item">
              <div className="card-inner">
                <div className="columns mb-4">
                  <div className="column has-text-centered" style={darkBox}>
                    <div>
                      <span className="is-6 title has-text-light">
                        LTE Conn:
                      </span>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faSignal} />
                    </div>
                    <div>
                      <span className="has-text-success">
                        {stats.lteConnected.toString()}
                      </span>
                    </div>
                  </div>
                  <div className="column has-text-centered">
                    <div>
                      <span className="is-6 title">Log Percent</span>
                    </div>
                    <div>
                      <progress
                        className="progress is-primary"
                        value={Number(stats.logPercentUsed) * 100}
                        max="100"
                      ></progress>
                    </div>
                    <div>
                      <span className="has-text-primary is-size-6 title">
                        {stats.logPercentUsed * 100}
                      </span>
                    </div>
                  </div>
                  <div className="column has-text-centered" style={darkBox}>
                    <div>
                      <span className="is-6 title has-text-light">
                        Cloud Conn:
                      </span>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faServer} />
                    </div>
                    <div>
                      <span className="has-text-success">
                        {stats.cloudConnected.toString()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="columns mb-4">
                  <div className="column" style={lightBox}>
                    <div>
                      <FontAwesomeIcon icon={faUserTag} />
                      <span className="is-6 title ml-2">Last RIds:</span>
                    </div>
                    <div>{stats.lastSeenRIDs.join(", ")}</div>
                  </div>
                </div>
                <div className="columns mb-4">
                  <div
                    className="column has-text-centered
              "
                    style={lightBox}
                  >
                    <div>
                      <span className="is-6 title"> LTE RSSi</span>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faSignal} />
                    </div>
                    <div>
                      <span> {stats.lteRssi}</span>
                    </div>
                  </div>
                  <div
                    className="column has-text-centered
              "
                    style={lightBox}
                  >
                    <div>
                      <span className="is-6 title">System Up</span>
                    </div>
                    <div className="has-text-success">
                      <FontAwesomeIcon icon={faThumbsUp} />
                    </div>
                    <div>
                      <span>{stats.sysUpTime}</span>
                    </div>
                  </div>
                  <div
                    className="column has-text-centered
              "
                    style={lightBox}
                  >
                    <div>
                      <span className="is-6 title"># Satellites</span>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faSatellite} />
                    </div>
                    <div>{stats.numSatellites}</div>
                  </div>
                </div>
                <div className="columns" style={lightBox}>
                  <div className="column has-text-centered">
                    <div>
                      <span className="is-6 title"> Last GPS Fix</span>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faStopwatch} />
                    </div>
                    <div>
                      <span>{stats.lastGpsFix}</span>
                    </div>
                  </div>
                  <div className="column has-text-centered">
                    <div>
                      <span className="is-6 title"> TimeStamp Latest </span>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faClock} />
                    </div>
                    <div>
                      <span>{timeDisplay(stats.timestamp)}</span>
                    </div>
                  </div>
                  <div className="column has-text-centered">
                    <div>
                      <span className="is-6 title"> GPS at zero [0,0]</span>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faGlobe} />
                    </div>
                    <div>
                      <span>{stats.gpsZeroZero.toString()}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="has-text-centered">
            <div className="toolbar is-spaced-bottom">
              <div
                className="toolbar-start"
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <h4 className="is-4 title" style={{ margin: 0 }}>
                  Status
                </h4>
              </div>
              <div className="toolbar-end">
                <button 
                  className="button icon-button"
                  onClick={getNewStatus}
                >
                  <FontAwesomeIcon icon={faSync} />
                </button>
                <button className="button icon-button ml-2" onClick={closePanel}>
                  {CloseSvg}
                </button>
              </div>
            </div>
            <h1 className="title is-6 tag is-curved">
              Current Status Unavailable
            </h1>
          </div>
        )}
      </div>
    </div>
  );
}
