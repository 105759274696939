import { htmlLegendPlugin } from "../misc/chart-plug/ChartScrollLegendPlugin";

/**
 * Generate RGB string from HSV values.
 * @param {number} h    Number [0, 1] representing the hue.
 * @param {number} s    Number [0, 1] representing the saturation.
 * @param {number} v    Number [0, 1] representing the value.
 * @returns {String}    e.g. "rgba(0, 0, 0, 1)"
 */
function HSVtoRGBStr(h, s, v) {
    function HSVtoRGB(h, s, v) {
        const i = Math.floor(h * 6);
        const f = h * 6 - i;
        const p = v * (1 - s);
        const q = v * (1 - f * s);
        const t = v * (1 - (1 - f) * s);
        const mode = i % 6;
        const tmp = ((m) => {
            if (m === 0) { return { r: v, g: t, b: p } }
            else if (m === 1) { return { r: q, g: v, b: p } }
            else if (m === 2) { return { r: p, g: v, b: t } }
            else if (m === 3) { return { r: p, g: q, b: v } }
            else if (m === 4) { return { r: t, g: p, b: v } }
            else if (m === 5) { return { r: v, g: p, b: q } }
            return {r: 0, g: 0, b: 0};
        })(mode);
        return {
            r: Math.round(tmp.r * 255),
            g: Math.round(tmp.g * 255),
            b: Math.round(tmp.b * 255)
        };
    }

    const x = HSVtoRGB(h, s, v);
    return `rgba(${x.r},${x.g},${x.b},1)`;
}

function genColors(count, skip) {
    return new Array(count).fill(undefined).map((_, i) => {
        const p = (i * skip) / count;
        const h = (p > 1.0) ? (p % 1) : p;
        return HSVtoRGBStr(h, 1.0, 1.0);
    });
};

export const GRAPH_COLORS = genColors(9, 4);

/**
 * Celsius to Fahrenheit conversion.
 * @param {number} c    Degrees Celsius.
 * @returns {number}    Fahrenheit value.
 */
function cToF(c) {
    return (c * (9 / 5)) + 32;
}


export const METRIC_TYPES = {
    'hr': {
        text: 'Heart Rate',
        unit: 'bpm',
    },
    'hsi': {
        text: 'Heat Stress Index',
    },
    'ect': {
        text: 'Estimated Core Temperature',
        unit: 'Deg Celsius',
        alt_unit: 'Deg Fahrenheit',
        converter: cToF,
    },
    'temp': {
        text: 'Skin Temperature',
        unit: 'Deg Celsius',
        alt_unit: 'Deg Fahrenheit',
        converter: cToF,
    },
    'risk': {
        text: 'Risk',
    },
    'nii': {
        text: 'Wobble',
    },
    'confidence': {
        text: 'Confidence'
    },
};

export const COMMON_GRAPH_CONFIG = {
    type: 'line',
    data: { datasets: [], labels: [] },
    options: {
        responsive: true,
        normalized: true,
        animation: false,
        parsing: false,
        // Manage the tick labels
        scales: {
            y: {
                display: true,
                beginAtZero: true,
            },
            x: {
                type: 'time',
                display: true,
                beginAtZero: false,
                ticks: {
                    maxTicksLimit: 4,
                },
                time: {
                    /* <https://www.chartjs.org/docs/next/axes/cartesian/time.html> */
                    displayFormats: {
                        datetime: "yyyy-MM-dd HH:mm:ss.SSSXX",
                        millisecond: "yyyy-MM-dd HH:mm:ss.SSSXX",
                        second: "yyyy-MM-dd HH:mm:ss.SSSXX",
                        minute: "yyyy-MM-dd HH:mm:ssXX",
                        hour: "yyyy-MM-dd HH:mm:ssXX",
                        day: "MMM d",
                        week: "PP",
                        month: "MMM yyyy",
                        quarter: "qqq - yyyy",
                        year: "yyyy"
                    },
                },
            },
        },
        // Move the recruit selection to the side
        plugins: {
            htmlLegend: {
                // Gets updated below
                containerID: '',
            },
            legend: {
                // Since html legend is being used
                display: false,
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: "x",
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    mode: "x",
                },
            },
        },
        // De-clutter by removing points
        borderWidth: 1,
        elements: {
            point: {
                radius: 0, // [default 3]
            },
            line: {
                borderColor: GRAPH_COLORS,
                backgroundColor: GRAPH_COLORS,
            },
        },
    },
    plugins: [
        htmlLegendPlugin,
    ],
};

