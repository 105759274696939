import React, { useEffect, useState } from "react";

export default function DownloadPanel(props = {}) {
  const [sidebarOpenedState, setSidebarOpenedState] = useState(false);
  const [panelStyle, setPanelStyle] = useState({
    marginTop: "4em",
    width: "16rem",
  });

  useEffect(() => {
    if (props.isComplete) {
      console.log("props.isComplete", props.isComplete);
      setPanelStyle({
        width: "24rem",
        whitespace: "normal",
        overflow: "auto",
        left: 0,
        height: "auto",
        margin: "auto",
      });
    } else {
      setPanelStyle({
        marginTop: "4em",
        width: "16rem",
      });
    }
  }, [props.isComplete]);

  const panelClasses = `logfile-side-panel panel ${
    sidebarOpenedState ? "is-pushed" : ""
  } ${props.isOpen ? "is-active" : ""}`;

  const message = (
    <h2>
      Download Request sent!
      <br />
      <br />
        You will receive an email shortly from hraps.gtri@gmail.com with a link to
        download this data set.
      <br />
      <br />
        Email Sent To: {props.email}
      <br />
      <br />
        Logfiles Requested for Download:
      <br />
    </h2>
  );

  function displayDate(dte) {
    let date = new Date(Number(dte * 1000));
    return Intl.DateTimeFormat(undefined, {
      dateStyle: "full",
      hour12: false,
      timeStyle: "short",
    }).format(date);
  }
  return (
    <div className={panelClasses} style={panelStyle}>
      <div className="panel-conversations-header">
        {props.isComplete ? null : (
          <h2 className="title is-narrow">Selected Logfiles</h2>
        )}
      </div>
      <div className="panel-conversations-body has-slimscroll">
        {props.isComplete ? message : null}
        {props.storage.length > 0 ? (
          props.storage.map((file, idx) => {
            return (
              <span
                className="panel-conversation"
                key={`${file["logfile"]._id}-${idx}`}
              >
                <div className="meta">
                  <span>
                    {file["device"] && file["device"].friendlyName
                      ? file["device"].friendlyName
                      : "No name on file"}
                  </span>
                  <span className="item-title">
                    End: {displayDate(file["logfile"].endTimeUnix)}
                  </span>
                  <span>{file["logfile"]._id.slice(-7)}</span>
                </div>
                <div className="mask mask-blob end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-check-circle"
                  >
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg>
                </div>
              </span>
            );
          })
        ) : (
          <h1>No Logfiles Currently Selected</h1>
        )}
        <br />
        <button
          className="button is-solid is-secondary"
          onClick={() => {
            props.onDataFromChild();
          }}
        >
          Okay
        </button>
      </div>
    </div>
  );
}
