import { useState, useEffect } from 'react';

import {
    faCog,
} from '@fortawesome/free-solid-svg-icons';

import Button from '../misc/Button';
import Modal from '../misc/Modal';
import CollapsableSection from '../misc/CollapsableSection';

export default function SettingsModal(props = {}) {
    const {
        options = [],
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Modal
                title='Real-Time Page Options'
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                selectText='Close'
                selectColor=''
                bodyBox={false}
            >
                <CollapsableSection
                    title="Summary"
                    hidable={false}
                >
                    {
                        options.map((x, xi) => {
                            const key = x.summary[0];
                            const value = x.summary[1];
                            return (
                                <p key={`summary-${xi}`}>
                                    <span className="has-text-weight-bold">{key}</span>: {value}
                                </p>
                            );
                        })
                    }
                </CollapsableSection>
                {
                    options.map((x, xi) => (
                        <CollapsableSection
                            key={`rt-opts-section-${xi}`}
                            title={x.title}
                            icon={x.icon}
                        >
                            {x.component}
                        </CollapsableSection>
                    ))
                }
            </Modal>
            <Button icon={faCog} onClick={() => setIsOpen(true)}>
                Options
            </Button>
        </>
    );
}
