import ConfirmationModal from './ConfirmationModal';

/**
 * @param msg {String}              Title for the modal above the data output.
 * @param data {Object}             Key-values for data to visualize in the modal. If value is an
 *                                  array, a table will be created.
 * @param labels {Object}           Labels to use next to data, if missing, key is used in place.
 * @param onConfirm {Callable}      Called when the confirm button is pressed.
 * @param onCancel {Callable}       Called when the cancel button is pressed to not confirm.
 */
export default function FormConfirmModal(props = {}) {
    const {
        msg = 'Confirm Submit?',
        data = {},
        labels = {},
        onConfirm = () => {},
        onCancel = () => {},
    } = props;

    const body = Object.entries(data).map(([k, v], i) => {
        const label = labels[k] ?? k;
        if (v instanceof Array) {
            return (<div key={`confdiag-${i}-${k}`}>
                <span className="has-text-weight-bold">
                    {label}:
                </span>
                <table className="table is-fullwidth is-striped">
                    <thead><tr><th>#</th><th>{label}</th></tr></thead>
                    <tbody>{
                        v.map((x, j) => (<tr key={`tbl-${k}-${i}-${j}`}><td>{j}</td><td>{x}</td></tr>))
                    }</tbody>
                </table>
            </div>);
        }
        return (<div key={`confdiag-${i}-${k}`}>
            <div>
                <span className="has-text-weight-bold">
                    {label}:
                </span> <span>
                    {v}
                </span>
            </div>
        </div>)
    });

    const fullBody = (
        <div>
            <div className="title">{msg}</div>

            <div className="block"></div>

            {body}
        </div>
    );

    return <ConfirmationModal
        active={true}
        confirmationText={fullBody}
        confirmButtonAction={onConfirm}
        cancelButtonAction={onCancel}
        closeButton={false}
    />;
}
