import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import FormConfirmModal from '../../misc/FormConfirmModal';
import SimpleErrorModal from '../../misc/SimpleErrorModal';
import Button from '../../misc/Button';

import {
    createDevice,
    getOneDevice,
    updateDevice,
    getDeviceTypes,
} from "../../../actions/device-actions";
import { getOrganizations } from "../../../actions/organization-actions";

import _ from 'lodash';

function DeviceEdit(props = {}) {
    const {
        isUpdate = false,
    } = props;

    const deviceId = props.match.params.deviceId;

    // Form fields
    const [nativeDeviceId, setNativeDeviceId] = useState('');
    const [deviceType, setDeviceType] = useState('');
    const [deviceTypeSlugs, setDeviceTypeSlugs] = useState({});
    const [org, setOrg] = useState('');
    const [friendlyName, setFriendlyName] = useState('');

    // Other states
    const [orgs, setOrgs] = useState([]);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [modal, setModal] = useState(null);

    const setters = {
        nativeDeviceId: setNativeDeviceId,
        deviceType: setDeviceType,
        org: setOrg,
        friendlyName: setFriendlyName,
    };

    const handleInputChange = (e) => {
        const val = (e.target.type === 'checkbox') ? e.target.checked : e.target.value;
        console.debug(`${e.target.name} change to ${val}`)
        setters[e.target.name](val);
    };

    const onSubmitClicked = (e) => {
        e.preventDefault();

        const data = {
            nativeDeviceId,
            deviceType,
            org,
            friendlyName,
        }

        const labels = {
            nativeDeviceId: 'MAC Address',
            deviceType: 'Device Type',
            org: 'Organization',
            friendlyName: 'Friendly Name',
        };

        let formData = { ...data, deviceType: deviceTypeSlugs[deviceType] };
        formData = Object.fromEntries(Object.entries(formData).map(([k, v]) => [k, _.toString(v)]));

        setModal(<FormConfirmModal
            msg='Confirm form submission?'
            data={formData}
            labels={labels}
            onConfirm={() => {
                ((isUpdate) ? updateDevice(deviceId, data) : createDevice(data)).then(_v => {
                    props.history.goBack();
                }).catch(err => {
                    setModal(<SimpleErrorModal
                        msg={`Failed to submit form data: ${err}`}
                        onClose={() => setModal(null)}
                    />);
                });
            }}
            onCancel={() => setModal(null)}
        />);
    };

    /*----------------------------------------*/

    useEffect(() => {
        getOrganizations().then((res) => {
            setOrgs(res.map(x => [x, x]));
        }).catch((err) => {
            console.error('Failed to query orgs:', err);
        });

        getDeviceTypes(true).then((res) => {
            setDeviceTypes(res);
            res.forEach((dt) => {
                setDeviceTypeSlugs(prevDeviceTypeSlugs => ({...prevDeviceTypeSlugs, [dt.id]: dt.slug}))
            });
        }).catch((err) => {
            console.error('Failed to query device types', err);
        });

        if (isUpdate) {
            getOneDevice(deviceId).then((res) => { 
                console.debug('Got device data', res);
                Object.entries(res).forEach(([k, v]) => {
                    if (k in setters) {
                        setters[k](v);
                    }
                })
            }).catch((err) => {
                console.error('Failed to get device data:', err);
            });
        }
    }, []);

    /*----------------------------------------*/

    const submitButtonName = (isUpdate) ? 'Update' : 'Create';

    const deviceTypeSelect = deviceTypes.map(({ id, slug }, i) => (
        <option key={`device-type-select-${slug}-${i}`} value={id}>{slug}</option>
    ));
    const orgOpts = orgs.map((t, i) => (
        <option key={`org-opt-${i}`} value={t[0]}>{t[1]}</option>
    ));

    return (<>
            <div>
                <div className="block"></div>

                <div className="container">
                    <div className="field">
                        <label className="label">MAC Address</label>
                        <div className="control">
                            <input
                                type="text"
                                className="input"
                                name="nativeDeviceId"
                                value={nativeDeviceId}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Device Type</label>
                        <div className="control">
                            <div className="select is-fullwidth">
                                <select
                                    name="deviceType"
                                    value={deviceType}
                                    onChange={handleInputChange}
                                >
                                    <option disabled={true} value="">Select Device Type</option>
                                    {deviceTypeSelect}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Organization</label>
                        <div className="control">
                            <div className="select is-fullwidth">
                                <select name="org"
                                    value={org}
                                    onChange={handleInputChange}>
                                    <option disabled={true} value="">Select Organization</option>
                                    {orgOpts}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Serial Number</label>
                        <div className="control">
                            <input
                                type="text"
                                className="input"
                                name="friendlyName"
                                value={friendlyName}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="field is-grouped">
                        <div className="control">
                            <Button
                                activeColor='is-success'
                                onClick={() => onSubmitClicked({preventDefault: () => {}})}
                            >{submitButtonName}</Button>
                        </div>
                        <div className="control">
                            <Button
                                onClick={() => props.history.goBack()}
                            >Cancel</Button>
                        </div>
                    </div>
                </div>

                {modal}
            </div>
    </>)
}

export default withRouter(DeviceEdit);
