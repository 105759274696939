import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import HrapsHeader from "../header/HrapsHeader";

export default class PathRouter extends React.Component {
    static defaultProps = {
        routes: [
            {
                component: <div></div>,
                route: "/",
                protected: false,
                protectionAuthenticationStrategy: () => true, // Set to false or don't include to use the default authentication strategy, N/A if protected === false.
                exact: false
            }
        ],
        redirect: "/" // Set to false to not have a redirect
    }

    render() {
        const routes = this.props.routes.map((v, i) => {
            if (v.protected) {
                if (v.protectionAuthenticationStrategy) {
                    return (
                        <>
                            <ProtectedRoute checkAuthentication={v.protectionAuthenticationStrategy} path={v.route}
                                            exact={v?.exact}
                                            key={`route-protected-${v.route}-${i}`}>{v.component}</ProtectedRoute>
                        </>
                    )
                }
                return (

                        <ProtectedRoute path={v.route} exact={v?.exact}
                                        key={`route-protected-${v.route}-${i}`}><HrapsHeader/>{v.component}</ProtectedRoute>
                )
            }

            return (
                <Route path={v.route} exact={v?.exact} key={`route-standard-${v.route}-${i}`}>{v.component}</Route>
            )
        }
    );

    return (
        <>
            <Switch>
                {routes}
                {this.props.redirect ? <Redirect to={this.props.redirect}/> : null}
            </Switch>
        </>
    );
    }
    }