import querystring from "querystring";

import { getUserSessionData } from "../util/user-session-util";
import { METHODS, CONTENT_TYPES, apiCall } from "../util/fetch-util";

export async function getAllEvents({ returnEventMap = false, throwOnError = false,
                                     query="", search="", status="", org="" } = {}) {
    // TODO: For some reason, this call gets different data from the getPageOfRecruits call below...need to update backend to properly serve the data

    const events = await getPageOfEvents(
        0,
        {
            query,
            search,
            status,
            eventsPerPage: 999,
            org,
            throwOnError,
        }
    );

    console.log(events);

    if (returnEventMap) {
        const eventMap = {};
        events?.forEach(v => eventMap[v.id] = v);
        return eventMap;
    }

    return events;
}

export async function getAllEventsById({ throwOnError = false } = {}) {
    return await getAllEvents({ returnEventMap: true, throwOnError });
}


export async function getOneEvent(eventId, { throwOnError = true } = {}) {
    const userId = getUserSessionData().id;
    const searchParams = { userId };
    const encodedSearchParams = querystring.encode(searchParams);
    const recruit = await apiCall(`/events/${eventId}?${encodedSearchParams}`, METHODS.get, { throwOnError });
    return recruit;
}

export async function getEventStatus(status, { throwOnError = true } = {}) {
    const userId = getUserSessionData().id;
    const searchParams = { userId };
    const encodedSearchParams = querystring.encode(searchParams);
    const eventStatus = await apiCall(`/events/status?${encodedSearchParams}`, METHODS.get, { throwOnError });
    return eventStatus;
}

export async function getPageOfEvents(page,
                                      {
                                          query = "",
                                          search = "",
                                          status = "",
                                          eventsPerPage = 15,
                                          org = "",
                                          throwOnError = false,
                                          sortKey = "",
                                          sortValue = "",
                                          startTime = "",
                                          endTime = "",
                                          queryFields = "",
                                          includeMeta = false,
                                      } = {}) {

    // This function may not need to exist since we aren't paging the event list, but is provided as a legacy to when it used to work that way...
    const userId = getUserSessionData().id;
    const searchParams = {
        query,
        search,
        status,
        page,
        limit: eventsPerPage,
        org,
        userId,
        sortKey,
        sortValue,
        startTime,
        endTime,
        queryFields,
    };
    const encodedSearchParams = querystring.encode(searchParams);

    const events = await apiCall(`/events?${encodedSearchParams}`, METHODS.get, {throwOnError});

    return (includeMeta) ? events : events?.data;
}

export async function deleteEvent(eventId) {
    return await apiCall(`/events/${eventId}/destroy`, METHODS.delete, { throwOnError: true });
}

export async function createEvent(eventData, {throwOnError = true} = {}) {
    const body = [
        {
            eventName: eventData.eventName,
            courseName: eventData.courseName,
            courseNumber: eventData.courseNumber,
            estimatedStartTime: eventData.estimatedStartTime,
            endTime: eventData.endTime,
            recruits: eventData.recruits,
            squads: eventData.squads,
            ...((eventData.org) ? {org: eventData.org} : {}),
        }
    ];

    return await apiCall(`/events`, METHODS.post, { body, throwOnError });
}

export async function updateEvent(eventId, eventData, {throwOnError = true} = {}) {
    const body = {
        eventName: eventData.eventName,
        courseName: eventData.courseName,
        courseNumber: eventData.courseNumber,
        estimatedStartTime: eventData.estimatedStartTime,
        endTime: eventData.endTime,
        recruits: eventData.recruits,
        squads: eventData.squads,
        ...((eventData.org) ? {org: eventData.org} : {}),
    };

    return await apiCall(`/events/${eventId}/update`, METHODS.post, { body, accept: CONTENT_TYPES.plain, throwOnError });
}

export async function requestEventDownload(requestData, {throwOnError = false} = {}) {
    const body = {
        email: requestData.email,
        heart: requestData.heart.toString(),
        accel: requestData.accel.toString(),
        gps: requestData.gps.toString(),
        temp: requestData.temp.toString(),
        risk: requestData.risk.toString(),
        ect: requestData.ect.toString(),
        nii: requestData.nii.toString(),
        eventId: requestData.eventId,
    }

    return await apiCall(`/download`, METHODS.post, { body, accept: CONTENT_TYPES.plain, throwOnError });
}
