import { useEffect, useState, useRef } from "react";

import { getOrganizations } from "../../actions/organization-actions";
import { getUserSessionData } from "../../util/user-session-util";

import DateTimePicker from "react-datetime-picker";

function includeAdminFeatures(featureBool) {
  const user = getUserSessionData();
  return featureBool && user.role === "ROLE_ADMIN";
}

/**
 * @param text {String}         Label string to display next to the control.
 * @param nestControl {DOM}     Control to nest within the label (e.g. for checkboxes).
 * @param labelClass {String}   Class name to use for the label, to change away from "label".
 */
function createLabel(text, nestControl, labelClass) {
  const style = {
    margin: "0 0.5em",
  };
  return !!text ? (
    <label key={`lbl-${text}`} className={labelClass || "label"} style={style}>
      {nestControl}
      {text}
    </label>
  ) : null;
}

function createCheckbox(idx, opts) {
  const {
    value = null,
    onChange = (_v) => {},
    initialState = false,
    label = null,
  } = opts;

  const control = (
    <input
      type="checkbox"
      className="checkbox"
      key={`check-${idx}`}
      defaultChecked={initialState}
      checked={value}
      onChange={(e) => onChange(e.target.checked)}
      value={label}
    />
  );
  return (
    <div key={`cb-ctrl-${idx}`} className="control">
      {label ? createLabel(label, control) : control}
    </div>
  );
}

function createSelect(idx, opts) {
  const {
    value = null,
    onChange = (_v) => {},
    label = null,
    labelValue = null,
    options = [],
  } = opts;

  const labelOpt = { text: `-- ${label || "Select"} --`, value: labelValue };

  const optTags = [].concat(labelOpt, options).map((x, i) => {
    const text = x instanceof Object ? x.text : x;
    const value = x instanceof Object ? x.value : x;
    return (
      <option key={`${value}-${i}`} value={value}>
        {text || value}
      </option>
    );
  });

  return (
    <div key={`select-ctrl-${idx}`} className="control">
      <div className="select is-small">
        <select
          key={`select-${idx}`}
          value={value ?? ""}
          onChange={(e) => onChange(e.target.value)}
        >
          {optTags}
        </select>
      </div>
    </div>
  );
}

function createRadio(idx, opts) {
  const {
    value = null,
    key = null,
    onChange = (_v) => {},
    label = null,
    options = [],
  } = opts;

  const handleChange = (e) => {
    console.debug("SF: createRadio: Change =>", e.target.name, e);
    onChange(e.target.value);
  };

  const inputs = options.map((x, i) => {
    return createLabel(
      x.label,
      <input
        type="radio"
        key={`${key}-${idx}-${i}`}
        name={key}
        value={x.value}
        defaultChecked={x?.default}
        onChange={handleChange}
      />
    );
  });

  return (
    <div key={`radio-parent-${idx}`} className="field is-grouped">
      {inputs}
    </div>
  );
}

function createDate(idx, opts) {
  const today = new Date();
  const tomorrow = new Date(today);
  const threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(today.getMonth() - 3);
  tomorrow.setDate(today.getDate() + 1);

  const defaultStartDate = threeMonthsAgo;
  const defaultEndDate = tomorrow;

  const {
    value = [defaultStartDate, defaultEndDate],
    onChange = (_v) => {},
    label = null,
  } = opts;

  const startTime = value instanceof Array ? value[0] : value;
  const endTime = value instanceof Array ? value[1] : null;

  const handleChange = (v, k) => {
    let newVal = null;
    if (k === "s") {
      newVal = !endTime ? v : [v, endTime];
    } else if (k === "e") {
      newVal = [startTime, v];
    }
    onChange(newVal);
  };

  return (
    <div key={`date-parent-${idx}`} className="field is-grouped">
      {createLabel(label)}
      <div key={`date-${idx}`} className="control">
        <DateTimePicker
          returnValue={"start"}
          value={startTime}
          onChange={(v) => handleChange(v, "s")}
        />
        <DateTimePicker
          returnValue={"end"}
          value={endTime}
          onChange={(v) => handleChange(v, "e")}
        />
      </div>
    </div>
  );
}

/*------------------------------------------------------------------------------------------------*/

export default function SearchFilter(props = {}) {
  const {
    onChange = (_d) => {},
    queryKey = "query",
    hideOrg = false,
    includeOrg = true,
    includeDateRange = false,
    realTimeUpdate = true,
    config = [
      {
        type: "checkbox",
        label: "Test",
        key: "test",
      },
      {
        type: "select",
        label: "Some Options",
        key: "sel1",
        options: ["Value 1", "Value 2", "Value 3"],
      },
      { type: "checkbox", label: "Test 1", key: "test1" },
      { type: "checkbox", label: "Test 2", key: "test2" },
      { type: "checkbox", label: "Test 3", key: "test3" },
      { type: "checkbox", label: "Test 4", key: "test4" },
      { type: "checkbox", label: "Test 5", key: "test5" },
      { type: "checkbox", label: "Test 6", key: "test6" },
      { type: "checkbox", label: "Test 7", key: "test7" },
      { type: "checkbox", label: "Test 8", key: "test8" },
      { type: "checkbox", label: "Test 9", key: "test9" },
      { type: "checkbox", label: "Test 10", key: "test10" },
    ],
  } = props;

  const [orgs, setOrgs] = useState([]);
  const [allData, setAllData] = useState({ [queryKey]: "" });
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const orgChoices = [
    { value: "", text: "Org Selection", disabled: true },
    { value: "", text: "Any" },
    ...orgs,
  ];

  /*----------------------------------------*/

  useEffect(() => {
    setAllData({
      query: "",
      org: "",
    });

    getOrganizations()
      .then((o) => {
        console.debug("SF: Got orgs", o);
        setOrgs(o.map((v) => ({ value: v, text: v })));
      })
      .catch((err) => {
        console.error("SF: Failed to query orgs:", err);
      });
  }, []);

  useEffect(() => {
    if (realTimeUpdate) {
      postUpdate();
    }
  }, [allData]);

  /*----------------------------------------*/

  const postUpdate = () => {
    onChange(
      Object.fromEntries(
        Object.entries(allData).map(([k, v]) => {
          let value = v;
          if (value instanceof Date) {
            value = v.toISOString();
          }
          if (
            value instanceof Array &&
            value.length !== 0 &&
            value.some((v) => v instanceof Date)
          ) {
            if (value[0] > value[1]) {
              const tmp = value[0];
              value[0] = value[1];
              value[1] = tmp;
            }
            value = v.map((w) => (!!w ? w.toISOString() : w));
          }
          return [k, value];
        })
      )
    );
  };

  const handleFieldChanged = (k, v) => {
    console.debug(`SF: Field changed: ${k} = ${v}`);
    console.log("k", "v", k, v);
    setAllData({ ...allData, [k]: v });
    setSubmitDisabled(false);
  };

  const handleSubmit = (e) => {
    e?.preventDefault?.();
    console.debug("SF: Submit handler...");
    if (!submitDisabled) {
      postUpdate();
      setSubmitDisabled(true);
    }
  };

  /*----------------------------------------*/

  let orgSel = null;
  if (includeAdminFeatures(includeOrg)) {
    const orgSelOpts = orgChoices.map(({ value, text, disabled }, i) => {
      return (
        <option value={value} key={`org-opt-${value}-${i}`} disabled={disabled}>
          {text}
        </option>
      );
    });
    orgSel = (
      <p className="control">
        <span className="select">
          <select
            value={allData.org}
            onChange={(e) => handleFieldChanged("org", e.target.value)}
          >
            {orgSelOpts}
          </select>
        </span>
      </p>
    );
  }

  const submitButton = realTimeUpdate ? null : (
    <p className="control">
      <input
        className="button is-primary"
        type="submit"
        value="Submit"
        disabled={submitDisabled}
      />
    </p>
  );

  const searchBar = (
    <div className="field has-addons">
      {hideOrg ? <span></span> : orgSel}
      <p className="control">
        <input
          type="text"
          className="input"
          value={allData[queryKey]}
          onChange={(e) => {
            handleFieldChanged(queryKey, e.target.value);
          }}
          placeholder="Search"
        ></input>
      </p>
      {submitButton}
    </div>
  );

  const fields = [...config];
  const fieldCreators = {
    checkbox: createCheckbox,
    select: createSelect,
    radio: createRadio,
    date: createDate,
  };

  const fieldTags = fields.map((x, i) => {
    if (x.type in fieldCreators) {
      const value = x.key ? allData[x.key] : undefined;
      const onChange = x.key ? (v) => handleFieldChanged(x.key, v) : (_v) => {};
      return fieldCreators[x.type](i, { ...x, value, onChange });
    } else {
      throw Error(`Unknown form field type '${x.type}'`);
    }
  });

  return (
    <form className="container" target="_self" onSubmit={handleSubmit}>
      {searchBar}
      <div className="field is-grouped is-grouped-multiline">{fieldTags}</div>
    </form>
  );
}
