import querystring from "querystring";
import { getUserSessionData } from "../util/user-session-util";
import { METHODS, apiCall, CONTENT_TYPES } from "../util/fetch-util";

// Because the nature of logfile a get all logfiles and how large this payload could be a function was not created to get all logfiles
export async function getPageOfDeviceLogFileMetaData(
  page = 0,
  {
    query = "",
    search = "",
    status = "",
    filesPerPage = 20,
    org = "",
    deleted = "",
    queryFields = "",
    includeMeta = false,
    startTime = "",
    endTime = "",
    uploadDate,
    startTimeUnix,
    endTimeUnix,
  } = {}
) {
  // This function may not need to exist since we aren't paging the device list, but is provided as a legacy to when it used to work that way...
  const userToken = getUserSessionData();
  const searchParams = {
    query,
    search,
    status,
    page,
    limit: filesPerPage,
    org,
    deleted,
    userId: userToken.id,
    queryFields,
    startTime: startTime ? startTime : "",
    endTime: endTime ? endTime : "",
    uploadDate: uploadDate,
    startTimeUnix: startTimeUnix,
    endTimeUnix: endTimeUnix,
  };

  const encodedSearchParams = querystring.encode({
    ...searchParams,
  });

  const logfiles = await apiCall(
    `/deviceLogFileMetaData?${encodedSearchParams}`,
    METHODS.get,
    { throwOnError: true }
  );
  return includeMeta ? logfiles : logfiles?.data;
}

export async function requestLogfileDownload(
  logfiles,
  email,
  { throwOnError = true } = {}
) {
  const body = {
    "logfiles": logfiles,
    "email": email
  };
  return await apiCall(`/download`, METHODS.post, {
    body,
    accept: CONTENT_TYPES.plain,
    throwOnError,
  });
}
