import querystring from "querystring";
import { getUserSessionData } from "../util/user-session-util";

import { METHODS, apiCall, CONTENT_TYPES } from "../util/fetch-util";

export async function getAllDevices({ returnDeviceMap = false, query= "", status= "", page= 0, limit= 999, org= ""  } = {}) {
    // TODO: backend needs an actual "getAllDevices" function, and not just leverage the by page call...this function will break if the limit number is exceeded
    const userToken = getUserSessionData();
    // const DEFAULT_SEARCH_PARAMS = { query: "", status: "", page: 0, limit: 999, org: "", userId: userToken.id };

    // const query = querystring.encode(DEFAULT_SEARCH_PARAMS);

    const searchParams = { query, status, page, limit, org};
    const encodedSearchParams = querystring.encode(searchParams);

    const devices = await apiCall(`/devices?userId=${userToken.id}&${encodedSearchParams}`, METHODS.get, {throwOnError: true});

    if (returnDeviceMap) {
        const deviceMap = {};
        devices?.data?.forEach(v => deviceMap[v.id] = v);
        return deviceMap;
    }

    return devices?.data;
}

export function getDevices(searchParams) {
    const querystringUse = querystring.stringify(searchParams);
    const userToken = getUserSessionData();
    return {
        types: ['GET_DEVICES', 'GET_DEVICES_SUCCESS', 'GET_DEVICES_FAILURE'],
        payload: {
            request: {
                method: 'get',
                url: `/devices?userId=${userToken.id}&${querystringUse}`
            }
        }
    }
}

export async function getAllDevicesById() {
    return await getAllDevices({ returnDeviceMap: true });
}

export async function getOneDevice(deviceId) {
    const userToken = getUserSessionData();
    const device = await apiCall(`/devices/${deviceId}?userId=${userToken.id}`, METHODS.get, {throwOnError: true});

    return device;
}

export async function getPageOfDevices(page,
                                       {
                                           query = "",
                                           search = "",
                                           status = "",
                                           devicesPerPage = 15,
                                           org = "",
                                           nativeDeviceId = "",
                                           deleted = "",
                                           sortKey = "",
                                           sortValue = "",
                                           pairStatus = "",
                                           queryFields = "",
                                           includeMeta = false,
                                       } = {}) {
    // This function may not need to exist since we aren't paging the device list, but is provided as a legacy to when it used to work that way...
    const userToken = getUserSessionData();
    const searchParams = {
        query,
        search,
        status,
        page,
        limit: devicesPerPage,
        org,
        nativeDeviceId,
        deleted,
        userId: userToken.id,
        sortKey,
        sortValue,
        ...((pairStatus === "") ? {} : {pairStatus}),
        queryFields,
    };
    const encodedSearchParams = querystring.encode(searchParams);

    const devices = await apiCall(`/devices?${encodedSearchParams}`, METHODS.get, { throwOnError: true });

    return (includeMeta) ? devices :devices?.data;
}

export async function activateDevice(deviceId) {
    return await apiCall(`/devices/${deviceId}/activate`, METHODS.get, {throwOnError: true, accept: CONTENT_TYPES.plain}); // TODO: Backend needs to make this endpoint a POST not a GET.
}

export async function deactivateDevice(deviceId) {
    return await apiCall(`/devices/${deviceId}/destroy`, METHODS.delete, {throwOnError: true, accept: CONTENT_TYPES.plain});
}

export async function createDevice(deviceData) {
    const body = {
        devices: [
            {
                nativeDeviceId: deviceData.nativeDeviceId,
                deviceType: deviceData.deviceType,
                friendlyName: deviceData.friendlyName,
                org: deviceData.org
            }
        ]
    };

    return await apiCall(`/devices`, METHODS.post, { body, throwOnError: true });
}

export async function updateDevice(deviceId, deviceData) {
    const body = {
        nativeDeviceId: deviceData.nativeDeviceId,
        deviceType: deviceData.deviceType,
        friendlyName: deviceData.friendlyName,
        org: deviceData.org
    };

    return await apiCall(`/devices/${deviceId}/update`, METHODS.post, {
        body,
        throwOnError: true,
        accept: CONTENT_TYPES.plain,
    });
}

export async function getDeviceTypes(withId = false) {
    const query = {
        metrics: 'none',
        limit: 999,
        page: 0,
    };
    const queryString = (new URLSearchParams(query)).toString();
    const resp = await apiCall(`/devices/types?${queryString}`, METHODS.get, { throwOnError: true });
    const deviceTypes = resp.data.map((t) => withId ? ({id: t.id, slug: t.slug}) : t.slug);
    return deviceTypes;
}

export async function getDevicesByEvent(eventId) {
    const userToken = getUserSessionData();
    const searchParams = {
        userId: userToken.id,
    };
    const encodedSearchParams = querystring.encode(searchParams);

    const devices = await apiCall(`/events/${eventId}/devices?${encodedSearchParams}`, METHODS.get, { throwOnError: true });

    return devices;
}

export async function getRecruitsDevices(eventId) {
    return await apiCall(`/events/${eventId}/pairedRecruits`, METHODS.get, {throwOnError: true});
}
