import React from "react";

import SortableTableAlt from "../misc/SortableTableAlt";
import Button from "../misc/Button";
import RealTimeSidebarDeviceDetail from "./RealTimeSidebarDeviceDetail";
import EventSelector from "./EventSelector";
import SquadSelector from "./SquadSelector";
import DeviceTypeSelector from "./DeviceTypeSelector";
import SettingsModal from "./SettingsModal";

import {
    MISSING_DATA_STRING,
    NO_DEVICE_SELECTED,
    DEG_C,
    DEG_F,
} from "./real-time-constants";

import {
    celsiusToFahrenheit,
} from '../../util/format-util';

/*
 * Table Utility
 */

const DEFAULT_TABLE_HEADERS = [
    {displayName: 'ID', title: "Roster ID", dataName: "rosterId"},
    {displayName: "Risk", title: "Risk Level", dataName: "risk"},
    {displayName: 'Core', title: "Core Temperature", dataName: "ctemp"},
    {displayName: 'Skin', title: "Skin Temperature", dataName: "stemp"},
    {displayName: 'HR', title: "Heart Rate", dataName: "hr"},
    {displayName: 'HSI', title: "HSI", dataName: "hsi"},
    {displayName: 'LAST', title: "Last Arrival of Sample Time", dataName: "sinceLastSample"},
]

const MARINE_TABLE_HEADERS = [
    {displayName: 'ID', title: "Roster ID", dataName: "rosterId"},
    {displayName: "Risk", title: "Risk Level", dataName: "marineRisk"},
    {displayName: 'Core', title: "Core Temperature", dataName: "ctemp"},
    {displayName: 'Skin', title: "Skin Temperature", dataName: "stemp"},
    {displayName: 'HR', title: "Heart Rate", dataName: "hr"},
    {displayName: 'HSI', title: "HSI", dataName: "hsi"},
    {displayName: 'LAST', title: "Last Arrival of Sample Time", dataName: "sinceLastSample"},
];

/**
 * Creates headers based on the header info above.
 */
function createTableHeader(isMarine) {
    const src = (isMarine) ? MARINE_TABLE_HEADERS : DEFAULT_TABLE_HEADERS;
    return src.map(v => ({name: v.displayName, title: v.title, sortAlgorithm: v.dataName}));
}

function toMinutes(diff) {
    const minValue = diff / 60000;
    return `${(Math.round(minValue * 10) / 10).toFixed(1)} min`;
}

/**
 * @param data {Any}        Data to be processed into a displayable mode.
 * @param key {String}      Data key used to pull data from the original object.
 * @param obj {Object}      The original object to be used for more complex data manipulation.
 * @param opts {Object}
 *      - celsius {Boolean}:        (true) To convert to Celsius for temp; (false) to use Fahrenheit.
 *      - dataProcessing {Object}:  Provide functions to use for customized processing where each
 *                                  key is the data type to use the function for.
 *
 * @returns
 *      Data to be displayed in the table.
 */
function processTableData(data, key, obj, opts) {
    const {
        celsius = false,
        dataProcessing = null,
    } = opts;

    const maybeConvert = (v) => (celsius) ? v : celsiusToFahrenheit(v);

    const postProcessors = {
        ctemp: (v, _d, isInv) => (isInv) ? MISSING_DATA_STRING : `${maybeConvert(v)}${celsius ? DEG_C : DEG_F}`,
        stemp: (v, _d, isInv) => (isInv) ? MISSING_DATA_STRING : `${maybeConvert(v)}${celsius ? DEG_C : DEG_F}`,
        sinceLastSample: (v, _d, isInv) => (isInv) ? MISSING_DATA_STRING : toMinutes(v),
        ...dataProcessing,
    };

    if (key in postProcessors) {
        data = postProcessors[key](data, obj, (data == null));
    }
    else if (data == null) {
        return MISSING_DATA_STRING;
    }

    return data;
}

/*
 * Component Construction
 */

/**
 * Manages the creation of the collapsed view.
 */
function createCollapsedView(opts) {
    const {
        setMenuCollapsed = (_state) => {},
        devicesAlt = {},
    } = opts;
    const deviceStates = Object.values(devicesAlt);
    const activeDevices = deviceStates.reduce((t, v) => v === "active" ? t + 1 : t, 0);
    return (
        <div className="level">
            <Button
                classList={["level-item"]}
                onClick={() => setMenuCollapsed(false)}
            >
                Show Devices ({activeDevices} / {deviceStates.length})
            </Button>
        </div>
    );
}

/**
 * Manages the creation of the view when a device is selected.
 *
 * @param options {Object}
 *      - onReturn: Callback for when user returns from the detail screen to the table view.
 *      - selectedDeviceData: Data for a specific device pulled from the deviceData array.
 *      - celsius: Use celsius for temperature unit.
 *      - map: Map object used to fly to the recruit when following the recruit on the map.
 */
function createDeviceDetailView(options = {}) {
    const {
        onReturn = (() => {}),
        selectedDeviceData = {},
        celsius = false,
        map = null,
    } = options;

    return (
        <RealTimeSidebarDeviceDetail
            clickReturnButton={onReturn}
            deviceDetailData={selectedDeviceData}
            celsius={celsius}
            map={map}/>
    );
}

/**
 * Manages the creation of the overview table of devices with recent metric values shown.
 *
 * @param options {Object}
 *      - deviceData: Device data detailed below.
 *      - devicesAlt: Array of active and inactive devices.
 *      - isMarine: Specify if to process data with marine specific processes.
 *      - celsius: Use celsius for temperature unit.
 *      - onDeviceSelected: Callback for when a device is clicked on in the table.
 *      - dataProcessing: Extra metric data processing callbacks object (detailed below).
 */
function createDeviceOverviewTable(options = {}) {
    const {
        deviceData = [],
        devicesAlt = {},
        isMarine = false,
        celsius = false,
        onDeviceSelected = ((_data, e) => {e.preventDefault()}),
        dataProcessing = {},
        recruits = [],
    } = options;

    const processOpts = { celsius, dataProcessing };

    const tdd = [];
    const bdd = [];

    deviceData.forEach((v) => {
        try {
            v.rosterId = recruits?.paired?.[v?.nativeDeviceId]?.displayName || v.rosterId;
            if ((v.id in devicesAlt) && (devicesAlt[v.id] === 'active')) {
                tdd.push(v);
            }
            else {
                //v.rosterId = recruits.paired[v?.nativeDeviceId]?.displayName;
                bdd.push(v);
            }
        } catch(err) {
            console.error(err);
        }

    });

    return (
        <div className="level-item table-container sidebar">
            {
                (deviceData?.length) > 0
                    ? <SortableTableAlt
                        headers={createTableHeader(isMarine)}
                        data={tdd}
                        bottomData={bdd}
                        sortable={true}
                        defaultSort={['rosterId']}
                        allowDeselect={false}
                        rowClickCallback={onDeviceSelected}
                        xformHandler={(d, k, o) => processTableData(d, k, o, processOpts)}
                    />
                    : <span>No Data</span>
            }
        </div>
    );
}

function TempUnitSelector(props = {}) {
    const {
        celsius = false,
        setCelsius = (_state) => {},
    } = props;

    return (
        <div className={"level-item"}>
            <div className="field">
                <label className="switch mb-3">
                    <input
                        id="switchExample"
                        name="switchExample"
                        type="checkbox"
                        checked={celsius}
                        onChange={() => setCelsius(!celsius)}
                    />
                    <span className="slider round"/>
                </label>
            </div>
        </div>
    );
}

/**
 * Manages the creation of the DOM for when the menu has been opened to show devices.
 *
 * @param options {Object}
 *      - deviceData: Array of device info including a `data` member with metric arrays.
 *      - devicesAlt: Array of active and inactive devices.
 *      - setMenuCollapsed: Callback for when the user clicks to close the menu.
 *      - celsius: Boolean to indicate what temperature unit to use.
 *      - setCelsius: Callback for when user clicks to change unit, contains new value.
 *      - body: DOM to include below items created here.
 */
function createExpandedView(options = {}) {
    const {
        devicesAlt = {},
        setMenuCollapsed = ((_v) => {}),
        body = <div></div>,
        controls = null,
    } = options;

    const controlsTags = (!controls) ? null
        : ((controls instanceof Array) ? controls : [controls]).map((x) => {
            return (<div className="level-item mx-2">{x}</div>);
        });

    const deviceStates = Object.values(devicesAlt);

    const hideButton = (
        <div className="level">
            <Button classList={["level-item"]} onClick={() => setMenuCollapsed(true)} >
                Hide Devices ({deviceStates.reduce((t, v) => v === "active" ? t + 1 : t, 0)} / {deviceStates.length})
            </Button>
        </div>
    );

    return (<>
        {hideButton}

        <hr />

        <div className="level">
            {controlsTags}
        </div>

        {body}
    </>);
}

/**
 * @param props {Object}    React properties object.
 *      - deviceData
 *          Array of device data objects, each containing members such as id, rosterId, and then a
 *          data object for metric arrays.
 *      - devicesAlt
 *          Array of active and inactive devices.
 *      - map
 *          MapboxGL map object for making things happen such as flyTo calls.
 *      - isMarine
 *          Set to use marine specific processes.
 *      - selectedDevice
 *          Device ID of the selected device, data is pulled from deviceData.
 *      - menuCollapsedCallback
 *          Callback for when user closes the menu
 *      - selectDeviceCallback
 *          Callback for when user selects a device to look at.
 *      - unselectDeviceCallback
 *          Callback for when returning from device detail view, device should be deselected from
 *          the map.
 *      - handleSelectTemperatureMeasurementCallBack
 *          Callback when user clicks to change the temperature unit.
 *      - dataProcessing
 *          If a field here contains a metric, that callable will be used for further processing of
 *          the value before it gets displayed. For example
 *          ```
 *          {risk: (v) => `!! ${v} !!`}
 *          ```
 */
export default function RealTimeSidebar(props) {
    const {
        deviceData = [],
        devicesAlt = {},
        map = null,
        celsius = false,
        isMarine = false,
        selectedDevice = NO_DEVICE_SELECTED,
        menuCollapsed = true,
        menuCollapsedCallback = ((_e, _isCollapsed) => {}),
        selectDeviceCallback = ((_d, _id) => {}),
        unselectDeviceCallback = (() => {}),
        handleSelectTemperatureMeasurementCallBack = ((_e) => {}),
        dataProcessing = {},
        recruits = {},
        optionsData = {},
    } = props;

    /* Callback handlers */

    const findSelectedDeviceData = (devId) => {
        if (devId === NO_DEVICE_SELECTED) {
            return undefined;
        }
        return deviceData.find((v) => v.id === devId);
    };
    const selectedDeviceData = findSelectedDeviceData(selectedDevice);

    /* The table somehow contains the device id hidden as the final array item. So we pull that for
     * the id of the device to find from the data array. Then we use the callback to notify upwards
     * of the selection. */
    const onDeviceSelect = (obj, e) => {
        console.debug(`RTSB: Device selected`, obj);
        e.preventDefault();

        const id = obj.id;
        const foundData = obj;
        selectDeviceCallback(foundData, id);
    };

    /* Just say upwards that a device was deselected */
    const onReturn = () => {
        unselectDeviceCallback()
    }

    const onTempUnitToggle = (value) => {
        const e = {
            target: {
                value: value,
                checked: value,
            }
        };
        handleSelectTemperatureMeasurementCallBack(e);
    }

    const onMenuCollapseToggle = (v) => {
        menuCollapsedCallback({}, v);
        if (v) {
            unselectDeviceCallback();
        }
    };

    /* DOM creation */

    const expandedViewOpts = {
        setMenuCollapsed: onMenuCollapseToggle,
        devicesAlt,
        controls: ([
            <TempUnitSelector
                celsius={celsius}
                setCelsius={onTempUnitToggle}
            />,
            <SettingsModal
                options={optionsData}
            />,
        ]),
    };

    const deviceDetailOpts = {
        onReturn,
        selectedDeviceData,
        celsius,
        map,
    };

    const deviceTableOpts = {
        deviceData,
        devicesAlt,
        isMarine,
        celsius,
        onDeviceSelected: onDeviceSelect,
        dataProcessing,
        recruits,
    };


    const deviceList = menuCollapsed
        ? createCollapsedView({ setMenuCollapsed: onMenuCollapseToggle, devicesAlt })
        : createExpandedView({
            ...expandedViewOpts,
            body: (selectedDevice === NO_DEVICE_SELECTED)
            ? createDeviceOverviewTable(deviceTableOpts)
            : (createDeviceDetailView(deviceDetailOpts)),
        });

    return (
        <div className="box real-time-sidebar">
            {deviceList}
        </div>
    );
}
