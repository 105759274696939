import { retrieveSessionData, storeSessionData, deleteSessionData } from "./session-storage-util";

const USER_DATA = "HRAPS_USER_DATA";

export function getUserSessionData() {
    return retrieveSessionData(USER_DATA);
}

export function createUserSessionData(userData) {
    storeSessionData(USER_DATA, userData);
}

export function deleteUserSessionData() {
    deleteSessionData(USER_DATA);
}