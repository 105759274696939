import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  refreshConfig,
  saveConfig,
  sendConfig,
  getCommhubSettings,
} from "../../../actions/comm-hub-actions";
import _, { set } from "lodash";
import {
  faPen,
  faSync,
  faSave,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseSvg } from "./CommHubSvgs";
import ReactTooltip from "react-tooltip";

function CommHubDetails(props = {}) {
  const { commhub, mac, receivingDevices, handleToast } = props;
  const macAddress = commhub ? commhub.mac : mac;
  const use24HrTime = Cookies.get("use24HrTime") === "true";
  const [editSettings, setEditSettings] = useState(false);

  //Status Variables
  const [dataMode, setDataMode] = useState("");
  const [otaEnabled, setOtaEnabled] = useState(false);
  const [serverDestination, setServerDestination] = useState("");
  const [replaceTimestamps, setReplaceTimestamps] = useState(false);
  const [loraMode, setLoraMode] = useState("");
  const [floodedMsgBroad, setFloodedMsgBroad] = useState(0);
  const [gpsUpdateInterval, setGpsUpdateInterval] = useState(0);
  const [realTimePhysioInterval, setRealTimePhysioInterval] = useState(0);
  const [physioLoggingInterval, setPhysioLoggingInterval] = useState(0);
  const [routListInterval, setRoutListInterval] = useState(0);
  const [gpsFloodInterval, setGpsFloodInterval] = useState(0);
  const [realTimeGpsInterval, setRealTimeGpsInterval] = useState(0);
  const [gpsLoggingInterval, setGpsLoggingInterval] = useState(0);
  const [realTimePhysioEnabled, setRealTimePhysioEnabled] = useState(false);
  const [physioLoggingEnabled, setPhysioLoggingEnabled] = useState(false);
  const [realTimeGpsEnabled, setRealTimeGpsEnabled] = useState(false);
  const [gpsLoggingEnabled, setGpsLoggingEnabled] = useState(false);
  const [otaServerSource, setOtaServerSource] = useState("");
  const [battShutdownThreshold, setBattShutdownThreshold] = useState(0);
  const [lteLoraOnlyEnabled, setLteLoraOnlyEnabled] = useState(false);
  const [lteMappingEnabled, setLteMappingEnabled] = useState(false);
  const [ledEnabled, setLedEnabled] = useState(false);
  const [ledBrightness, setLedBrightness] = useState("");
  const [settingsTimeStamp, setSettingsTimestamp] = useState("");
  
  const [globalSettingsSaved, setGlobalSettingsSaved] = useState(true);

  // Data Points for Payloads
  const dataPoints = {
    dataMode,
    otaEnabled,
    serverDestination,
    replaceTimestamps,
    loraMode,
    floodedMsgBroad,
    gpsUpdateInterval,
    realTimePhysioInterval,
    physioLoggingInterval,
    routListInterval,
    gpsFloodInterval,
    realTimeGpsInterval,
    gpsLoggingInterval,
    realTimePhysioEnabled,
    physioLoggingEnabled,
    realTimeGpsEnabled,
    gpsLoggingEnabled,
    otaServerSource,
    battShutdownThreshold,
    lteLoraOnlyEnabled,
    lteMappingEnabled,
    ledEnabled,
    ledBrightness,
  };

  // Set each status
  const setSettings = (res) => {
    handleDataMode(res.dataMode);
    setOtaEnabled(res.otaEnabled);
    handleServerDestination(res.serverDestination);
    setReplaceTimestamps(res.replaceTimestamps);
    handleLoraMode(res.loraMode);
    setFloodedMsgBroad(res.floodedMsgBroad);
    setGpsUpdateInterval(res.gpsUpdateInterval);
    setRealTimePhysioInterval(res.realTimePhysioInterval);
    setRoutListInterval(res.routListInterval);
    setGpsFloodInterval(res.gpsFloodInterval);
    setRealTimePhysioEnabled(res.realTimePhysioEnabled);
    setPhysioLoggingEnabled(res.physioLoggingEnabled);
    setRealTimeGpsEnabled(res.realTimeGpsEnabled);
    setGpsLoggingEnabled(res.gpsLoggingEnabled);
    handleOTAServerSource(res.otaServerSource);
    setBattShutdownThreshold(res.battShutdownThreshold);
    setLteLoraOnlyEnabled(res.lteLoraOnlyEnabled);
    setLteMappingEnabled(res.lteMappingEnabled);
    setLedEnabled(res.ledEnabled);
    handleLEDBrightness(res.ledBrightness);
    handleTimestamp(res.timestamp);
    setPhysioLoggingInterval(res.physioLoggingInterval);
    setRealTimeGpsInterval(res.realTimeGpsInterval);
    setGpsLoggingInterval(res.gpsLoggingInterval);
  };

  // API Calls
  const getCommHubDetails = () => {
    refreshConfig(macAddress)
      .then((res) => {
        console.log("Fetched commhub settings");
        setSettings(res);
        handleToast("success", `Settings for ${macAddress} successfully retrieved`);
      })
      .catch((err) => {
        console.log("Failed to get active commhub settings", err);
        handleToast("failure", `Failed to retrieve settings for ${macAddress}: ${err.message}`);
      });
  };

  const saveCommHubDetails = () => {
    saveConfig(dataPoints)
      .then((res) => {
        console.log("Saved comm hub settings");
        setGlobalSettingsSaved(true);
        handleToast("success", "Successfully saved global commhub settings");
      })
      .catch((err) => {
        console.log("Failed to save active commhub settings", err);
        handleToast("failure", `Failed to save global commhub settings: ${err.message}`);
      });
  };

  const sendCommHubDetails = () => {
    sendConfig(macAddress, dataPoints, receivingDevices)
      .then((res) => {
        console.log("Sent comm hub settings");
        handleToast("success", "Successfully sent commhub settings");
      })
      .catch((err) => {
        console.log("Failed to send active commhub settings", err);
        handleToast("failure", `Failed to send commhub settings: ${err.message}`);
      });
  };

  const globalSendAndSave = async () => {
    try {
      if (receivingDevices.length === 0) {
        throw new Error("Must select at least one device to receive settings"); 
      }
      let saveSuccess, errMessage;
      if (!globalSettingsSaved) {
        try {
          await saveConfig(dataPoints);
          saveSuccess = true;
        } catch (err) {
          console.log("Failed to save global settings", err.message);
          saveSuccess = false;
          errMessage = err.message;
        }
      }
      await sendConfig(macAddress, dataPoints, receivingDevices);
      handleToast("success", `Successfully sent ${(saveSuccess === undefined ? "" : saveSuccess === true ? "and saved" : `but could not save (error: ${errMessage}`)} global commhub settings`);
      setGlobalSettingsSaved(true);
    } catch (err) {
      console.log(`Failed to send global settings: ${err.message}`)
      handleToast("failure", `Failed to send global settings: ${err.message}`);
    }
  }

  useEffect(() => {
    if (macAddress === "global") {
      getCommHubDetails(macAddress);
    } else {
      try {
        getCommhubSettings().then((res, err) => {
          try {
            if (Object.keys(res).length > 1) {
              setSettings(res[macAddress]);
            }
          } catch (err) {
            alert("No commhub settings found.");
          }
        });
      } catch (err) {
        console.log("err", err);
      }
    }
  }, []);

  // Action Handlers
  const setters = {
    dataMode: setDataMode,
    otaEnabled: setOtaEnabled,
    serverDestination: setServerDestination,
    replaceTimestamps: setReplaceTimestamps,
    loraMode: setLoraMode,
    floodedMsgBroad: setFloodedMsgBroad,
    gpsUpdateInterval: setGpsUpdateInterval,
    realTimePhysioInterval: setRealTimePhysioInterval,
    physioLoggingInterval: setPhysioLoggingInterval,
    routListInterval: setRoutListInterval,
    gpsFloodInterval: setGpsFloodInterval,
    realTimeGpsInterval: setRealTimeGpsInterval,
    gpsLoggingInterval: setGpsLoggingInterval,
    realTimePhysioEnabled: setRealTimePhysioEnabled,
    physioLoggingEnabled: setPhysioLoggingEnabled,
    realTimeGpsEnabled: setRealTimeGpsEnabled,
    gpsLoggingEnabled: setGpsLoggingEnabled,
    otaServerSource: setOtaServerSource,
    battShutdownThreshold: setBattShutdownThreshold,
    lteLoraOnlyEnabled: setLteLoraOnlyEnabled,
    lteMappingEnabled: setLteMappingEnabled,
    ledEnabled: setLedEnabled,
    ledBrightness: setLedBrightness,
    settingsTimeStamp: setSettingsTimestamp,
  };

  const handleInputChange = (e) => {
    const val =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    console.debug(`${e.target.name} change to ${val}`);
    setters[e.target.name](val);
    setGlobalSettingsSaved(false);
  };

  const handleEditButton = () => {
    return editSettings ? setEditSettings(false) : setEditSettings(true);
  };

  // Option Logic for displaying the correct option
  const handleDataMode = (dataMode) => {
    if (parseInt(dataMode) === 0 || dataMode === "LoRa Only") {
      setDataMode("LoRa Only");
    } else if (parseInt(dataMode) === 1 || dataMode === "LTE Only") {
      setDataMode("LTE Only");
    } else if (parseInt(dataMode) === 2 || dataMode === "LoRa and LTE") {
      setDataMode("LoRa and LTE");
    } else {
      setDataMode(dataMode);
    }
  };

  const availableDataModes = ["LoRa Only", "LTE Only", "LoRa and LTE"];

  const dataModeOpts = availableDataModes.map((t, i) => (
    <option key={`data-mode-${i}`} value={t}>
      {t}
    </option>
  ));

  const handleServerDestination = (_serverDestination) => {
    if (
      parseInt(_serverDestination) === 0 ||
      _serverDestination === "Production"
    ) {
      setServerDestination("Production");
    } else if (
      parseInt(_serverDestination) === 1 ||
      _serverDestination === "Development"
    ) {
      setServerDestination("Development");
    } else {
      setServerDestination(serverDestination);
    }
  };

  const availableServerDestination = ["Production", "Development"];

  const serverDestinationOpts = availableServerDestination.map((t, i) => (
    <option key={`server-destination-mode-${i}`} value={t}>
      {t}
    </option>
  ));

  const handleLoraMode = (_loraMode) => {
    if (parseInt(_loraMode) === 0 || _loraMode === "Beacon") {
      setLoraMode("Beacon");
    } else if (parseInt(_loraMode) === 1 || _loraMode === "Mesh") {
      setLoraMode("Mesh");
    } else {
      setLoraMode(loraMode);
    }
  };

  const availableLoraModes = ["Beacon", "Mesh"];

  const loraModeOpts = availableLoraModes.map((t, i) => (
    <option key={`lora-mode-${i}`} value={t}>
      {t}
    </option>
  ));

  const handleOTAServerSource = (_otaServerSource) => {
    if (parseInt(_otaServerSource) === 0 || _otaServerSource === "Production") {
      setOtaServerSource("Production");
    } else if (
      parseInt(_otaServerSource) === 1 ||
      _otaServerSource === "Development"
    ) {
      setOtaServerSource("Development");
    } else {
      setOtaServerSource(otaServerSource);
    }
  };

  const availableOTAServers = ["Production", "Development"];

  const otaServerDestinationOpts = availableOTAServers.map((t, i) => (
    <option key={`server-destination-mode-${i}`} value={t}>
      {t}
    </option>
  ));

  const handleLEDBrightness = (_ledBrightness) => {
    if (parseInt(_ledBrightness) === 1 || _ledBrightness === "Low") {
      setLedBrightness("Low");
    } else if (parseInt(_ledBrightness) === 2 || _ledBrightness === "Medium") {
      setLedBrightness("Medium");
    } else if (parseInt(_ledBrightness) === 3 || _ledBrightness === "High") {
      setLedBrightness("High");
    } else {
      setLedBrightness(ledBrightness);
    }
  };

  const availableLEDBrightness = ["Low", "Medium", "High"];

  const ledBrightnessOpts = availableLEDBrightness.map((t, i) => (
    <option key={`server-destination-mode-${i}`} value={t}>
      {t}
    </option>
  ));

  const dateFormatOptions = {
    hour12: !use24HrTime,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const handleTimestamp = (_timestamp) => {
    setSettingsTimestamp(
      new Date(_timestamp).toLocaleString("en-US", dateFormatOptions)
    );
  };

  /// Dynamic Ui Components
  const createSelectOneToOneHundredDropdown = (label, name, value) => {
    let obj = {
      array: [],
    };

    for (let l = 0; l < 100; l++) {
      obj.array[l] = l + 1;
    }

    let optionItems = obj.array.map((item) => (
      <option key={item}>{item}</option>
    ));

    return (
      <article className="tile is-child">
        <div className="field">
          <label className="label">{label}</label>
          <div className="select is-fullwidth">
            <select
              name={name}
              value={value}
              disabled={editSettings ? "" : "disabled"}
              onChange={handleInputChange}
            >
              <option></option>
              {optionItems}
            </select>
          </div>
        </div>
      </article>
    );
  };

  const createLabelAndSwitch = (label, key, switchBool) => {
    const enabledSwitch = "f-switch is-success";
    const disabledSwitch = "f-switch is-success is-disabled";

    return (
      <article className="tile is-child">
        <div className="container">
          <div className="field flex">
            <label className="label" style={{ width: "75%" }}>
              {label}
            </label>
            <span className="control ml-4">
              <label className={editSettings ? enabledSwitch : disabledSwitch}>
                <input
                  type="checkbox"
                  className="is-switch"
                  name={key}
                  checked={switchBool}
                  onClick={handleInputChange}
                  onChange={handleInputChange}
                  aria-label="On"
                />
                <i></i>
              </label>
            </span>
          </div>
        </div>
      </article>
    );
  };

  const createNumberInput = (label, name, value) => {
    return (
      <article className="tile is-child">
        <div className="field">
          <label className="label">{label}</label>
          <div className="control">
            <input
              className="input"
              type="number"
              name={name}
              value={value}
              disabled={editSettings ? "" : "disabled"}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </article>
    );
  };

  const createOptionSelect = (label, name, value, options) => {
    return (
      <article className="tile is-child">
        <div className="field">
          <label className="label">{label}</label>
          <div className="select is-fullwidth">
            <select
              name={name}
              value={value}
              disabled={editSettings ? "" : "disabled"}
              onChange={handleInputChange}
            >
              <option></option>
              {options}
            </select>
          </div>
        </div>
      </article>
    );
  };

  return (
    <>
      <div className="block"></div>
      <div className="container">
        <div className="container">
          <div className="block"></div>
          <div className="level">
            <div className="level-item level-left">
              <h3 className="title is-4">
                Settings: {macAddress !== "global" ? macAddress : "Global"}
              </h3>
            </div>
            <div className="level-item level-right">
              <div className="field">
                <button
                  className="button icon-button"
                  onClick={props.handleClose}
                >
                  {CloseSvg}
                </button>
              </div>
            </div>
          </div>
          <div className="level">
            <div className="level-item level-right">
              <div className="portal-buttons">
                {macAddress === "global" ? (
                  <button
                    data-tip
                    data-for={"saving"}
                    className="button icon-button"
                    onClick={saveCommHubDetails}
                  >
                    <FontAwesomeIcon icon={faSave} />
                    <ReactTooltip id={"saving"} type="light" place="bottom">
                      Save
                    </ReactTooltip>
                  </button>
                ) : null}
                <button
                  data-tip
                  data-for={"upload"}
                  className="button icon-button is-secondary "
                  onClick={macAddress === "global" ? globalSendAndSave : sendCommHubDetails}
                >
                  <FontAwesomeIcon icon={faUpload} />
                </button>
                <ReactTooltip id={"upload"} type="light" place="bottom">
                  Upload
                </ReactTooltip>
                <button
                  data-tip
                  data-for={"sync"}
                  className="button icon-button"
                  onClick={getCommHubDetails}
                >
                  <FontAwesomeIcon icon={faSync} />
                  <ReactTooltip id={"sync"} type="light" place="bottom">
                    Sync
                  </ReactTooltip>
                </button>
                <button
                  data-tip
                  data-for={"edit"}
                  className="button icon-button"
                  onClick={handleEditButton}
                >
                  <FontAwesomeIcon icon={faPen} />
                  <ReactTooltip id={"edit"} type="light" place="bottom">
                    Edit
                  </ReactTooltip>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <article className="tile is-child">
              <div className="field">
                <label className="label">
                  Last Received Settings: {settingsTimeStamp}
                </label>
              </div>
            </article>
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-6 is-flex is-flex-direction-column is-justify-content-space-between">
            <div className="list-widget">
              <div className="toolbar is-space-bottom">
                <div className="toolbar-start">General</div>
              </div>
              <div>
                {createLabelAndSwitch("LED Enabled", "ledEnabled", ledEnabled)}
                {createLabelAndSwitch("OTA Enabled", "otaEnabled", otaEnabled)}
                {createLabelAndSwitch(
                  "Replace Timestamps Enabled",
                  "replaceTimestamps",
                  replaceTimestamps
                )}
              </div>
            </div>
            <div className="list-widget">
              <div className="toolbar is-space-bottom">
                <div className="toolbar-start">GPS</div>
              </div>
              <div>
                {createLabelAndSwitch(
                  "GPS Logging Enabled",
                  "gpsLoggingEnabled",
                  gpsLoggingEnabled
                )}
                {createLabelAndSwitch(
                  "Live GPS Enabled",
                  "realTimeGpsEnabled",
                  realTimeGpsEnabled
                )}
              </div>
            </div>
            <div className="list-widget">
              <div className="toolbar is-space-bottom">
                <div className="toolbar-start">LORA + LTE</div>
              </div>
              <div>
                {createLabelAndSwitch(
                  "LTE LoRa Only Enabled",
                  "lteLoraOnlyEnabled",
                  lteLoraOnlyEnabled
                )}
                {createLabelAndSwitch(
                  "LTE Mapping Enabled",
                  "lteMappingEnabled",
                  lteMappingEnabled
                )}
              </div>
            </div>
            <div className="list-widget">
              <div className="toolbar is-space-bottom">
                <div className="toolbar-start">Physio</div>
              </div>
              <div>
                {createLabelAndSwitch(
                  "Physio Logging Enabled",
                  "physioLoggingEnabled",
                  physioLoggingEnabled
                )}
                {createLabelAndSwitch(
                  "Live Physio Enabled",
                  "realTimePhysioEnabled",
                  realTimePhysioEnabled
                )}
              </div>
            </div>
          </div>
          <div className="column is-6">
            <div className="list-widget">
              <div className="toolbar is-spaced-bottom">
                <div className="toolbar-start">
                  <h4 className="title is-6">Modes and Settings</h4>
                </div>
                <div className="toolbar-end"></div>
              </div>
              <div className="block"></div>
              <ul className="inner-list">
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createOptionSelect(
                        "Data Mode",
                        "dataMode",
                        dataMode,
                        dataModeOpts
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createOptionSelect(
                        "Server Destination",
                        "serverDestination",
                        serverDestination,
                        serverDestinationOpts
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createOptionSelect(
                        "OTA Server Source",
                        "otaServerSource",
                        otaServerSource,
                        otaServerDestinationOpts
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createNumberInput(
                        "Battery Shutdown Threshold",
                        "battShutdownThreshold",
                        battShutdownThreshold,
                        otaServerDestinationOpts
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createOptionSelect(
                        "LED Brightness",
                        "ledBrightness",
                        ledBrightness,
                        ledBrightnessOpts
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createOptionSelect(
                        "LoRa Mode",
                        "loraMode",
                        loraMode,
                        loraModeOpts
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createNumberInput(
                        "Flooded Message Broad",
                        "floodedMsgBroad",
                        floodedMsgBroad,
                        otaServerDestinationOpts
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="column is-6">
            <div className="list-widget">
              <div className="toolbar is-spaced-bottom">
                <div className="toolbar-start">
                  <h4 className="title is-6"> GPS Intervals</h4>
                </div>
                <div className="toolbar-end"></div>
              </div>
              <div className="block"></div>
              <ul className="inner-list">
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createSelectOneToOneHundredDropdown(
                        "GPS Flood Interval",
                        "gpsFloodInterval",
                        gpsFloodInterval
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createSelectOneToOneHundredDropdown(
                        "GPS Update Interval",
                        "gpsUpdateInterval",
                        gpsUpdateInterval
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createSelectOneToOneHundredDropdown(
                        "Real Time GPS Interval",
                        "realTimeGpsInterval",
                        realTimeGpsInterval
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createSelectOneToOneHundredDropdown(
                        "GPS Logging Interval",
                        "gpsLoggingInterval",
                        gpsLoggingInterval
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="column is-6">
            <div className="list-widget">
              <div className="toolbar is-spaced-bottom">
                <div className="toolbar-start">
                  <h4 className="title is-6"> Other Intervals</h4>
                </div>
                <div className="toolbar-end"></div>
              </div>
              <div className="block"></div>
              <ul className="inner-list">
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createSelectOneToOneHundredDropdown(
                        "Real Time Physio Interval",
                        "realTimePhysioInterval",
                        realTimePhysioInterval
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createSelectOneToOneHundredDropdown(
                        "Route list Interval",
                        "routListInterval",
                        routListInterval
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-list-item">
                    <div className="list-item-data">
                      {createSelectOneToOneHundredDropdown(
                        "Physio Logging Interval",
                        "physioLoggingInterval",
                        physioLoggingInterval
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="block"></div>
        <div className="block"></div>
      </div>
    </>
  );
}

export default withRouter(CommHubDetails);
