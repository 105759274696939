import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faCog, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Button from "../../misc/Button";
import pathList from "../../../util/path-list";
import CommHubStatusPanel from "./CommHubStatusPanel";
import CommHubDetails from "./CommHubDetails";

export default function ActiveCommhubCard({
  status,
  commhub,
  receiveGlobal,
  onSelect,
  handleToast,
}) {
  const [globeHover, setGlobeHover] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const targetRef = useRef(null);
  const [commStatus, setCommStatus] = useState(status);
  const timeSince = (timestamp) => {
    const time = Math.floor(timestamp / 1000);
    const secondsAgo = Math.floor(Date.now() / 1000) - time;
    const minutesAgo = Math.floor(secondsAgo / 60);
    return secondsAgo < 60 ? `${secondsAgo}s` : `${minutesAgo}m`;
  };

  useEffect(() => {
    if (showStatusModal && targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth", // Optional: Add smooth scrolling animation
        block: "start", // Scroll to the top of the element
      });
    }
  }, [showStatusModal]);

  useEffect(() => {
    if (showSettingsModal && targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth", // Optional: Add smooth scrolling animation
        block: "start", // Scroll to the top of the element
      });
    }
  }, [showSettingsModal]);

  const handleStatusClose = () => {
    setShowStatusModal(false);
  };

  const handleSettingsClose = () => {
    setShowSettingsModal(false);
  };

  const getPortalContent = (str) => {
    if (str === "status" && showStatusModal) {
      return (
        <div>
          <CommHubStatusPanel
            handleClose={handleStatusClose}
            mac={commhub.mac}
            handleToast={handleToast}
          />
        </div>
      );
    }
    if (str === "settings" && showSettingsModal) {
      return (
        <div>
          <CommHubDetails
            handleClose={handleSettingsClose}
            commhub={commhub}
            macAddress={commhub.mac}
            handleToast={handleToast}
          />
        </div>
      );
    }
  };

  return (
    <div>
      <div className="column">
        <div className="is-child box">
          <span className="">
            <span className="title is-flex is-justify-content-space-between is-align-items-center">
              <p className="is-flex">HUB {commhub.serial_num} </p>
              <i className="subtitle mb-0 is-size-6">{commhub.mac}</i>
              <FontAwesomeIcon
                className="is-clickable"
                icon={faGlobe}
                onMouseEnter={() => setGlobeHover(true)}
                onMouseLeave={() => setGlobeHover(false)}
                color={
                  receiveGlobal
                    ? "hsl(261, 90%, 59%)"
                    : globeHover
                    ? "#a29bfe"
                    : "rgb(140, 140, 140)"
                }
                onClick={() => onSelect(commhub.mac)}
              />
            </span>
          </span>
          <div className="columns mt-1">
            <div className="column is-7">
              <p className="mb-4 mt-2">Version Something</p>
              <style>
                {`
							@keyframes pulsate {
								0% {
									transform: scale(1);
								}
								50% {
									transform: scale(1.2);
								}
								100% {
									transform: scale(1);
								}
							}

							.pinging {
								fill: rgb(6, 214, 158);
								animation: pulsate 2s ease infinite;
								transform-origin: center;
							}

							.notPinging {
								fill: grey
							}
						`}
              </style>
              <span className="is-flex is-justify-content-space-between">
                <svg
                  width="50px"
                  height="50px"
                  viewBox="0 0 25 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className={
                      Math.floor(Date.now() / 1000) -
                        Math.floor(commhub.timestamp / 1000) <=
                      10
                        ? "pinging"
                        : "notPinging"
                    }
                    d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                    fill="black"
                  />
                  <text
                    x="50%"
                    y="48%"
                    textAnchor="middle"
                    dominantBaseline="central"
                    fill="black"
                    fontSize="5"
                    fontFamily="Arial"
                  >
                    {timeSince(commhub.timestamp)}
                  </text>
                </svg>
                <svg
                  width="60"
                  height="45"
                  viewBox="0 0 50 15"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1"
                    y="1"
                    width="44"
                    height="23"
                    rx="3"
                    ry="3"
                    stroke="black"
                    fill="none"
                    strokeWidth="2"
                  />
                  <rect
                    x="3"
                    y="3"
                    width={Math.floor(commhub.battery * 0.4).toString()}
                    height="19"
                    rx="2"
                    ry="2"
                    fill={
                      commhub.battery > 50
                        ? "#4CD964"
                        : commhub.battery > 10
                        ? "rgb(255, 190, 0)"
                        : "rgb(255, 0, 0)"
                    }
                  />
                  <rect x="45" y="8" width="3" height="9" fill="black" />
                  <text
                    x="50%"
                    y="87%"
                    alignmentBaseline="middle"
                    textAnchor="middle"
                    fontSize="12"
                    fill="black"
                  >
                    {commhub.battery}%
                  </text>
                </svg>
                <div>{/* THIS IS SPACING FILLER */}</div>
              </span>
            </div>
            <div className="column is-5 is-flex is-flex-direction-column is-justify-content-space-between">
              <Button
                type="normal"
                onClick={() => {
                  setShowSettingsModal(!showSettingsModal);
                }}
                icon={faCog}
                classList={["is-fullwidth"]}
              >
                Config
              </Button>
              <Button
                type="normal"
                icon={faAngleDown}
                onClick={() => {
                  setShowStatusModal(!showStatusModal);
                }}
                classList={["is-fullwidth", "is-light"]}
              >
                Status
              </Button>
              {showStatusModal &&
                createPortal(
                  getPortalContent("status"),
                  document.querySelector("#status-" + commhub.serial_num)
                )}
              {showSettingsModal &&
                createPortal(
                  getPortalContent("settings"),
                  document.querySelector("#settings-" + commhub.serial_num)
                )}
            </div>
          </div>
        </div>
        <div className="">
          <div
            ref={targetRef}
            id={"status-" + commhub.serial_num}
            className={`portal-content ${
              showStatusModal ? "visible" : "hidden"
            }`}
          ></div>
          <div
            ref={targetRef}
            id={"settings-" + commhub.serial_num}
            className={`portal-content ${
              showSettingsModal ? "visible" : "hidden"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
}
