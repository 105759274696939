import React from "react";

import gtriLogo from "../../../assets/gtri_logo.svg";

export default class GtriLogo extends React.Component {
    static defaultProps = {
        logoWidth: 200
    }

    render() {
        const style = { width: this.props.logoWidth }

        return (
            <div className="gtri-logo">
                <img src={gtriLogo} alt="GTRI Logo" style={style} />
            </div>
        );
    }
}