import {useState, useEffect} from 'react';

import {getPageOfEvents} from '../../actions/event-actions';

import Button from '../misc/Button'

/**
 *
 * @param {Number} start
 * @param {Number} end
 *      [Optional] If defined, start is starting point and end is non-inclusive end. Otherwise,
 *      start is actually end value and start is started at 0.
 *
 * @returns
 *      Array of values incremented by 1 until `end` non-inclusive.
 */
function range(start, end) {
    if (end === undefined) {
        end = start;
        start = 0;
    }
    const len = Math.abs(end - start);
    const arr = [...(new Array(len)).keys()];
    return arr.map((_, i) => (start > end) ? start - i : start + i);
}

function onlyActiveEvents(event) {
    return event.status === 'inProgress';
}

/**
 * Query all the events by utilizing the paged api sorting by time since that'll likely be the most
 * important metric for event order.
 */
function queryEvents(cb) {
    const eventsPerPage = 3;
    const opts = {
        eventsPerPage,
        includeMeta: true,
        throwOnError: true,
        sortKey: 'actualSTUnix',
        sortValue: -1,
    };
    let events = [];
    getPageOfEvents(0, opts).then((payload) => {
        events = payload.data;
        if (events.length < payload.metadata.total) {
            const pages = Math.ceil(payload.metadata.total / eventsPerPage);
            const queries = range(1, pages).map(async (page) => {
                return getPageOfEvents(page, opts);
            });
            return Promise.all(queries);
        }
        return null;
    }).then((payload) => {
        if (!!payload) {
            let otherEvents = payload.map((pl) => {
                return pl.data;
            })
            events = [...events, ...otherEvents.flat(1)];
            events = events.filter(onlyActiveEvents);
        }
        console.debug('EventSelector: Returing events', events);
        cb(events);
    }).catch((err) => {
        console.error('EventSelector: Failed to query events.', err.message);
    });
}

export default function EventSelector(props = {}) {
    const {
        events = [],
        onEventsUpdate = ((_events) => {}),
        selectedEvent = null,
        onEventSelected = ((_event) => {}),
    } = props;

    const [localEvents, setLocalEvents] = useState(events);
    const [selectedEvent_, setSelectedEvent] = useState(selectedEvent);
    const [selectedId, setSelectedId] = useState(selectedEvent_?.id ?? null);

    const eventSelectChange = (e) => {
        console.debug('ES: Current hover', e.target);
        const eventId = e.target.value;
        if (eventId === null || eventId === '') {
            setSelectedEvent(null);
            setSelectedId(null);
            return;
        }
        const event = localEvents.find((v) => v.id === eventId);
        setSelectedEvent(event);
        setSelectedId(eventId);
        console.debug(`ES: ${eventId}`, event);
    }

    const handleOk = () => {
        onEventSelected(selectedEvent_);
    }

    const clearEvent = () => {
        setSelectedEvent(null);
        setSelectedId(null);
        onEventSelected(null);
    }

    /*----------------------------------------*/

    useEffect(() => {
        if (events.length === 0) {
            queryEvents(setLocalEvents);
        }
    }, []);

    useEffect(() => {
        if (localEvents.length > 0) {
            onEventsUpdate(localEvents)
        }
    }, [localEvents])

    /*----------------------------------------*/

    return (
        <>
            <div className="level">
                <div className="level-item">
                    <div className="select">
                        <select value={selectedId ?? ''} onChange={eventSelectChange}>
                            <option value={''}>Select Event</option>
                            {
                                localEvents.map((v) => (
                                    <option key={`ev-sel-${v.id}`} value={v.id}>{v.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>

            <div className="block"></div>

            <div className="card-footer">
                <Button classList={["m-2"]} onClick={handleOk} activeColor='is-success'>
                    Set
                </Button>
                <Button classList={["m-2"]} onClick={clearEvent}>
                    Clear
                </Button>
            </div>
        </>
    );
}
