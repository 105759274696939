import React from "react";

import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import ConfigurationHeader from "../ConfigurationHeader";
import ApiSortableTable from "../../misc/ApiSortableTable";
import Button from "../../misc/Button";
import SearchFilter from "../SearchFilter";

import { deactivateRecruit, activateRecruit, getPageOfRecruits } from "../../../actions/recruit-actions";

import { getUserSessionData } from "../../../util/user-session-util";

import PathList from "../../../util/path-list";


export default class RecruitConfiguration extends React.Component {
    static defaultProps = {
        headers: [
            { name: "Name", sortKey: 'name' },
            { name: "Roster ID", sortKey: 'displayName' },
            { name: "Org.", sortKey: 'org', title: "Organization" },
            { name: "Dev. MAC Address", title: "Current Device MAC Address" },
            { name: "Dev. Serial #", title: "Current Device Serial Number" },
            { name: "Last Event" },
            { name: "Status", sortKey: 'isDeleted' },
            { name: "Actions" }
        ]
    }

    constructor(props) {
        super(props);

        this.state = {
            recruits: [],
            total: 0,
            showInactiveRecruits: false,
            searchParams: {
                query: '',
                status: '',
                page: 0,
                limit: 20,
                org: '',
                deleted: 'false',
            },
            searchConfig: [
                {type: 'radio', key: 'queryFields', options: [
                    {label: 'By Name', value: '', default: true},
                    {label: 'By Dev MAC', value: 'firstDevice.nativeDeviceId'},
                    {label: 'By Dev Serial #', value: 'firstDevice.friendlyName'},
                    {label: 'By Last Event', value: 'lastEvent.name'},
                ]},
            ],
        }

        this.updateRecruitList = this.updateRecruitList.bind(this);
        this.toggleInactiveRecruitsFilter = this.toggleInactiveRecruitsFilter.bind(this);
        this.updateSearchParams = this.updateSearchParams.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }


    updateSearchParams(searchParams) {
        for(let key in searchParams) {
            this.state.searchParams[key] = searchParams[key];
        }
    }

    updateRecruitList() {
        const searchParams = {
            ...this.state.searchParams,
            recruitsPerPage: this.state.searchParams.limit,
            includeMeta: true,
            throwOnError: true,
        }
        getPageOfRecruits(searchParams.page, searchParams).then((p) => { // TODO: Like the Devices call, this needs to be updated on the backend to not have a page limit
            if (!('metadata' in p)) {
                throw new Error(p);
            }
            const data = p.data;
            const metadata = p.metadata;
            const recruits = data || [];
            const total = metadata.total || 0;
            console.log("GOT ALL RECRUITS", recruits);
            this.setState({ recruits, total });
        }).catch((e) => {
            console.error(`${e.message || e}`);
        });
    }

    componentDidMount() {
      const userToken = getUserSessionData()
      if (!userToken) {
              window.location.href = PathList.login;
              return;
      }
      this.updateRecruitList();
    }

    handleDeactivate(recruitId) {
        deactivateRecruit(recruitId).then(() => (
            this.updateRecruitList()
        )).catch((err) => (
            console.error('Failed to deactivate recruit:', err)
        ));
    }

    handleActivate(recruitId) {
        activateRecruit(recruitId).then(() => (
            this.updateRecruitList()
        )).catch((err) => (
            console.error('Failed to activate recruit:', err)
        ));
    }

    transformRecruitListForTable(recruitList) {
        return recruitList?.map(v => {

            const editButton = <Button
                type='link'
                path={PathList.recruitUpdate(v.id)}
                classList={["mx-1"]}
                >Edit</Button>;
            const statusUpdateButton = <Button
                activeColor={v?.isDeleted ? " is-success" : " is-danger"}
                classList={['mx-1', "is-light"]}
                onClick = {() => v?.isDeleted ? this.handleActivate(v.id) : this.handleDeactivate(v.id)}
                >{v?.isDeleted ? "Activate" : "Deactivate"}</Button>;

            return [
                v?.name,
                v?.displayName,
                v?.org,
                v?.devices?.[0]?.nativeDeviceId || "None",
                v?.devices?.[0]?.friendlyName || "None",
                v?.events?.[v?.events?.length - 1]?.name || "None",
                v?.isDeleted ? "Deactivated" : "Activated",
                <div>{editButton}{statusUpdateButton}</div>
            ];
        });
    }

    handleOnChange(e) {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                org: e.target.value,
            }
        }, () => this.updateRecruitList());
    }

    handleSearchChange(data) {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...data,
            }
        }, () => {
            console.debug('DC: New search params', this.state.searchParams)
            this.updateRecruitList()
        })
    }

    toggleInactiveRecruitsFilter(e) {
        e.preventDefault();
        const showInactiveRecruits = !this.state.showInactiveRecruits;
        const deleted = (showInactiveRecruits) ? '' : 'false';
        this.setState({
            showInactiveRecruits,
            searchParams: {
                ...this.state.searchParams,
                deleted,
            }
        }, () => this.updateRecruitList());
    }

    render() {
        const recruits = this.transformRecruitListForTable(this.state.recruits);

        return (
            <div>
                <ConfigurationHeader selectedButton={"Recruits"} previousPath={PathList.commHubs} nextPath={PathList.pair} currentPath="Recruits"/>

                <div className="block"></div>

                <div className="container">
                    <div className="level">
                        <div className="level-item level-right">
                            <div className="field is-grouped">
                                <div className="control">
                                    <Button
                                        type='checkable'
                                        activeColor='is-hraps'
                                        value={this.state.showInactiveRecruits}
                                        onClick={() => this.toggleInactiveRecruitsFilter({preventDefault: () => {}})}
                                    >
                                        Show Inactive Recruits
                                    </Button>
                                </div>

                                <div className="control">
                                    <Button
                                        type='link'
                                        path={PathList.recruitAdd}
                                        icon={faPlusCircle}
                                        activeColor='is-success'
                                    >
                                        Add New Recruit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                    <SearchFilter
                        config={this.state.searchConfig}
                        onChange={(d) => this.handleSearchChange(d)}
                    />
                    </div>

                    <div className="block"></div>

                    <ApiSortableTable
                        headers={this.props.headers}
                        data={recruits}
                        total={this.state.total}
                        limit={this.state.searchParams.limit}
                        onPageChange={(page, _limit) => {
                            this.setState({
                                searchParams: {
                                    ...this.state.searchParams,
                                    page,
                                }
                            }, () => this.updateRecruitList())
                        }}
                        onSortChange={(sortKey, sortValue) => {
                            this.setState({
                                searchParams: {
                                    ...this.state.searchParams,
                                    sortKey,
                                    sortValue,
                                }
                            }, () => this.updateRecruitList())
                        }}
                    />
                </div>
            </div>
        );
    }
}
