import {useEffect, useState} from 'react';

import Button from '../misc/Button';

export default function DeviceTypeSelector(props = {}) {
    const {
        deviceTypeList = [],
        selectedDeviceTypeList = [],
        onSelectedDeviceTypeListChanged = (_selDevList) => [],
    } = props;

    const [selected, setSelected] = useState(selectedDeviceTypeList);

    const handleDevStatusChange = (dev) => {
        if (selected.includes(dev)) {
            setSelected(
                selected.filter((d) => d !== dev)
            );
        }
        else {
            setSelected(
                [...selected, dev]
            );
        }
    };

    const handleOk = () => {
        onSelectedDeviceTypeListChanged(selected);
    };

    /*----------------------------------------*/

    useEffect(() => {
        if (selected.length === 0) {
            setSelected(deviceTypeList);
        }
    }, [deviceTypeList])

    /*----------------------------------------*/

    const devs = deviceTypeList.map((d, di) => (
        <span key={`dev-type-${di}`} className="p-2">
            <Button
                type='checkable'
                activeColor='is-hraps'
                value={selected.includes(d)}
                onClick={() => handleDevStatusChange(d)}
            >
                {d}
            </Button>
        </span>
    ));

    return (
        <>
            {devs}

            <div className="block"></div>

            <div className="card-footer">
                <Button classList={["m-4"]} activeColor="is-success" onClick={handleOk}>
                    Set
                </Button>
            </div>
        </>
    );
}
