import { useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';

import { ItemTypes } from './PoiItemTypes';

import PathList from "../../../util/path-list";
import ConfirmationModal from "../../misc/ConfirmationModal";
import Button from "../../misc/Button";

import { deletePoi } from "../../../actions/poi-actions";

/**
 * Element that can be used for a draggable POI table item.
 *
 * Required Properties
 *
 * - poi: The poi object item returned from page of poi request.
 * - idx: Index.
 */
export default function PoiItem(props) {
    const {
        poi = {},
        idx = 0,
        onChange = () => {},
        selectedPoi = null,
        onClicked = (_id) => {},
        onDragStarted = (_id) => {},
    } = props;

    const [modalVisible, setModalVisible] = useState(false);
    const isSelected = selectedPoi === poi.id;

    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.POI,
        item: {id: poi.id},
        collect: monitor => ({isDragging: isSelected || !!monitor.isDragging()}),
    }))

    useEffect(() => {
        if (isDragging) {
            onDragStarted(poi.id);
        }
    }, [isDragging])

    const toggleSelected = () => {
        onClicked(poi.id);
    };

    const doRemove = () => {
        deletePoi(poi.id).then(() => {
            onChange();
        }).catch((err) => {
            console.error(`Failed to delete the poi "${poi.friendlyName}"`, err);
            onChange();
        });
    };

    const editButton = <Button
        type='link'
        path={PathList.poiUpdate(poi.id)}
        classList={["mx-1"]}
        >Edit</Button>;
    const deleteButton = <Button
        activeColor='is-danger'
        classList={['mx-1', "is-light"]}
        onClick={() => setModalVisible(true)}
        >Delete</Button>;

    const modal = (modalVisible) ? (
        <ConfirmationModal
            confirmationText={(<p>Delete Point of Interest "{poi.friendlyName}"?</p>)}
            confirmButtonAction={() => {setModalVisible(false); doRemove()}}
            cancelButtonAction={() => setModalVisible(false)}
            active={modalVisible}
            />
    ) : null;

    return (
        <td key={idx} ref={drag} className={isSelected ? 'is-primary' : ''} onClick={() => toggleSelected()}>
            <div className="level is-fullwidth is-size-7">
                <div className="level-left">
                    <div className="level-item has-text-centered">
                        <div className="content">
                            <div className='has-text-weight-bold'>Name</div>
                            <div>{poi.friendlyName} ({poi.nativeDeviceId})</div>
                        </div>
                    </div>
                </div>

                <div className="level-right">
                    {
                        (poi?.isDefault) ? (
                            <div className="level-item has-text-centered has-text-weight-bold">
                                Is Default
                            </div>
                        ) : null
                    }
                    <div className="level-item has-text-centered">
                        <div>
                            <div className='has-text-weight-bold'>Type</div>
                            <div>{poi.poiType}</div>
                        </div>
                    </div>
                    <div className="level-item has-text-centered">
                        {editButton}
                        {deleteButton}
                    </div>
                </div>
            </div>
            {modal}
        </td>
    );
}
