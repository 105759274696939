// Set up basic toaster component
import React, { useState, useEffect } from 'react';
import "./toaster.css";
import ToastList from "./toast-list/ToastList";

const Toaster = (props) => {
    const [toasts, setToasts] = useState([]);
    const [autoClose, setAutoClose] = useState(true);
    const [autoCloseDuration, setAutoCloseDuration] = useState(5);
    const [position, setPosition] = useState("bottom-right");
    const [type, setType] = useState("default");

    const removeToast = (id) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };

    const positions = {
        "top-right": "Top-right",
        "top-left": "Top-left",
        "bottom-right": "Bottom-right",
        "bottom-left": "Bottom-left",
    };

    const showToast = (message, type) => {
        const toast = {
            id: Date.now(),
            "message":message,
            "type": type,
          };

          setToasts((prevToasts) => [...prevToasts, toast]);
          if (autoClose) {
            setTimeout(() => {
              removeToast(toast.id);
            }, autoCloseDuration * 1000);
          }
     }

    useEffect(() => {
        if (props.trigger > 0) {
          showToast(props["message"], props["type"]);
        }
      }, [props.trigger]);


    return (
        <div className={`toaster toaster-${type}`}>
            <ToastList data={toasts} position={position} removeToast={removeToast} />
        </div>
    )
}
// export Toaster component
export default Toaster;
