import {useState} from "react";

import {
    faAngleDown,
    faAngleUp,
} from "@fortawesome/free-solid-svg-icons";

import Button from "./Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function CollapsableSection(props = {}) {
    const {
        title = 'Section',
        subtitle = 'dddkj',
        hidable = true,
        icon=null,
    } = props;

    const [hidden, setHidden] = useState(hidable);

    const toggleHideStatus = () => {
        if (hidable) {
            setHidden(!hidden);
        }
    }

    return (
        <div className="card">
            <header className="card-header" onClick={(e) => {
                e.preventDefault();
                toggleHideStatus();
            }}>
                <p className="card-header-title">
                    {(icon != null) ? <FontAwesomeIcon icon={icon} /> : null}{title}
                </p>
                {
                    (hidable)
                    ? (
                        <Button>
                            <FontAwesomeIcon icon={hidden ? faAngleDown : faAngleUp} />
                        </Button>
                    ) : null
                }
            </header>
            <div className="card-content" hidden={hidden}>
                {props.children}
            </div>
        </div>
    );
}
