import { useDrop } from 'react-dnd';

import { ItemTypes } from './PoiItemTypes';

import Button from '../../misc/Button';

import {
    addPoiToEvent,
    rmPoiFromEvent,
} from "../../../actions/poi-actions.js";



/**
 * Element that can be used for a droppable Event table item.
 *
 * Required Properties
 *
 * - event: The event object item returned from page of event request.
 * - idx: Index.
 */
export default function EventItem(props) {
    const {
        event = {},
        onChange = (() => {}),
        selectedPoi = null,
    } = props;

    const eventId = event.id;
    const pois = event.pois ?? [];
    const poiIds = pois.map((p) => p.id);

    const doAddPoi = (poi) => {
        addPoiToEvent(eventId, poi.id).then(() => {
            onChange();
        }).catch((err) => {
            console.error(`Failed to add poi "${poi}" to event "${props.event.name}`, err);
            onChange();
        })
    };
    const doRmPoi = (poi) => {
        rmPoiFromEvent(eventId, poi.id).then(() => {
            onChange();
        }).catch((err) => {
            console.error(`Failed to rm poi "${poi}" from event "${props.event.name}`, err);
            onChange();
        })
    };

    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: ItemTypes.POI,
        drop: (id_) => (doAddPoi(id_, id_)),
        canDrop: (id_) => !poiIds.includes(id_),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop()
        })
    }));

    const useSelected = (selectedPoi != null);
    const canDrop_ = (useSelected) ? (!poiIds.includes(selectedPoi)) : (canDrop);

    const styleColor = ((useSelected && !canDrop_) || (isOver && !canDrop_))
        ? ({backgroundColor: "#f99"})
        : (!isOver && canDrop_)
        ? ({backgroundColor: "#ff9"})
        : (isOver && canDrop_)
        ? ({backgroundColor: "#9f9"})
        : ({});

    const style = {...styleColor};

    const poiTags = pois.map((p) => (
        <span className="tag is-success" key={`poi-${p.id}`}>
            <span id={(false) ? p.friendlyName: undefined}>{p.friendlyName}</span>
            <Button type="delete" classList={["is-small"]} onClick={() => doRmPoi(p)}/>
        </span>
    ));

    const clickButton = (selectedPoi == null || !canDrop_) ? null : (
        <Button activeColor="is-danger" classList={["is-small"]} onClick={() => doAddPoi({id: selectedPoi})}>
            Add to Event
        </Button>
    );

    return (
        <td key={props.idx} ref={drop} style={style}>
            <div className="level is-fullwidth is-size-7">
                <div className="level-left">
                    <div className="level-item has-text-centered">
                        <div>
                            <div className='has-text-weight-bold'>Name</div>
                            <div>{props.event.name}</div>
                        </div>
                    </div>
                </div>
                <div className="level-right">
                    {clickButton}
                </div>
            </div>
            <div className="has-text-centered">
                {poiTags}
            </div>
        </td>
    );
}
