import querystring from "querystring";
import { getUserSessionData } from "../util/user-session-util";

import { METHODS, apiCall } from "../util/fetch-util";

export async function getPageOfSquads(page,
                                       {
                                           query = "",
                                           limit = 15,
                                           deleted = "",
                                           org = "",
                                           sortKey = "",
                                           sortValue = "",
                                           queryFields = "",
                                           includeMeta = false,
                                       } = {}) {
    // This function may not need to exist since we aren't paging the device list, but is provided as a legacy to when it used to work that way...
    const userToken = getUserSessionData();
    const searchParams = {
        query,
        page,
        limit,
        deleted,
        org,
        userId: userToken.id,
        sortKey,
        sortValue,
        queryFields,
    };
    const encodedSearchParams = querystring.encode(searchParams);

    const devices = await apiCall(`/squad?${encodedSearchParams}`, METHODS.get, { throwOnError: true });

    return (includeMeta) ? devices :devices?.data;
}

export async function getSquad(id) {
    if (!id) {
        throw new Error('ID is required');
    }
    const userToken = getUserSessionData();
    const searchParams = {
        userId: userToken.id,
    };
    const encodedSearchParams = querystring.encode(searchParams);

    const res = await apiCall(`/squad/${id}?${encodedSearchParams}`, METHODS.get, { throwOnError: true });
    return res;
}

export async function createSquad(data) {
    const requiredKeys = ['name'];
    data = Object.fromEntries(Object.entries(data).filter(([_, v]) => v !== null));
    if (!requiredKeys.every((k) => k in data)) {
        throw new Error(`POI Create data requires these keys ${requiredKeys}`);
    }

    const ret = await apiCall(`/squad`, METHODS.post, { body: [data], throwOnError: true });
    return ret;
}

export async function updateSquad(id, data) {
    const requiredKeys = [];
    data = Object.fromEntries(Object.entries(data).filter(([_, v]) => v !== null));
    if (!requiredKeys.every((k) => k in data)) {
        throw new Error(`POI Create data requires these keys ${requiredKeys}`);
    }

    const ret = await apiCall(`/squad/${id}`, METHODS.post, { body: data, throwOnError: true });
    return ret;
}

export async function deleteSquad(id) {
    const userToken = getUserSessionData();
    const searchParams = {
        userId: userToken.id,
    };
    const encodedSearchParams = querystring.encode(searchParams);
    await apiCall(`/squad/${id}?${encodedSearchParams}`, METHODS.delete, { throwOnError: true });
}

export async function restoreSquad(id) {
    const userToken = getUserSessionData();
    const searchParams = {
        userId: userToken.id,
    };
    const encodedSearchParams = querystring.encode(searchParams);
    await apiCall(`/squad/${id}/restore?${encodedSearchParams}`, METHODS.post, { throwOnError: true });
}

export async function getSquadDevices(id) {
    const userToken = getUserSessionData();
    const searchParams = {
        userId: userToken.id,
    };
    const encodedSearchParams = querystring.encode(searchParams);
    const ret = await apiCall(`/squad/${id}/devices?${encodedSearchParams}`, METHODS.get, { throwOnError: true });
    return ret;
}
