import querystring from "querystring";

import { getUserSessionData } from "../util/user-session-util";
import { METHODS, CONTENT_TYPES, apiCall } from "../util/fetch-util";

export async function getPageOfPois({page=0, limit=25, includeMeta=false} = {}) {
    const userId = getUserSessionData().id;
    const encodedParams = querystring.encode({userId, page, limit});

    const pois = await apiCall(`/poi?${encodedParams}`, METHODS.get, {throwOnError: true});

    if (includeMeta) {
        return pois || {metadata: {total: 0, page: 0}, data: []};
    }
    return pois.data || [];
}

export async function getPoi(poiId) {
    const userId = getUserSessionData().id;
    const encodedParams = querystring.encode({userId});
    const poi = await apiCall(`/poi/${poiId}?${encodedParams}`, METHODS.get, {throwOnError: true});
    return poi;
}

export async function addPoiToEvent(eventId, poiId) {
    const ret = await apiCall(`/events/${eventId}/poi/${poiId}`, METHODS.post, {throwOnError: true});
    return ret;
}

export async function rmPoiFromEvent(eventId, poiId) {
    const ret = await apiCall(`/events/${eventId}/poi/${poiId}/remove`, METHODS.delete, {throwOnError: true});
    return ret;
}

export async function createPoi(poiData) {
    const requiredKeys = ['nativeDeviceId', 'poiType'];
    poiData = Object.fromEntries(Object.entries(poiData).filter(([_, v]) => v !== null));
    if (!requiredKeys.every((k) => k in poiData)) {
        throw new Error(`POI Create data requires these keys ${requiredKeys}`);
    }

    const ret = await apiCall(`/poi`, METHODS.post, { body: [poiData], throwOnError: true });

    return ret;
}

export async function updatePoi(poiId, poiData) {
    const requiredKeys = ['nativeDeviceId', 'poiType'];
    poiData = Object.fromEntries(Object.entries(poiData).filter(([_, v]) => v !== null));
    if (!requiredKeys.every((k) => k in poiData)) {
        throw new Error(`POI Create data requires these keys ${requiredKeys}`);
    }

    const ret = await apiCall(`/poi/${poiId}/update`, METHODS.post, { body: poiData, throwOnError: true });

    return ret;
}

export async function deletePoi(poiId) {
    return await apiCall(`/poi/${poiId}`, METHODS.delete, { throwOnError: true, accept: CONTENT_TYPES.plain });
}
