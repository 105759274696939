export const ENV = {
    hrapsApiUrl: window._env_.REACT_APP_HRAPS_API || "",
    hdapApiUrl: window._env_.REACT_APP_HDAP_API || "",
    webClientDebug: window._env_.WEBCLIENT_DEBUG || "",
    mapsEnable: window._env_.HRAPS_MAPS_ENABLE || "",
    mapsURL: window._env_.HRAPS_MAPS || "",
    mapsStyle: window._env_.HRAPS_MAPS_STYLE || "",
}

Object.freeze(ENV);
