import React from "react";

import QuickDashboard from "../misc/QuickDashboard";

import {
  faMobileAlt,
  faUser,
  faUsers,
  faCalendarAlt,
  faHandshake,
  faMapMarker,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";

import PathList from "../../util/path-list";

export default class RetrospectiveDashboard extends React.Component {
  static defaultProps = {
    links: [
      {
        title: "Download Commhub Logfiles",
        destination: PathList.logfiles,
        icon: faFolder,
      },
      {
        title: "View/Download Completed Events",
        destination: PathList.eventTable,
        icon: faCalendarAlt
      }
    ],
  };

  render() {
    return (
      <div>
        <div className="block" />
        <QuickDashboard title="Retrospective Datasets" links={this.props.links} />
      </div>
    );
  }
}
