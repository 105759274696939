import { METHODS, apiCall } from "../util/fetch-util";

import { getUser } from "./user-actions";

export async function getOrganizations() {
    // TODO: The list of organizations should be provided by the server via API call, not determined in the front end where the end user can manipulate their role manually
    const role = (await getUser())?.role;

    const adminRole = "ROLE_ADMIN";

    return apiCall(
        '/org',
        METHODS.get,
        { throwOnError: true }
    ).then((payload) => {
        return payload.data.filter((o) => {
            return role === adminRole || role === o.role;
        }).map((o) => {
            return o.name
        });
    });
}
