import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from "react-dnd-touch-backend";
import { isBrowser } from 'react-device-detect';

import ConfigurationHeader from "../ConfigurationHeader";
import PoiItem from "./PoiItem";
import EventItem from "./PoiEventItem";

import PathList from "../../../util/path-list";
import IconText from "../../misc/IconText";
import Button from "../../misc/Button";

import {
    getPageOfPois,
} from "../../../actions/poi-actions.js";
import {
    getPageOfEvents,
} from "../../../actions/event-actions.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleRight,
    faAngleLeft,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

const EVENT_PAGE_LIMITS = 5;
const POI_PAGE_LIMITS = 10;

/* ==================================================
 * Utility
 * ==================================================*/

/**
 * Calculates the total number of pages from the provided total number of items and the page size
 * limit.
 *
 * @param totalItems {Integer}
 * @param pageLimit {Integer}
 *
 * @returns {Integer} of the total number of pages.
 */
function calculatePageTotal(totalItems, pageLimit) {
    return (pageLimit > 0) ? Math.ceil((totalItems || 0) / pageLimit) : 0;
}

/**
 * Returns a level with page info and buttons to change the page.
 *
 * @param curPage {Integer}         Current page 0-indexed.
 * @param pageTotal {Integer}       Total number of pages avaliable.
 * @param pageSetter {Callable]     Setter function provided by `useState`.
 *
 * @returns DOM object that can be placed in the table footer.
 */
function createPageChangeFooter(curPage, pageTotal, pageSetter) {
    const page = curPage + 1;
    const total = (pageTotal === 0) ? 1 : pageTotal;
    const changePage = (dir) => {
        if ((dir === 'first') && (curPage > 0)) {
            pageSetter(0);
        }
        else if ((dir === 'last') && ((curPage + 1) < pageTotal)) {
            pageSetter(pageTotal - 1);
        }
        if ((dir === 'prev') && (curPage > 0)) {
            pageSetter(curPage - 1);
        }
        else if ((dir === 'next') && ((curPage + 1) < pageTotal)) {
            pageSetter(curPage + 1);
        }
    };
    return (
        <tr><th>
            <nav className="pagination">
                <div className="pagination-list">
                    <p className="mr-2">{`Page ${page} of ${total}`}</p>
                    <span className="pagination-link" onClick={() => changePage('first')}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft}/>
                    </span>
                    <span className="pagination-link" onClick={() => changePage('prev')}>
                        <FontAwesomeIcon icon={faAngleLeft}/>
                    </span>
                    <span className="pagination-link" onClick={() => changePage('next')}>
                        <FontAwesomeIcon icon={faAngleRight}/>
                    </span>
                    <span className="pagination-link" onClick={() => changePage('last')}>
                        <FontAwesomeIcon icon={faAngleDoubleRight}/>
                    </span>
                </div>
            </nav>
        </th></tr>
    )
}

/* ==================================================
 * POIs
 * ==================================================*/

/**
 * Component that manages a table/list of draggable POIs with data queried from the API on a
 * page-by-page basis.
 *
 * @property onChange {Callable}    Callback for whenever an action takes place, like a POI created
 *                                  or deleted.
 * @property limit {Integer}        Integer value specifying the limit of list items per page.
 */
function PoiList(props) {
    const {
        onChange = (() => {}),
        onPoiSelect = ((_id) => {}),
        selectedPoi = null,
        limit = POI_PAGE_LIMITS,
    } = props;

    const [trigger, setTrigger] = useState('');
    const [page, setPage] = useState(0);
    const [queryPage, setQueryPage] = useState(0);
    const [pageTotal, setPageTotal] = useState(1);
    const [pois, setPois] = useState([]);

    const setSelectedPoi = (id) => onPoiSelect(id);

    useEffect(() => {
        const searchParams = {
            page: queryPage,
            limit: limit,
            includeMeta: true,
        };
        getPageOfPois(searchParams).then((data) => {
            console.debug('Got poi data', data);
            const total = data.metadata.total || 0;
            setPois(data.data || [])
            setPage(queryPage);
            setPageTotal(calculatePageTotal(total, limit));
        }).catch((err) => {
            console.error('Failed to query POIs:', err);
        });
    }, [queryPage, trigger]);

    const listItemChanged = () => {
        onChange();
        setTrigger(Date.now());
    }

    const listItemClicked = (id) => {
        setSelectedPoi((id === selectedPoi) ? null : id);
        onPoiSelect((id === selectedPoi) ? null : id);
    };

    const poiHeader = (
        <tr><th>
            <div className={"columns mb-0"}>
                <div className={"column"}>
                    <span>Points of Interest</span>
                </div>
            </div>
        </th></tr>
    );
    const poiBody = pois.map((v, vi) => (
        <tr key={`poi-${vi}-${v.id}`}>
            <PoiItem
                idx={vi}
                poi={v}
                onChange={listItemChanged}
                onClicked={listItemClicked}
                selectedPoi={selectedPoi}
                onDragStarted={() => setSelectedPoi(null)}
            />
        </tr>
    ));
    const poiFooter = createPageChangeFooter(page, pageTotal, setQueryPage);

    return (
        <table className="table is-fullwidth is-striped is-hoverable" >
            <thead>{poiHeader}</thead>
            <tbody>{poiBody}</tbody>
            <tfoot>{poiFooter}</tfoot>
        </table>
    );
}

/* ==================================================
 * EVENTS
 * ==================================================*/

/**
 * Component that manages a table/list of droppable Events with data queried from the API on a
 * page-by-page basis.
 *
 * @property onChange {Callable}    Callback for whenever an action takes place, like a POI added or
 *                                  removed from the event.
 * @property limit {Integer}        Integer value specifying the limit of list items per page.
 * @property trigger {Any}          Trigger value to force the list to refresh (Date.now() works
 *                                  well here).
 */
function EventList(props) {
    const {
        onChange = (() => {}),
        limit = EVENT_PAGE_LIMITS,
        outsideTrigger = true,
        selectedPoi = null,
    } = props;

    const [trigger, setTrigger] = useState(true);
    const [page, setPage] = useState(0);
    const [queryPage, setQueryPage] = useState(0);
    const [pageTotal, setPageTotal] = useState(1);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const searchParams = {
            eventsPerPage: limit,
            includeMeta: true,
        };
        getPageOfEvents(queryPage, searchParams).then((data) => {
            console.debug('Got event data', data);
            const total = data.metadata.total || 0;
            setEvents(data.data || []);
            setPage(queryPage);
            setPageTotal(calculatePageTotal(total, limit));
        }).catch((err) => {
            console.error('Failed to query Events:', err);
        });
    }, [queryPage, trigger, outsideTrigger]);

    const listItemChanged = () => {
        onChange();
        setTrigger(Date.now());
    };

    const eventHeader = (
        <tr><th>
            <div className={"columns mb-0"}>
                <div className={"column"}>
                    <span>Events</span>
                </div>
            </div>
        </th></tr>
    );
    const eventBody = events.map((v, vi) => (
        <tr key={`event-${vi}-${v.id}`}>
            <EventItem idx={vi} event={v} onChange={listItemChanged} selectedPoi={selectedPoi}/>
        </tr>
    ));
    const eventFooter = createPageChangeFooter(page, pageTotal, setQueryPage);

    return (
        <table className="table is-fullwidth is-striped is-hoverable" >
            <thead>{eventHeader}</thead>
            <tbody>{eventBody}</tbody>
            <tfoot>{eventFooter}</tfoot>
        </table>
    );
}

/* ==================================================
 * Root Component
 * ==================================================*/

export default function PoiConfiguration(props) {
    const [eventUpdateTrigger, setEventUpdateTrigger] = useState(Date.now());
    const [selectedPoi, setSelectedPoi] = useState(null);

    return (
        <div>
            <ConfigurationHeader
                selectedButton={"POIs"}
                previousPath={PathList.events}
                nextPath={PathList.squads}
                currnetPath="POIs"
            />

            <div className="block"></div>

            <div className="container">
                <div className="level">
                    <div className="level-item level-right">
                        <div className="field is-grouped">
                            <div className="control">
                                <Button
                                    type='link'
                                    path={PathList.poiCreate}
                                    activeColor='is-success'
                                    icon={faPlusCircle}
                                >
                                    Add New POI
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="block"></div>

            <DndProvider backend={isBrowser ? HTML5Backend : TouchBackend}>
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <PoiList
                                onChange={() => setEventUpdateTrigger(Date.now())}
                                selectedPoi={selectedPoi}
                                onPoiSelect={setSelectedPoi}
                            />
                        </div>

                        <div className="column">
                            <EventList
                                trigger={eventUpdateTrigger}
                                selectedPoi={selectedPoi}
                                onChange={() => setSelectedPoi(null)}
                            />
                        </div>
                    </div>
                </div>
            </DndProvider>
        </div>
    );
}
