import React from "react";
import { Link } from "react-router-dom";

import SortableTable from "../misc/SortableTable";
import QuickDashboard from "../misc/QuickDashboard";

import { ASCENDING } from "../misc/SortableTable";

import { getAllEvents } from "../../actions/event-actions";

import PathList from "../../util/path-list";

export default class Retrospective extends React.Component {
    static defaultProps = {
        title: "Manage Events",
        headers: [
            { name: "Status", title: "Other Status" },
            { name: "Name" },
            { name: "# Recruits" },
            { name: "Estimated Start Time" },
            { name: "Estimated End Time" },
            { name: "Actions" },
        ],
    }

    constructor(props) {
        super(props);

        this.state = {
            events: [],
        };

        this.updateEventList = this.updateEventList.bind(this);
        this.transformEventListForTable = this.transformEventListForTable.bind(this);
    }

    componentDidMount() {
        this.updateEventList();
    }

    updateEventList() {
        getAllEvents({throwOnError: true}).then(events => {
            console.log("Got Event Data!! ", events);
            this.setState({ events });
        }).catch(err => {
            console.error("Failed to query events: ", err);
        });
    }

    transformEventListForTable(eventList) {
        return eventList.map(item => {

            function to_sentence_case(ccase) {
                var r = ccase.replace(/([A-Z])/g, " $1");
                return r.charAt(0).toUpperCase() + r.slice(1);
            }

            function toDateFormat(iso) {
                var d = new Date(iso);
                return d.toUTCString();
            }

            var tagClass = "is-danger";
            switch (item.status) {
                case "complete":
                    tagClass = "is-success";
                    break;
                case 'upcoming':
                    tagClass = "is-info";
                    break;
                case 'inProgress':
                    tagClass = "is-warning";
                    break;
                case 'cancelled':
                    tagClass = "is-light";
                    break;
                case 'overdue':
                case 'unknown':
                default:
                    tagClass = "is-danger";
                    break;
            }

            const statusTag = <span
                        className={"tag " + tagClass}
                        >{to_sentence_case(item.status)}</span>;

            const viewButton = <Link
                        className="button is-small action-item"
                        to={PathList.eventView(item.id)}
                        >View</Link>;
            const downloadButton = <Link
                        className="button is-small action-item"
                        to={PathList.eventDownload(item.id)}
                        disabled={item.status !== "complete"}
                        >Download</Link>;

            return [
                statusTag,
                item.name,
                item.recruits.length,
                <span className="table-datetime">{toDateFormat(item.estimatedStartTime)}</span>,
                <span className="table-datetime">{toDateFormat(item.endTime)}</span>,
                <div>{viewButton}{downloadButton}</div>,
            ];
        });
    }

    render() {
        const events = this.transformEventListForTable(this.state.events);

        return (
            <div>
                <div className="block"></div>
                <QuickDashboard
                    title={this.props.title}
                    links={[]}
                    />
                <SortableTable
                    headers={this.props.headers}
                    data={events}
                    defaultSort={[1, ASCENDING]}
                    sortable={true}
                    />
            </div>
        );
    }
}
