import socketIOClient from "socket.io-client";
import sailsIOClient from "sails.io.js";

import { ENV } from "./env-util";

const envHost = `${window.location.protocol}//${ENV.hrapsApiUrl}`; // This assumes that the ENV provided url does not start with http or https
console.log("ENVHOST:", envHost, window.location.protocol);

let io = sailsIOClient(socketIOClient);
io.sails.autoConnect = false;

export async function connectIOSocket({ url=envHost, timeout } = {}) {
    console.log("Attempting socket connection");

    return new Promise((resolve, reject) => {
        const socket = io.sails.connect((new URL(url)).href);

        socket.on("connect", () => {
            console.log("Socket connected.")
            resolve(socket, io);
        });

        socket.on("error", err => {
            console.error("Socket connection error.");
            reject(err);
        });

        console.info('connectIOSocket timeout', timeout)
        if (timeout) {
            setTimeout(() => {
                if (!socket.isConnected()) {
                    reject('Sails io.socket connection timed out');
                }
            }, [timeout]);
        }
    });

}
