import querystring from "querystring";

import { METHODS, apiCall } from "../util/fetch-util";

export async function getEventData(eventId,
                                   recruit,
                                   {
                                       metric='hr',
                                       numSamples=10000,
                                       startTime=null,
                                       endTime=null,
                                       ingestType=null,
                                   } = {}) {
    const params = {
        recruit,
        metric,
        numSamples,
        ...(startTime === null ? {} : {startTime}),
        ...(endTime === null ? {} : {endTime}),
        ...(ingestType === null ? {} : {ingestType}),
    };
    const encodedParams = querystring.encode(params);

    console.debug(`/events/${eventId}/data?${encodedParams}`)
    const data = await apiCall(`/events/${eventId}/data?${encodedParams}`, METHODS.get, { throwOnError: false });

    return data;
}

export async function getEventDataAllRecruits(eventId,
                                   {
                                       metric='hr',
                                       numSamples=10000,
                                       startTime=null,
                                       endTime=null,
                                       ingestType=null,
                                   } = {}) {
    const params = {
        metric,
        numSamples,
        startTime,
        endTime,
        ingestType,
    };
    const encodedParams = (new URLSearchParams(Object.fromEntries(
        Object.entries(params).filter(([_, v]) => v != null)
    ))).toString();

    console.debug(`/events/${eventId}/data/all?${encodedParams}`)
    const data = await apiCall(`/events/${eventId}/data/all?${encodedParams}`, METHODS.get, { throwOnError: true });

    return data;
}
