import { apiCall, METHODS } from "../util/fetch-util";

export async function getLiveCache(limit = -1, { throwOnError = true } = {}) {
  const data = await apiCall(`/live/cache?limit=${limit}`, METHODS.get, {
    throwOnError,
  });
  console.log("Got live cache:", data);

  return data;
}

export function joinLiveCache(socket, { eventId = null } = {}) {
  console.log("joining live cache");
  return new Promise((resolve, reject) => {
    console.log("getting live cache");
    const queryStr = eventId != null ? `?eventId=${eventId}` : "";
    socket.get(`/live/join${queryStr}`, null, (message, jwRes) => {
      const statusBad = !(200 <= jwRes.statusCode || jwRes.statusCode < 300);
      if (statusBad || message.error) {
        console.error("Error joining live cache");
        reject(message.error ?? `Failed to connnect (${jwRes.statusCode})`);
        return;
      }
      console.log("getting got...attempting to resolve", jwRes);
      resolve(message);
    });
  });
}

export function registerNewDataListener(socket, callback) {
  socket.on("newData", (message) => callback(message));
}

export async function getActiveDeviceIds(userId) {
  return await apiCall(`/sapper/activeDevices?userId=${userId}`, METHODS.get, {
    throwOnError: true,
  });
}

export async function getTrackedDevices(userId, { outputVersion = "1" } = {}) {
  const query = {
    userId,
    outputVersion,
  };
  const queryStr = new URLSearchParams(query).toString();
  const data = await apiCall(
    `/sapper/trackedDevices?${queryStr}`,
    METHODS.get,
    { throwOnError: true }
  );
  return data.devices;
}

export async function getActiveDeviceLocations(userId, devices) {
  const body = { devices, userId };
  return await apiCall(`/sapper/activeDeviceLocations`, METHODS.post, {
    body,
    throwOnError: true,
  });
}

export async function joinLiveTracking(socket, deviceId) {
  return new Promise((resolve, reject) => {
    socket.get(`/live/joinLiveTracking/${deviceId}`, null, (message, jwRes) => {
      if (jwRes.error) {
        reject(jwRes.body);
        return;
      }

      resolve(message);
    });
  });
}

export function registerLiveTrackingDataListener(socket, callback) {
  socket.on("liveTracking:newData", (message) => callback(message));
}

export async function leaveLiveTracking(socket, deviceId) {
  return new Promise((resolve, reject) => {
    socket.get(
      `/live/leaveLiveTracking/${deviceId}`,
      null,
      (message, jwRes) => {
        const statusBad = !(200 <= jwRes.statusCode || jwRes.statusCode < 300);
        if (statusBad || message.error) {
          reject(message.error ?? `Failed to connnect (${jwRes.statusCode})`);
          return;
        }

        resolve(message);
      }
    );
  });
}
