import NotificationModal from './NotificationModal';

export default function SimpleErrorModal(props = {}) {
    const {
        msg = 'Test error text',
        onClose = () => {},
    } = props;

    return (
        <NotificationModal
            active={true}
            confirmationText={(
                <div>
                    <div className="title">
                        Error
                    </div>

                    <div className="block"></div>

                    <div className="notification has-text-weight-bold has-text-danger">
                        {msg}
                    </div>
                </div>
            )}
            confirmButtonAction={onClose}
            cancelButtonAction={onClose}
            closeButton={false}
        />
    );
}
