import React from "react";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import lodash from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMinus,
    faPlus,
    faRetweet,
    faEye,
    faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import {
    COMMON_GRAPH_CONFIG,
    GRAPH_COLORS,
    METRIC_TYPES,
} from './constants';

import DataChart from "../misc/DataChart";
import LoadingSpinner from "../misc/LoadingSpinner";

import {
    getOneEvent,
} from "../../actions/event-actions";
import {
    getEventDataAllRecruits,
} from "../../actions/data-actions";
import {
    getAllDevices,
} from "../../actions/device-actions";

/*------------------------------------------------------------------------------------------------*/

/**
 * Helper function to sort data array where each element is an object with an 'x' value.
 * @param {Any} a   Item one.
 * @param {Any} b   Item two.
 * @returns {Number}    Like C's strcmp.
 */
function dataSorter(a, b) {
    return (a.x < b.x) ? -1 : (a.x === b.x) ? 0 : 1;
}

/**
 * Automatically rounds to the most appropriate value. This basically just does the round based on
 * how many digits there are in base10.
 */
function autoRoundUp(value) {
    const digits = Math.log(value) * Math.LOG10E + 1 | 0;
    const roundDigits = Math.pow(10, digits - 2);
    const ret = (Math.ceil(value / roundDigits) * roundDigits) + roundDigits;
    return ret;
}

/**
 * Inserts null valued data samples when the time difference between samples is greater than
 * timeToSep.
 * This assumes that datasets sample x values are integers that represent ms timestamps.
 *
 * @param {Array} datasets
 * @param {Number} timeToSep
 */
function segmentData(datasets, timeToSep) {
    timeToSep = (timeToSep === undefined) ? 180e3 : timeToSep;
    function zip() {
        var args = [].slice.call(arguments);
        var shortest = (args.length === 0) ? [] : args.reduce(function(a, b){
            return (a.length < b.length) ? a : b;
        });

        return shortest.map(function(_, i){
            return args.map(function(array){return array[i]})
        });
    }
    const ret = datasets.map((ds) => {
        const tsArray = ds.data.map((s) => s.x);
        const timeDiffs = zip(
            tsArray,
            tsArray.slice(1)
        ).map(([x, y], i) => {
            return {index: i, value: y - x, x: x, y: y};
        }).filter(
            (s) => s.value > timeToSep
        ).reverse();

        timeDiffs.forEach((v) => {
            ds.data.splice(v.index + 1, 0, [{x: v.x, y: null}]);
        });

        return ds;
    });
    return ret;
}

/**
 *
 * @param {Array} datasets      The incoming datasets data for a particular metric. This data should
 *                              be in the format with x and y values and with the x value being a
 *                              `Date` object.
 * @param {Number} timeWindow   (Optional) The amount of time (milliseconds) to keep of the data.
 *                              Mainly used for realtime data.
 *
 * @returns                     Array of return data. Return Data:
 *                              - New datasets array
 *                              - Labels array.
 *                              - suggestedMax value.
 */
function processData(datasets, {timeWindow=null, converter=null} = {}) {
    /*--- Data Touchup ---*/
    const doTimeWindow = (timeWindow !== null);
    datasets = datasets.map((ds) => {
        const timePoints = ds.data.map((s) => s.x);
        ds.data = ds.data.filter((s, i) => timePoints.indexOf(s.x) === i);
        ds.data = ds.data.sort(dataSorter);
        return ds;
    });
    let maxTime = -1;
    if (doTimeWindow) {
        datasets.forEach((ds) => {
            const max = ds.data[ds.data.length - 1].x;
            if (maxTime < max) {
                maxTime = max;
            }
        });
    }
    const minTime = (doTimeWindow) ? maxTime - timeWindow : -1;
    if (doTimeWindow) {
        datasets = datasets.map((ds) => {
            ds.data = ds.data.filter((s) => s.x > minTime);
            ds.data.unshift({x: minTime, y: null});
            return ds;
        });
    }
    if (converter !== null) {
        datasets = datasets.map((ds) => {
            ds.data = ds.data.map((s) => { return {...s, y: converter(s.y)}; });
            return ds;
        });
    }
    datasets = segmentData(datasets);
    /*--- Extract Labels ---*/
    const tickValuesArr = datasets
        .map((ds) => {return ds.data.map((s) => s.x)})
        .flat()
        .sort();
    /*--- Value for suggestedMax ---*/
    const maxVal = autoRoundUp(
        Math.max.apply(null, datasets.map((ds) => ds.data.map((s) => s.y)).flat())
    );
    return [datasets, tickValuesArr, maxVal];
}

/**
 * Chart.js options function for evaluating what data point has low confidence, then displays it if so.
 * The returned value will be the radius or size of the data point with low confidence.
 * https://www.chartjs.org/docs/master/general/options.html#scriptable-options
 * @param {Object} context The context object for each data point
 * @returns {number} A value if the data point has a low confidence value, which should be shown.
 */
function getCustomRadius(context) {
    const index = context.dataIndex;
    const value = context.dataset.data[index]
    if (value?.lowConfidence) {
        return 5
    }
    return 0
}

/*------------------------------------------------------------------------------------------------*/

class EventPlot extends React.Component {
    constructor(props) {
        super(props);

        // Use the above template to generate chart configs
        const dcDict = Object.fromEntries(
            Object.entries(METRIC_TYPES).map(([k, m]) =>
                [
                    k,
                    (() => {
                        let n = lodash.cloneDeep(COMMON_GRAPH_CONFIG)
                        n.options.plugins.htmlLegend.containerID = `chart-${k}-legend`;
                        n.options.scales['y'].title = {
                            display: ('unit' in m),
                            ...(('unit' in m) ? {text: m.unit} : {}),
                        };
                        return n;
                    })()
                ]
            )
        );

        this.state = {
            eventName: "...",

            currentMetric: 'hr',
            dataCache: dcDict,
            visibleRecruits: [],
            recruitInfo: {},
            numSamples: 512,

            showDataPoints: false,
            showConfidence: false,
            scrollToZoom: true,
            realtimeMode: true,

            doConversion: Object.fromEntries(Object.keys(METRIC_TYPES).map(k =>
                [k, true]
            )),

            chartRefs: Object.fromEntries(Object.keys(METRIC_TYPES).map(k =>
                [k, React.createRef()]
            )),

            errorMsg: "",
            errorMsgModalOpen: false,

            loading: false,
        };
    }

    /**
     * Triggers upon component load.
     */
    componentDidMount() {
        // Trigger the querying of data for the first metric tab
        this.changeToDataset(Object.keys(METRIC_TYPES)[0])
            .then(() => this.changeToDataset(Object.keys(METRIC_TYPES)[6]))
            .then(() => this.evaluateConfidence());
    }

    /**
     * Request metric data for all recruits in the event. If there is a failure to retrieve data for
     * a recruit, this will return an empty data list for that recruit.
     * @param {String} metric   Key value used in the metricTypes member.
     * @returns {Array}         Series of datasets (samples), one for each recruit.
     */
    async requestData(metric, {rtMode=false} = {}) {
        // Request Event Info (used for recruits + time-frame)
        let startTimeUnix;
        let endTimeUnix;
        let startTime;
        let endTime;
        let prom = Promise.all([
            getOneEvent(this.props.match.params.eventId),
            getAllDevices(),
        ]).then(([eventData, deviceData]) => {
            // Check for error then set the title

            if ('error' in eventData) {
                // Adding this in here until we have other code merged in
                throw eventData.error;
            }
            if ('error' in deviceData) {
                // Adding this in here until we have other code merged in
                throw deviceData.error;
            }

            const eventRecruits = Object.fromEntries(eventData.recruits.map((r) => [
                r.id,
                {
                    displayName: r.displayName,
                    coreUserId: r.coreUserId
                }
            ]));

            // The device id that correlates to a recruit, this is a MAC-like address but we need it
            // without the colon.
            const devInfo = Object.fromEntries(deviceData.filter((device) => {
                return Object.keys(eventRecruits).includes(device.currentRecruit);
            }).map((device) => {
                const key = device.nativeDeviceId.replace(/:/g, "");
                return [
                    key,
                    {
                        id: device.id,
                        coreDeviceId: device.coreDeviceId,
                        nativeDeviceId: device.nativeDeviceId,
                        currentRecruit: device.currentRecruit,
                        ...eventRecruits[device.currentRecruit],
                    }
                ];
            }));

            this.setState({
                eventName: eventData.name,
                recruitInfo: devInfo,
            });
            return eventData;
        }).then(eventData => {
            // Request sample data for each recruit in the event.
            // returns list of datasets, one for each recruit.
            const numSamples = this.state.numSamples;
            startTime = new Date(eventData.estimatedStartTime);
            endTime = new Date(eventData.endTime);
            startTimeUnix = startTime.getTime() / 1000;
            endTimeUnix = endTime.getTime() / 1000;

            return getEventDataAllRecruits(
                eventData.id,
                {
                    metric,
                    numSamples: numSamples,
                    startTime: startTimeUnix,
                    endTime: endTimeUnix,
                    ingestType: (rtMode ? ['live'] : ['batch']),
                }
            );
        }).then((allData) => {
            return Object.values(allData.data).map((rdata) => {
                if (rdata.data.length === 0) {
                    return {
                        data: [],
                        metricSlug: metric,
                        recruitId: rdata.recruitId,
                        recruitName: `${rdata.recruitName} (no data)`,
                    };
                }
                rdata.data = [
                    {x: startTime.toISOString(), y: null},
                    ...rdata.data,
                    {x: endTime.toISOString(), y: null},
                ].concat();

                return rdata;
            })
        }).then(data => {
            // Extract specific data needed for the chart
            // the data timestamps are strings, we'll convert to JS timestamps (ms)

            const datasets = data.map((recruitData, i) => {
                const dataset = {
                    label: recruitData.recruitName,
                    data: recruitData.data
                        .map((v) => {return {x: new Date(v.x).getTime(), y: v.y}})
                        .sort(dataSorter),
                    hidden: recruitData.data.length === 0,
                    order: i,
                };
                return dataset;
            });
            return datasets;
        }).catch(err => {
            // TODO CW: set a state value to show error on the page in case event query fails?
            console.error(err);
        });
        return prom;
    }

    /**
     * Routine to switch tabs and query data if required.
     * @param {String} metric   Key value used in the metricTypes member.
     */
    async changeToDataset(metric, force) {
        const doQuery = () => {
            if (force) {
                return true;
            }
            return !this.state.dataCache[metric]
                .data.datasets.some((ds) => ds.data.length !== 0);
        }

        const getAndProcessData = async (rtMode) => {
            const mt = METRIC_TYPES[metric];
            const retData = await this.requestData(metric, {rtMode});
            console.debug((rtMode) ? 'Realtime' : 'Batch', 'data', retData);
            const pdOpts = {
                ...((this.state.doConversion[metric]) ? {converter: mt.converter} : {}),
            };
            const [datasets, labels, maxVal] = processData(retData, pdOpts);
            const retDs = (!rtMode) ? datasets : datasets.map((ds, i) => {
                ds.label = ds.label + ' [rt]';
                ds.elements = {
                    line: {
                        borderDash: [5, 5],
                        borderColor: GRAPH_COLORS[i % GRAPH_COLORS.length],
                        backgroundColor: GRAPH_COLORS[i % GRAPH_COLORS.length],
                    },
                };
                return ds;
            });
            return [retDs, labels, maxVal];
        };

        let toSet = {currentMetric: metric};

        if (doQuery()) {
            this.setState({ loading: true });
            // Query data then switch
            try {
                console.debug(`No data for ${metric}, querying`)
                const dataTypes = [
                    false,
                    ...((this.state.realtimeMode) ? [true] : [])
                ];
                const v = await Promise.all(dataTypes.map((v) =>
                    getAndProcessData(v)
                ));
                const [batchData, rtData] = v;
                const [datasets, labels, maxVal] = batchData;
                const [rtDatasets, rtLabels, rtMaxVal] = (rtData !== undefined)
                    ? rtData
                    : [[], [], undefined];

                const newLabels = lodash.uniq(labels.concat(rtLabels));
                const newMaxVal = Math.max(maxVal, rtMaxVal);

                let newDc = this.state.dataCache;
                newDc[metric].data = {
                    datasets: [].concat(datasets, rtDatasets),
                    labels: newLabels,
                };
                newDc[metric].options.scales.y.suggestedMax = newMaxVal;
                const mt = METRIC_TYPES[metric];
                const axisTitle = (this.state.doConversion[metric]) ? mt.alt_unit : mt.unit;
                if (axisTitle !== undefined) {
                    newDc[metric].options.scales.y.title.text = axisTitle;
                }
                toSet = {...toSet, dataCache: newDc};
            } catch (err) {
                console.error('Failed to get sample data:', err);
            }
            finally {
                this.setState({ loading: false });
            }
        }
        if (metric !== Object.keys(METRIC_TYPES)[6]) {
            this.setState(toSet);
        }
    }

    /**
     * Callback for the clicking on the metric tabs.
     * @param {Object} e    Event object.
     * @param {String} k    Metric key used to trigger the dataset query.
     */
    handleTabChange(_e, k) {
        if (!this.state.loading) {
            this.changeToDataset(k);
        }
    }

    /**
     * Set the state of a checkbox by name.
     * @param {String} name
     * @param {Boolean} state
     */
    setCheckbox(name, state) {
        console.debug('Setting checkbox', name, 'with state', state);
        let e = document.getElementById(name);
        e.checked = state;
    }

    /**
     * Callback for the checkboxes in this component.
     * @param {Object} e    Event object.
     */
    onCbStateChange(e) {
        if (e.target.id === 'cb-show-data-points') {
            if(this.state.currentMetric === Object.keys(METRIC_TYPES)[0]) {
                document.getElementById('cb-show-low-confidence').checked = false
            }
            let dataCache = {
                ...this.state.dataCache
            };
            for(let key in dataCache) {
                dataCache[key].options.elements.point.radius = (e.target.checked) ? 3 : 0;
            }
            this.setState({
                showDataPoints: e.target.checked,
                showConfidence: false,
                dataCache,
            });
        }
        else if (e.target.id === 'cb-scroll-to-zoom') {
            let dc = Object.fromEntries(
                Object.entries(this.state.dataCache).map(([k, v]) => {
                    v.options.plugins.zoom.zoom.wheel.enabled =
                        !v.options.plugins.zoom.zoom.wheel.enabled;
                    return [k, v];
                })
            );

            this.setState({
                dataCache: dc,
                scrollToZoom: !this.state.scrollToZoom,
            });
        }
        else if (e.target.id === 'cb-show-low-confidence') {
            this.changeToDataset(Object.keys(METRIC_TYPES)[6]).then(() => this.evaluateConfidence()).then(() => {
                document.getElementById('cb-show-data-points').checked = false;
                let dataCache = {
                    ...this.state.dataCache
                };
                for (let key in dataCache) {
                    dataCache[key].options.elements.point.radius = e.target.checked ? getCustomRadius : 0;
                }
                this.setState({
                    showConfidence: e.target.checked,
                    showDataPoints: false,
                    dataCache,
                });
            });
        }
        else if (e.target.id === 'cb-temp-to-f') {
            const tempMetrics = ['ect', 'temp'];
            let dataCache = this.state.dataCache;
            let doConversion = this.state.doConversion;

            tempMetrics.forEach((m) => {
                dataCache[m].data.datasets = [];
                doConversion[m] = e.target.checked;
            });

            const cb = () => {
                this.changeToDataset(this.state.currentMetric);
            };

            this.setState({ dataCache, doConversion }, cb);
        }
        else if (e.target.id === 'cb-realtime') {
            if(this.state.currentMetric === Object.keys(METRIC_TYPES)[0]) {
                document.getElementById('cb-show-low-confidence').checked = false;
                this.setState({showConfidence: false});
            }
            const realtimeMode = e.target.checked;
            console.debug('Is realtimeMode', realtimeMode);

            // Clear the datasets, so the realtime or stored data can work on a clean slate.
            let dataCache = this.state.dataCache;

            Object.keys(dataCache).forEach((k) => {
                dataCache[k].data.datasets = [];
            });

            const callback = () => {
                this.changeToDataset(this.state.currentMetric);
            };

            this.setState({
                dataCache,
                realtimeMode,
            }, callback);
        }
    }

    /**
     * Callback sent back from the DataChart component for when the chart has been updated. Using
     * this here so we can call update() without the 'none' that prevents the data points from being
     * toggled.
     *
     * @param {Object} chart    The chart object to be managed.
     * @param {String} key      Metric key used to trigger the dataset query.
     */
    onChartUpdate(chart, key) {
        function updateChartData(conf) {
            chart.data = conf.data;
            chart.options = conf.options;
        }
        updateChartData(this.state.dataCache[key]);
        chart.update();
    }

    /**
     * Callback for to hide all recruits.
     */
    hideOrShowAll(key, show) {
        const chart = this.state.chartRefs[key].current;
        if (show) {
            chart?.showAll();
        }
        else {
            chart?.hideAll();
        }
    }

    doZoom(key, method) {
        if (method === 'out') {
            this.state.chartRefs[key].current?.chart?.zoom(0.9);
        }
        else if (method === 'in') {
            this.state.chartRefs[key].current?.chart?.zoom(1.1);
        }
        else if (method === 'reset') {
            this.state.chartRefs[key].current?.chart?.resetZoom();
        }
    }

    /**
     * Iterates each recruits heart rate value and evaluates if each value has low confidence. Amends each heart
     * rate data point with low confidence with a lowConfidence property.
     */
    async evaluateConfidence() {
        console.log("evaluating confidence")
        const CONFIDENCE_DATA_SETS = this.state.dataCache?.confidence?.data?.datasets
        const HEART_RATE_DATA_SETS = this.state.dataCache?.hr?.data?.datasets

        for (let i = 0; i < CONFIDENCE_DATA_SETS?.length; i++) {
            for (let j = 0; j < (CONFIDENCE_DATA_SETS[i]?.data?.length >= HEART_RATE_DATA_SETS[i]?.data?.length
                ? HEART_RATE_DATA_SETS[i].data.length
                : CONFIDENCE_DATA_SETS[i].data.length); j++) {
                if ((CONFIDENCE_DATA_SETS[i]?.data[j]?.y < 70) && (HEART_RATE_DATA_SETS[i]?.data[j])) {
                    HEART_RATE_DATA_SETS[i].data[j].lowConfidence = true
                }
            }
        }
    }

    render() {
        const metricTypeCopy = Object.assign({}, METRIC_TYPES);
        delete metricTypeCopy.confidence

        const tabs = Object.entries(metricTypeCopy).map(([k, m]) => {
            return (
                <li className={(k === this.state.currentMetric) ? 'is-active' : ''} key={`tab-${k}`}>
                    <a onClick={e => this.handleTabChange(e, k)}>
                        { m.text }
                    </a>
                </li>
            );
        });

        const zoomActions = [
            {
                name: 'zoom-out',
                tooltip: 'Zoom Out',
                icon: faMinus,
                callback: (k) => {this.doZoom(k, 'out')},
            },
            {
                name: 'zoom-in',
                tooltip: 'Zoom In',
                icon: faPlus,
                callback: (k) => {this.doZoom(k, 'in')},
            },
            {
                name: 'reset',
                tooltip: 'Reset',
                icon: faRetweet,
                callback: (k) => {this.doZoom(k, 'reset')},
            },
        ];

        const genZoomButtons =  (k) => { return (
            <div> <div className="tile is-parent">
            {
                zoomActions.map(action => {
                    return <div key={`zoom-buttons-${k}-${action.name}`} className="tile is-child">
                        <div className="container has-text-centered">
                            <button data-tip data-for={`zoom-${action.name}-tip`} className="button is-small"
                                    onClick={() => action.callback(k)}>
                                <FontAwesomeIcon icon={action.icon} />
                            </button>
                            <ReactTooltip id={`zoom-${action.name}-tip`}>
                                {action.tooltip}
                            </ReactTooltip>
                        </div>
                    </div>
                })
            }
            </div> </div>
        );};

        const buttonActions = [
            {
                name: 'hide',
                tooltip: 'Hide all recruits',
                icon: faEyeSlash,
                callback: (k, s=false) => this.hideOrShowAll(k, s),
                colorClass: 'is-link',
            },
            {
                name: 'show',
                tooltip: 'Show all recruits',
                icon: faEye,
                callback: (k, s=true) => this.hideOrShowAll(k, s),
                colorClass: 'is-info',
            },
        ];

        const genButtons = (k) => {return (
            <div> <div className="tile is-parent">
            {
                buttonActions.map(action => {
                    return <div key={`zoom-buttons-${k}-${action.name}`} className="tile is-child">
                        <div className="container has-text-centered">
                            <button data-tip data-for={`zoom-${action.name}-tip`}
                                className={ `button is-small ${action.colorClass}` }
                                onClick={() => action.callback(k)}>
                                <FontAwesomeIcon icon={action.icon} />
                            </button>
                            <ReactTooltip id={`zoom-${action.name}-tip`}>
                                {action.tooltip}
                            </ReactTooltip>
                        </div>
                    </div>
                })
            }
            </div> </div>
        );};

        const legendStyle = {
            overflowY: 'auto',
            maxHeight: '20em',
        };

        const genLegend = (k) => {
            return (
                <div className="tile is-parent is-vertical">
                    {genZoomButtons(k)}
                    {genButtons(k)}
                    <div className="tile is-child" >
                        <div id={`chart-${k}-legend`} className="content is-small"
    style={legendStyle}/>
                    </div>
                </div>
            );
        };

        const genChart = (k) => {
            return (
                <div id={`chart-${k}-div`} className="tile is-child is-10" style={{padding:"20px"}}>
                    <DataChart
                        handleChartUpdate={(c) => this.onChartUpdate(c, k)}
                        ref={this.state.chartRefs[k]}
                        config={this.state.dataCache[k]} />
                </div>
            );
        };

        const charts = Object.keys(metricTypeCopy).map((k) => {
            return (
                <div id={`chart-${k}`} key={`chart-${k}`} hidden={ k !== this.state.currentMetric } >
                    <div className="tile is-ancestor">
                        <div className="tile is-parent">
                            {genLegend(k)}
                            {genChart(k)}
                        </div>
                    </div>
                </div>
            );
        });

        const checkboxData = [
            {
                id: "cb-show-data-points",
                text: "Show Data Points",
                default: this.state.showDataPoints,
                onChange: (e) => this.onCbStateChange(e),
                condition: () => true,
            },
            {
                id: "cb-scroll-to-zoom",
                text: "Scroll to zoom",
                default: this.state.scrollToZoom,
                onChange: (e) => this.onCbStateChange(e),
                condition: () => true,
            },
            {
                id: "cb-temp-to-f",
                text: "Temperature in F",
                default: true,
                onChange: (e) => this.onCbStateChange(e),
                condition: () => (['ect', 'temp'].includes(this.state.currentMetric)),
            },
            {
                id: "cb-realtime",
                text: "Include Live Data",
                default: this.state.realtimeMode,
                onChange: (e) => this.onCbStateChange(e),
                condition: () => true,
            },
            {
                id: "cb-show-low-confidence",
                text: "Show Low Confidence",
                default: this.state.showConfidence,
                onChange: (e) => this.onCbStateChange(e),
                condition: () => (['hr'].includes(this.state.currentMetric)),
            },
        ];

        const checkboxes = checkboxData.filter(
            (v) => v.condition()
        ).map((v) => {
            return (
                <div key={v.id} className="tile is-child">
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            id={ v.id }
                            defaultChecked={ v.default }
                            onChange={ v.onChange } />
                        { v.text }
                    </label>
                </div>
            );
        });

        // TODO add an "In Progress" dialog since requests can take some time.

        return (
            <div>
                <div className="block"/>

                <div className="container">
                    <h1 className="level-item title">{this.state.eventName}</h1>
                </div>

                <div className="tabs is-centered is-toggle is-small">
                    <ul> {tabs} </ul>
                </div>

                <div className="tile is-ancestor">
                    <div className="tile is-parent has-text-centered">
                        {checkboxes}
                    </div>
                </div>

                <div id='chart-div'>
                    <LoadingSpinner inProgress={this.state.loading} >
                        {charts}
                    </LoadingSpinner>
                </div>
            </div>
        );
    }
}

export default withRouter(EventPlot);
