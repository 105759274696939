import _ from 'lodash';

/**
 * Query all the events by utilizing the paged api sorting by time since that'll likely be the most
 * important metric for event order.
 */
export async function getAllPages(
    getFunction,
    {
        // limit=50,
        limit=5,
        limitField=null,
        sortKey=null,
        sortValue=null,
        otherOpts={},
    } = {},
) {
    const opts = Object.fromEntries([
        [limitField ? limitField : 'limit', limit],
        ['includeMeta', true],
        ['throwOnError', true],
        ['sortKey', sortKey],
        ['sortValue', sortValue],
        ...Object.entries(otherOpts),
    ].filter(([_, v]) => v != null));

    let ret = await getFunction(0, opts);
    let data = ret.data;
    if (ret.metadata.total > ret.data.length) {
        const pages = Math.ceil(ret.metadata.total / limit);
        const results = await Promise.all(_.range(1, pages + 1).map(async (page) => {
            return getFunction(page, opts);
        })).then((x) => x.map((y) => y.data));
        data = [data, ...results].flat(1);
    }
    console.debug(`getAllPages() got ${data.length} entries`, data)
    return data;
}
