import React from "react";

import GtriLogo from "./GtriLogo";
import HrapsLogo from "./HrapsLogo";

export default class GtriHrapsLogo extends React.PureComponent {
    static defaultProps = {
        gtriLogoWidth: 200,
        separatorHeight: 50
    }

    render() {
        const separatorStyle = { height: this.props.separatorHeight };

        return (
            <div className="gtri-hraps-logo has-text-centered">
                <div className="logo-component">
                    <GtriLogo logoWidth={this.gtriLogoWidth} />
                </div>
                <div className="gtri-hraps-logo-separator" style={separatorStyle}></div>
                <div className="logo-component">
                    <HrapsLogo />
                </div>
            </div>
        );
    }
}