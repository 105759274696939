import { METHODS, apiCall, CONTENT_TYPES } from "../util/fetch-util";
import { createUserSessionData, deleteUserSessionData, getUserSessionData } from "../util/user-session-util";
import PathList from "../util/path-list";

export async function login(username, password) {
    const userData = await apiCall("/login", METHODS.post, { body: { username, password }, throwOnError: true });

    delete(userData?.password); // It's a terrible idea to store passwords anywhere on the frontend, hashed or not

    createUserSessionData(userData);

    if (userData?.error) {
        deleteUserSessionData();
    }

    return userData;
}

export async function logout(redirectTo = PathList.login) {
    await apiCall("/logout", METHODS.get, { throwOnError: true, accept: CONTENT_TYPES.plain });

    deleteUserSessionData();

    if (redirectTo) {
        // If you want to log off without getting redirected, just set redirectTo = false
        window.location.assign(redirectTo);
    }
}

export function getUser() {
    // TODO: Getting user data should be an API call
    return getUserSessionData();
}

export function isLoggedIn() {
    // TODO: This should be an API call
    return Boolean((getUser())?.id);
}
