import React from "react";

import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";

import zoomPlugin from "chartjs-plugin-zoom";

Chart.register(zoomPlugin);

export default class DataChart extends React.Component {
    static defaultProps = {
        config: null,
        handleChartUpdate: null // EX: chart => {}
    }

    constructor(props) {
        super(props);

        this.chartRef = React.createRef();
        this.chart = null;
    }

    componentDidMount() {
        this.chart = new Chart(this.chartRef.current, this.props.config);
    }

    componentDidUpdate() {
        if (this.props.handleChartUpdate) {
            this.props.handleChartUpdate?.(this.chart);

            return;
        }

        this.chart.data = this.props.config.data;
        this.chart.options = this.props.config.options;
        this.chart.update("none");
    }

    /**
     * Hide all data sets in the chart data.
     */
    hideAll() {
        if (!this.chart?.data.datasets.length) {
            return;
        }
        this.chart?.data.datasets.forEach((_ds, idx) => {
            const meta = this.chart.getDatasetMeta(idx);
            meta.hidden = true;
        });
        this.chart?.hide(0);
    }

    /**
     * Show all data sets in the chart data.
     */
    showAll() {
        if (!this.chart?.data.datasets.length) {
            return;
        }
        this.chart?.data.datasets.forEach((_ds, idx) => {
            const meta = this.chart.getDatasetMeta(idx);
            meta.hidden = false;
        });
        this.chart?.show(0);
    }

    render() {
        return <canvas ref={this.chartRef} />;
    }
}
