import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faKey } from "@fortawesome/free-solid-svg-icons";

export default class LoginForm extends React.Component {
    static defaultProps = {
        handleSubmit: (user, pass, e) => console.log("Login submit pressed"),
        loginMessage: ""
    }

    constructor(props) {
        super(props);

        this.state = {
            status: "",
            user: "",
            pass: ""
        };

        this.changeStateValue = this.changeStateValue.bind(this);
        this.submitLogin = this.submitLogin.bind(this);
    }

    changeStateValue(field, e) {
        const change = {};
        const value = e.target.value;
        change[field] = value;

        this.setState(change);
    }

    submitLogin(e) {
        e.preventDefault();
        const user = this.state.user;
        const pass = this.state.pass;
        this.setState({ pass: "" });
        this.props.handleSubmit(user, pass, e);
    }

    render() {
        return (
            <form>
                <div className="field">
                    <label className="label">Username</label>
                    <div className="control has-icons-left">
                        <input type="text" className="input" placeholder="Username" name="user" value={this.state.user} onChange={e => this.changeStateValue("user", e)} required={true} />
                        <span className="icon is-small is-left">
                            <FontAwesomeIcon icon={faUser} />
                        </span>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Password</label>
                    <div className="control has-icons-left">
                        <input type="password" className="input" placeholder="Password" name="password" value={this.state.pass} onChange={e => this.changeStateValue("pass", e)} required={true} />
                        <span className="icon is-small is-left">
                            <FontAwesomeIcon icon={faKey} />
                        </span>
                    </div>

                    <div className="has-text-danger is-size-7">
                        {this.props.loginMessage}                        
                    </div>
                </div>

                <div className="field">
                    <div className="control">
                        <input type="submit" className="button is-primary is-medium is-fullwidth" value="Log In" onClick={e => this.submitLogin(e)} />
                    </div>
                </div>
            </form>
        )
    }
}