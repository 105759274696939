import React from "react";
import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import LogfileDownloader from "./LogfileDownloader";

export default function ComhubCard(props={}) {
  const [showModal, setShowModal] = useState(false);
  const targetRef = useRef(null);
  let displayDate = Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: false,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(new Date(Number(props.prime * 1000)));

  useEffect(() => {
    if (showModal && targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth", // Optional: Add smooth scrolling animation
        block: "start", // Scroll to the top of the element
      });
    }
  }, [showModal]);

  const handleClose = () => {
    setShowModal(false);
  };

  const portalContent = (
    <div>
      <LogfileDownloader
        lfs={props.lfs}
        onDataToParent={props.onDataFromChild}
        deviceInfo={props.info}
        onClose={handleClose}
      />
    </div>
  );

  return (
    <div className="">
      <div className="dashboard-tiles">
        <div className="dashboard-tile">
          <div className="tile-content">
            <h4 className="tile-label"> Last Upload: {displayDate} </h4>
            <span className="tile-value">
              {props && props.info && props.info.friendlyName
                ? props.info.friendlyName
                : "loading"}
            </span>
            <div className="tile-footer">
              <div className="icon-box is-purple is-rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-activity"
                >
                  <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                </svg>
              </div>
              <div className="flex-meta">
                <p className="paragraph rem-85">
                  <span className="tag is-light is-success is-rounded is-uppercase">
                    Online
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="action">
            <div className="flex-meta">
              <button
                className="button icon-button is-secondary raised"
                onClick={() => {
                  setShowModal(!showModal);
                }}
              >
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-file"
                  >
                    <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                    <polyline points="13 2 13 9 20 9"></polyline>
                  </svg>
                  <span>{"  " + props.lfs.length}</span>
                </span>
              </button>
              {showModal &&
                createPortal(
                  portalContent,
                  document.querySelector("#modal-" + props.comhubId)
                )}
            </div>
          </div>
        </div>
        <span
          ref={targetRef}
          id={"modal-" + props.comhubId}
          className={`portal-content ${showModal ? "visible" : ""}`}
        ></span>
      </div>
    </div>
  );
}
