import React from "react";

import GtriHrapsLogo from "../misc/logos/GtriHrapsLogo";
import LoginForm from "./LoginForm";

import { login, isLoggedIn } from "../../actions/user-actions";

import PathList from "../../util/path-list";

export default class Login extends React.Component {
    static defaultProps = {
        destinationPage: PathList.portal
    }

    constructor(props) {
        super(props);

        this.state = {
            loginMessage: ""
        };

        this.handleLogin = this.handleLogin.bind(this);
    }

    async handleLogin(user, pass) {
        await login(user, pass).catch((err) => console.error('Login failed:', err));

        this.setState({ loginMessage: "" });

        if (!isLoggedIn()) {
            this.setState({ loginMessage: "Unable to log in." });
            return;
        }

        const paramStr = window.location.search.split("?")[1];
        const params = (paramStr)
            ? Object.fromEntries(
                paramStr.split("&")
                .map((v) => v.split("="))
                .map(([k, v]) => [k, decodeURIComponent(v)])
            )
            : {};

        if (Object.keys(params).includes('from')) {
            window.location.assign(params.from);
        }
        else {
            window.location.assign(this.props.destinationPage);
        }
    }

    render() {
        return (
            <div className="hero is-fullheight purple-gradient has-text-light">
                <div className="hero-body">
                    <div className="container">
                        <div className="level">
                            <div className="level-item">
                                <GtriHrapsLogo />
                            </div>
                        </div>

                        <div className="card has-background-light">
                            <div className="container py-5">
                                <div className="level">
                                    <h3 className="title level-item">Log in to your account</h3>
                                </div>
                                <div className="level">
                                    <div className="level-item">
                                        <LoginForm handleSubmit={(user, pass) => this.handleLogin(user, pass)} loginMessage={this.state.loginMessage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
