import React from "react";

export const ASCENDING = "▲";
export const DECENDING = "▼";

export default class SortableTable extends React.Component {
    static defaultProps = {
        headers: [
            { name: "Header 1", title: "Header One - Expand abbreviations here" },
            { name: "Header 2", sortAlgorithm: "exampleSort" },
            { name: "Header 3" },
            { name: "Header 4" }
        ],
        data: [
            ["Data 1", "Data 2", "Data 3", "Data 4"],
            ["Data 1", "Data 2", "Data 3", "Data 4"],
            ["Data 1", "Data 2", "Data 3", "Data 4"],
            ["Data 1", "Data 2", "Data 3", "Data 4"],
            ["Data 1", "Data 2", "Data 3", "Data 4"],
            [<div>Hi 1</div>, <div>Hi 2</div>, <div>Hi 3</div>, <div>Hi 4</div>],
            [<div>Hi 1</div>, <div>Hi 2</div>, <div>Hi 3</div>, <div>Hi 4</div>],
            [<div>Hi 1</div>, <div>Hi 2</div>, <div>Hi 3</div>, <div>Hi 4</div>],
            [<div>Hi 1</div>, <div>Hi 2</div>, <div>Hi 3</div>, <div>Hi 4</div>] // You can use strings, or put JSX HTML as content
        ],
        tableClasses: ["is-striped", "is-fullwidth"],
        sortable: false,
        defaultSort: [0, ASCENDING],
        sortingAlgorithms: {
            exampleSort: (a, b) => `${a}`.localeCompare(`${b}`)
        },
        numbered: false, // TODO: Actually implement numbering rows...
        rowClickCallback: (row, data, e) => e.preventDefault() // If a row needs to be clickable, just add a callback, if not set to false
    }

    constructor(props) {
        super(props);

        this.state = {
            sortColumn: this.props.sortable ? this.props.defaultSort[0] : false,
            sortDirection: this.props.sortable ? this.props.defaultSort[1] : false
        }

        this.sortData = this.sortData.bind(this);
        this.clickSortColumn = this.clickSortColumn.bind(this);
    }

    sortData() {
        const newData = [...this.props.data];
        const sortAlgo = this.props.sortingAlgorithms?.[this.props.headers?.[this.state.sortColumn]?.sortAlgorithm];

        if (sortAlgo) {
            if (this.state.sortDirection === ASCENDING) {
                newData.sort((a, b) => sortAlgo(a[this.state.sortColumn], b[this.state.sortColumn]));
            }

            if (this.state.sortDirection === DECENDING) {
                newData.sort((a, b) => sortAlgo(b[this.state.sortColumn], a[this.state.sortColumn]));
            }

        } else {
            newData.sort();
            if (this.state.sortDirection === DECENDING) {
                newData.reverse();
            }
        }

        return newData;
    }

    clickSortColumn(column, e) {
        e.preventDefault();
        if (!this.props.sortable) {
            return;
        }

        const newDirection = this.state.sortDirection === ASCENDING ? DECENDING : ASCENDING;

        this.setState({ sortColumn: column, sortDirection: newDirection });
    }

    render() {
        const headers = this.props.headers.map((v, i) => {
            return (
                <th key={`sortable-table-header-${i}`} title={v?.title || v.name} onClick={e => this.clickSortColumn(i, e)}>
                    {v.name}{this.props.sortable && i === this.state.sortColumn ? ` ${this.state.sortDirection}` : null}
                </th>
            );
        });

        const orderedRows = this.props.sortable ? this.sortData() : this.props.data;

        const data = orderedRows.map((v, i) => {
            const line = v.map((w, j) => {
                return (
                    <td key={`sortable-table-body-line-${j}`}>
                        {w}
                    </td>
                );
            });

            return (
                <tr key={`sortable-table-body-${i}`} onClick={e => this.props?.rowClickCallback ? this.props?.rowClickCallback?.(i, orderedRows?.[i], e) : undefined}>
                    {line}
                </tr>
            );
        });

        const classes = this.props.tableClasses?.length > 0 ? `table ${this.props.tableClasses.join(" ")}` : "table";

        return (
            <div className="table-container">
            <table className={classes}>
                {headers?.length > 0 ? <thead><tr>{headers}</tr></thead> : null}
                {data?.length > 0 ? <tbody>{data}</tbody> : null}
            </table>
            </div>
        );
    }
}
