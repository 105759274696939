import React, { useState } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from "react-dnd-touch-backend";
import { isBrowser } from "react-device-detect";
import pathList from "../../../util/path-list";

import {DeviceList} from './DeviceBox';
import {RecruitList} from './RecruitBox';

import ConfigurationHeader from "../ConfigurationHeader";

import {ENV} from "../../../util/env-util";

/*
 * Root Component
 */

export default function PairConfiguration(props) {
    const [selectedDevices, setSelectedDevices] = useState({});
    const [pairedDevices, setPairedDevices] = useState(0);

    const debug_flag = ENV.webClientDebug === "1"

    return (
        <div>
            <ConfigurationHeader selectedButton={"Pair"} previousPath={pathList.recruits}
                                 nextPath={pathList.events} currentPath="Pairing"/>
            <DndProvider backend={isBrowser ? HTML5Backend : TouchBackend}>
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <RecruitList
                                debugFlag={debug_flag}
                                selectedDevices={selectedDevices}
                                onPairedDevices={setPairedDevices}
                            />
                        </div>

                        <div className="column">
                            <DeviceList
                                debugFlag={debug_flag}
                                pairedDevices={pairedDevices}
                                onSelectedDevices={setSelectedDevices}
                            />
                        </div>
                    </div>
                </div>
            </DndProvider>
        </div>
    );
}
