import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import FormConfirmModal from '../../misc/FormConfirmModal';
import SimpleErrorModal from '../../misc/SimpleErrorModal';
import DateTimeSelector from "../../misc/DateTimeSelector";
import SelectList from "../../misc/SelectList";
import Button from '../../misc/Button';

import {
    createEvent,
    getOneEvent,
    updateEvent,
} from "../../../actions/event-actions";
import {
    getAllRecruits,
} from '../../../actions/recruit-actions';
import {
    getPageOfSquads,
} from '../../../actions/squad-actions';
import { getOrganizations } from "../../../actions/organization-actions";

import _ from 'lodash';
import { ConsoleView } from 'react-device-detect';

function EventEdit(props = {}) {
    const {
        isUpdate = false,
    } = props;

    const eventId = props.match.params.eventId;

    // Form fields
    const [eventName, setEventName] = useState('');
    const [courseName, setCourseName] = useState('');
    const [courseNumber, setCourseNumber] = useState('');
    const [estimatedStartTime, setEstimatedStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [org, setOrg] = useState('');
    const [recruits, setRecruits] = useState([]);
    const [squads, setSquads] = useState([]);

    // Other states
    const [orgs, setOrgs] = useState([]);
    const [recruitList, setRecruitList] = useState([]);
    const [squadList, setSquadList] = useState([]);
    const [modal, setModal] = useState(null);
    const [recruitListNeedsUpdate, setRecruitListNeedsUpdate] = useState(true);
    const [squadListNeedsUpdate, setSquadListNeedsUpdate] = useState(true);

    const selectedRecruitsList = recruits.filter((x) => !!x).map((x) => x.id);
    const selectedSquadsList = squads.filter((x) => !!x).map((x) => x.id);

    const eventNameSetter = (e) => {
        if (e.includes(' ')) {
            setModal(<FormConfirmModal
                msg='Cannot Contain Spaces'
                onConfirm={() => setModal(null)}
                onCancel={() => setModal(null)}
            />)
        } else {
            setEventName(e);
        }
    }

    const courseNameSetter = (e) => {
        if (e.includes(' ')) {
            setModal(<FormConfirmModal
                msg='Cannot Contain Spaces'
                onConfirm={() => setModal(null)}
                onCancel={() => setModal(null)}
            />)
        } else {
            setCourseName(e);
        }
    }

    const courseNumberSetter = (e) => {
        if (e.includes(' ')) {
            setModal(<FormConfirmModal
                msg='Cannot Contain Spaces'
                onConfirm={() => setModal(null)}
                onCancel={() => setModal(null)}
            />)
        } else {
            setCourseNumber(e);
        }
    }

    const setters = {
        eventName: eventNameSetter,
        courseName: courseNameSetter,
        courseNumber: courseNumberSetter,
        estimatedStartTime: setEstimatedStartTime,
        endTime: setEndTime,
        org: setOrg,
        recruits: setRecruits,
        squads: setSquads,
    };

    const handleInputChange = (e) => {
        const val = (e.target.type === 'checkbox') ? e.target.checked : e.target.value;
        console.debug(`${e.target.name} change to ${val}`)
        setters[e.target.name](val);
    };
    const handleDateTimePickerChange = (key, value) => {
        setters[key](value);
    };

    const onSelectRecruit = (id, alt) => {
        console.debug('Recruits select', id, alt);
        if (alt === 'all') {
            setRecruits(recruitList);
        }
        else {
            const recruit = recruitList.find((x) => x.id === id);
            setRecruits(recruits.concat(recruit))
        }
    };
    const onUnselectRecruit = (id, alt) => {
        console.debug('Recruits deselect', id, alt);
        if (alt === 'all') {
            setRecruits([]);
        }
        else {
            setRecruits(recruits.filter((x) => x.id !== id));
        }
    };

    const onSelectSquad = (id, alt) => {
        console.debug('Squads select', id, alt);
        if (alt === 'all') {
            const addedRecruits = {};
            // add every recruit that's in a squad and keep track of which squads they're part of
            squadList.forEach((x) => x.recruits.forEach((y => {
                if (y.id in addedRecruits) {
                    addedRecruits[y.id].squads.push(x.id);
                } else {
                    addedRecruits[y.id] = {...recruitList.find((z) => z.id === y.id), squads: [x.id]};
                }
            })));
            // if recruits from squads already added, update their squads ONLY IF they were previously added as part of a squad
            setRecruits(recruits.map((x) => {
                if (x.id in addedRecruits) {
                    const tmp = addedRecruits[x.id];
                    delete addedRecruits[x.id];
                    return "squads" in x ? tmp : x;
                } else {
                    return x;
                }
            }).concat(Object.values(addedRecruits)));
            setSquads(squadList);
        }
        else {
            const squad = squadList.find((x) => x.id === id);
            const squadRecruits = {}
            // find recruits in specified squad from recruitList
            squad.recruits.forEach((x) => {
                squadRecruits[x.id] = {...recruitList.find((y) => y.id === x.id), squads: [squad.id]};
            })
            // if recruits from squad already added, update their squads ONLY IF they were previously added as part of a squad
            setRecruits(recruits.map((x) => {
                if (x.id in squadRecruits) {
                    const tmp = squadRecruits[x.id];
                    delete squadRecruits[x.id];
                    return "squads" in x ? {...x, squads: x.squads.concat(tmp.squads)} : x;
                } else {
                    return x;
                }
            }).concat(Object.values(squadRecruits)));
            setSquads(squads.concat(squad));
        }
    };

    const onUnselectSquad = (id, alt) => {
        console.debug('Squads deselect', id, alt);
        if (alt === 'all') {
            // remove all recruits that were added as part of a squad
            setRecruits(recruits.filter((x) => !("squads" in x)));
            setSquads([]);
        }
        else {
            // remove recruits in specified squad only if they were added as part of that squad
            setRecruits(recruits.filter((x) => !("squads" in x && x.squads.includes(id))));
            setSquads(squads.filter((x) => x.id !== id));
        }
    };

    const onSubmitClicked = (e) => {
        e.preventDefault();

        const data = {
            eventName,
            courseName,
            courseNumber,
            estimatedStartTime: estimatedStartTime?.toISOString?.(),
            endTime: endTime?.toISOString?.(),
            org,
            recruits: recruits.map((r) => ({
                id: r.id,
                coreUserId: r.coreUserId,
                squads: r.squads,
            })),
            squads: squads.map((s) => ({
                id: s.id,
                name: s.name,
            })),
            squads: squads.map((s) => ({
                id: s.id,
                name: s.name,
            })),
        }

        const labels = {
            eventName: 'Event Name',
            courseName: 'Course Name',
            courseNumber: 'Course Number',
            estimatedStartTime: 'Est. Start Time',
            endTime: 'End Time',
            org: 'Organization',
            recruits: 'Recruits',
            squads: 'Squads',
        };

        setModal(<FormConfirmModal
            msg='Confirm form submission?'
            data={{...data, recruits: recruits.map((x) => x.displayName ?? x.name), squads: squads.map((x) => x.name)}}
            labels={labels}
            onConfirm={() => {
                ((isUpdate) ? updateEvent(eventId, data) : createEvent(data)).then(_v => {
                    props.history.goBack();
                }).catch(err => {
                    console.error(err);
                    setModal(<SimpleErrorModal
                        msg={`Failed to submit form data: ${err}`}
                        onClose={() => setModal(null)}
                    />);
                });
            }}
            onCancel={() => setModal(null)}
        />);
    };

    /*----------------------------------------*/

    useEffect(() => {
        getOrganizations().then((res) => {
            setOrgs(res.map(x => [x, x]));
        }).catch((err) => {
            console.error('Failed to query orgs:', err);
        });

        getAllRecruits().then((res) => {
            setRecruitList(res.filter((x) => !x.isDeleted));
        }).catch((err) => {
            console.error('Failed to query device types', err);
        });

        getPageOfSquads(0, undefined).then((res) => {
            setSquadList(res);
        }).catch((err) => {
            console.error('Failed to query squads:', err);
        });

        if (isUpdate) {
            getOneEvent(eventId).then((res) => {
                console.debug('Got event data', res);
                Object.entries(res).forEach(([k, v]) => {
                    if (k in setters) {
                        if (['estimatedStartTime', 'endTime'].includes(k)) {
                            v = new Date(v);
                        }
                        setters[k](v);
                    }
                })
            }).catch((err) => {
                console.error('Failed to get event data:', err);
            });
        }
    }, []);

    useEffect(() => {
        if (recruitListNeedsUpdate && !!recruitList.length && !!recruits.length) {
            console.debug('Updating Selected Recruits to Ensure correct data is included');
            setRecruits(recruits.map((r) => {
                if (!('name' in r)) {
                    const found = recruitList.find((x) => x.id === r.id);
                    if (!!found) {
                        return r.squads ? {...found, squads: r.squads} : found;
                    }
                }
                return r;
            }));
            setRecruitListNeedsUpdate(false);
        }
    }, [recruits.length, recruitList.length]);

    useEffect(() => { 
        if (squadListNeedsUpdate && !!squadList.length && !!squads.length) {
            console.debug('Updating Selected Squads to Ensure correct data is included');
            setSquads(squads.map((s) => {
                if (!('name' in s)) {
                    const found = squadList.find((x) => x.id === s.id);
                    if (!!found) {
                        return found;
                    }
                }
                return s;
            }));
            setSquadListNeedsUpdate(false);
        }
    }, [squads.length, squads.length]);

    /*----------------------------------------*/

    const submitButtonName = (isUpdate) ? 'Update' : 'Create';

    const orgOpts = orgs.map((t, i) => (
        <option key={`org-opt-${i}`} value={t[0]}>{t[1]}</option>
    ));

    const recruitsSelList = recruitList.map((x) => ({value: x.id, display: x.name}));
    const squadsSelList = squadList.map((x) => ({value: x.id, display: x.name}));

    return (<>
            <div>
                <div className="block"></div>

                <div className="container">
                    <div className="field">
                        <label className="label">Event Name: (No Spaces)</label>
                        <div className="control">
                            <input
                                type="text"
                                className={ ["input"].join(" ") }
                                name="eventName"
                                value={eventName}
                                onChange={handleInputChange}
                                onLoad={handleInputChange}
                                />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Course Name: (No Spaces)</label>
                        <div className="control">
                            <input
                                type="text"
                                className={ ["input"].join(" ") }
                                name="courseName"
                                value={courseName}
                                onChange={handleInputChange}
                                onLoad={handleInputChange}
                                />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Course Number: (No Spaces)</label>
                        <div className="control">
                            <input
                                type="text"
                                className={ ["input"].join(" ") }
                                name="courseNumber"
                                value={courseNumber}
                                onChange={handleInputChange}
                                onLoad={handleInputChange}
                                />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Est. Start Time</label>
                        <div className="control">
                            <DateTimeSelector
                                name="estimatedStartTime"
                                value={estimatedStartTime}
                                onChange={handleDateTimePickerChange}
                                />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">End Time</label>
                        <div className="control">
                            <DateTimeSelector
                                name="endTime"
                                value={endTime}
                                onChange={handleDateTimePickerChange}
                                />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Organization</label>
                        <div className="control">
                            <div className="select is-fullwidth">
                                <select name="org" value={org} onChange={handleInputChange}>
                                    <option disabled={true} value="">Select Organization</option>
                                    {orgOpts}
                                </select>
                            </div>
                        </div>
                    </div>

                    <SelectList
                        label={"Recruits"}
                        list={recruitsSelList}
                        selected={selectedRecruitsList}
                        onSelect={id => onSelectRecruit(id)}
                        onUnselect={id => onUnselectRecruit(id)}
                        onSelectAll={() => onSelectRecruit(null, 'all')}
                        onUnselectAll={() => onUnselectRecruit(null, 'all')}
                    />

                    <SelectList
                        label={"Squads"}
                        list={squadsSelList}
                        selected={selectedSquadsList}
                        onSelect={id => onSelectSquad(id)}
                        onUnselect={id => onUnselectSquad(id)}
                        onSelectAll={() => onSelectSquad(null, 'all')}
                        onUnselectAll={() => onUnselectSquad(null, 'all')}
                    /> 

                    <div className="field is-grouped">
                        <div className="control">
                            <Button
                                activeColor='is-success'
                                onClick={() => onSubmitClicked({preventDefault: () => {}})}
                            >{submitButtonName}</Button>
                        </div>
                        <div className="control">
                            <Button
                                onClick={() => props.history.goBack()}
                            >Cancel</Button>
                        </div>
                    </div>
                </div>

                {modal}
            </div>
    </>)
}

export default withRouter(EventEdit);
