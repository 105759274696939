import { ENV } from "./env-util";
import { getUserSessionData } from "./user-session-util";

export const METHODS = {
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE"
};

export const CONTENT_TYPES = {
    json: "application/json",
    plain: "text/plain",
}

Object.freeze(METHODS);

export async function apiCall(
        url,
        method,
        {
            body = false,
            baseUrl = `https://${ENV.hrapsApiUrl}`,
            includeAuthorization = false,
            accept = CONTENT_TYPES.json,
            throwOnError = true
        } = {}) {
    const headers = {
        "Accept": accept,
        "Content-Type": "application/json"
    };

    if (includeAuthorization) {
        const userData = getUserSessionData();
        headers["Authorization"] = `${userData?.id}`; // Authorization header should be in the form of "<type> <credentials>" (ie: "Basic user:pass"), but....nah
    }

    const options = {
        method,
        headers,
        cache: "no-cache",
        credentials: "include"
    }

    if (body) {
        options.body = JSON.stringify(body);
    }

    try {
        const targetUrl = new URL(url, baseUrl);
        const response = await fetch(targetUrl.href, options);

        // console.log("RESPONSE:", response);

        if (!response.ok) {
            const txt = await (response.text());
            let err =  new Error(`${response.statusText}: ${txt}`);
            err.http_code = response.status;
            throw err;
        }

        const data = await ((accept === CONTENT_TYPES.json)
                            ? response.json()
                            : response.text());
        return data;

    } catch (error) {
        if (throwOnError) {
            throw error;
        }
        else {
            console.error(error);
            return { error };
        }
    }
}
