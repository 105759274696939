import {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";

import IconText from "./IconText";

const ACCEPTED_TYPES = [
    'normal',
    'checkable',
    'delete',
    'link',
    'modal-close',
];
Object.freeze(ACCEPTED_TYPES);


export default function Button(props = {}) {
    const {
        type = 'normal',
        icon = null,
        iconAfterText = false,
        activeColor = null,
        classList = [],
        value = null,
        path = null,
        onClick = () => {},
        disabled,
        key = undefined,
    } = props;

    if (!ACCEPTED_TYPES.includes(type)) {
        console.error(
            `Button component's type "${type}" unrecognized, acceptable types: `,
            ACCEPTED_TYPES
        )
    }

    const [curValue, setCurValue] = useState(value ?? false);
    const [curIcon, setCurIcon] = useState(icon);
    const [activeStyle, setActiveStyle] = useState([
        ...classList,
        (!['delete', 'modal-close'].includes(type))
        ? 'button'
        : (type === 'modal-close')
        ? 'modal-close'
        : 'delete',
        (type !== 'checkable') ? activeColor : '',
    ]);

    const handleClick = () => {
        if (['normal', 'delete', 'modal-close'].includes(type)) {
            onClick(value);
        }
        if (type === 'checkable') {
            onClick(!curValue);
            if (value == null) {
                setCurValue(!curValue);
            }
        }
    }

    const resetStyling = () => {
        if (['normal', 'link'].includes(type)) {
            setActiveStyle((activeColor != null)
                ? ['button', ...classList, activeColor]
                : ['button', ...classList]
            );
        }
        else if (['modal-close'].includes(type)) {
            setActiveStyle((activeColor != null)
                ? ['modal-close', ...classList, activeColor]
                : ['modal-close', ...classList]
            );
        }
        else if (['delete'].includes(type)) {
            setActiveStyle((activeColor != null)
                ? ['delete', ...classList, activeColor]
                : ['delete', ...classList]
            );
        }
        else if (type === 'checkable') {
            setCurIcon((curValue) ? faCheckSquare : faSquare);
            setActiveStyle((curValue)
                ? ['button', ...classList, activeColor]
                : ['button', ...classList]
            );
        }
    };

    useEffect(() => {
        resetStyling();
    }, [curValue, activeColor, value]);

    useEffect(() => {
        if (value != null) {
            setCurValue(value);
        }
    }, [value])

    const children = (curIcon)
        ? (<IconText icon={curIcon} text={props.children} iconAfterText={iconAfterText} />)
        : (<>{props.children}</>);

    const buttonElem = (type === 'link') ? (
        <Link className={activeStyle.join(' ')} to={path} disabled={disabled}>
            {children}
        </Link>
    ) : (
        <div className={activeStyle.join(' ')} onClick={handleClick} disabled={disabled}>
        {children}
        </div>
    );
    return (<span key={key}>{buttonElem}</span>);
}
