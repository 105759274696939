function getPageTotal(itemTotal, pageLimit) {
    return (itemTotal === 0) ? 0 : Math.ceil(itemTotal / pageLimit);
}

export default function PageSelection(props = {}) {
    const {
        page = 0,
        limit = 5,
        itemTotal = 0,
        onPageChange = (_page) => {},
    } = props;

    const pageTotal = getPageTotal(itemTotal, limit);
    console.debug(`PageSelection: Page ${page}; Limit ${limit}; Total ${itemTotal}; Total ${pageTotal}`)

    let pages = [
        ...((page > 1) ? [{page: 0}] : []),
        ...((page > 2) ? [{page: 0, gap: true}] : []),
        ...((page > 0) ? [{page: page - 1}] : []),
        {page: page, current: true},
        ...(((itemTotal > 0) && ((pageTotal - page) > 1)) ? [{page: page + 1}] : []),
        ...(((pageTotal > 0) && ((pageTotal - page) > 3)) ? [{page: 0, gap: true}] : []),
        ...(((pageTotal > 0) && ((pageTotal - page) > 2)) ? [{page: pageTotal - 1}] : []),
    ];
    // pages = pages.map((x) => ({...x, page: x.page + 1}));
    pages = pages.map((x, i) => {
        if (x.gap) {
            return (<li key={`pagesel-${i}-ell`}>
                <span className="pagination-ellipsis">&hellip;</span>
            </li>);
        }
        return (<li key={`pagesel-${i}-${x.page}`}>
            <a
                className={`pagination-link ${x?.current ? 'is-current' : ''}`}
                onClick={() => onPageChange(x.page)}
            >{x.page + 1}</a>
        </li>);
    });

    const navClasses = ['pagination', 'is-small'];

    return (<nav className={navClasses.join(' ')}>
        <div className="pagination-list">
            {pages}
        </div>
    </nav>)
}
