import React, { useState, useEffect, useRef } from "react";
import {
  getAllCommhubs,
  getActiveCommhubs,
  getCommhubSettings,
  getCommhubStatus,
} from "../../../actions/comm-hub-actions";
import ConfigurationHeader from "../../configuration/ConfigurationHeader";
import pathList from "../../../util/path-list";
import Button from "../../misc/Button";
import SearchFilter from "../SearchFilter";
import {
  faAngleDown,
  faGlobe,
  faSave,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ActiveCommhubCard from "./ActiveCommhubCard";
import CommHubDetails from "./CommHubDetails";
import { CloseSvg } from "./CommHubSvgs";
import Toaster from "../../misc/toaster/Toaster";

export default function Commhubs(props = {}) {
  const dropdownRef = useRef(null);
  const [commhubs, setCommhubs] = useState([]);
  const [visibleCommhubs, setVisibleCommhubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [commhubSettings, setCommhubSettings] = useState({});
  const [commhubStatus, setCommhubStatus] = useState({});
  const [queryTrigger, setQueryTrigger] = useState(0);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [sortType, setSortType] = useState("serial");
  const [receiveGlobal, setReceiveGlobal] = useState({});
  const [allSelectedForUpload, setAllSelectedForUpload] = useState(true);
  const [totalDevices, setTotalDevices] = useState(0);
  const [selectedDevices, setSelectedDevices] = useState(0);
  const [trigger, setTrigger] = useState(0);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [searchParams, setSearchParams] = useState({
    query: "",
    queryFields: "friendlyName",
  });
  const searchConfig = [
    {
      type: "radio",
      key: "queryFields",
      options: [
        { label: "By Name", value: "friendlyName", default: true },
        { label: "By MAC", value: "nativeDeviceId", default: false },
      ],
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        const [commhubs, settings, status] = await Promise.all([
          getActiveCommhubs(),
          getCommhubSettings(),
          getCommhubStatus(),
        ]);
        const temp = {};
        let total = 0;
        let selected = 0;
        // this is temporary, will most likely keep the fetched active commhubs in dict form for quicker checking when we implement inactive commhubs
        const iterableCommhubs = Object.keys(commhubs).map((x) => {
          total += 1;
          temp[x] = x in receiveGlobal ? receiveGlobal[x] : true;
          if (temp[x]) selected += 1;
          return { ...commhubs[x], mac: x };
        });
        setCommhubs(iterableCommhubs);
        setVisibleCommhubs(sortCommhubs(sortType, iterableCommhubs));
        // TODO write logic to deal with only adding changes to se
        // setting and status if there is a change
        // rework the logic to be more efficient and less redundant
        setCommhubSettings(settings);
        setCommhubStatus(status);
        setTotalDevices(total);
        setSelectedDevices(selected);
        setReceiveGlobal(temp);
        if (loading) setLoading(false);
      } catch (err) {
        console.log("An error occurred:", err);
      }
    };
    getData();
    setTimeout(() => setQueryTrigger(Date.now()), 3000);
  }, [queryTrigger]);

  useEffect(() => {
    setAllSelectedForUpload(selectedDevices === totalDevices);
  }, [totalDevices, selectedDevices]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the dropdown is active and is clicked outside of
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        setDropdownActive(!dropdownActive); // Toggle the dropdown visibility state
      }
    };

    // If the dropdown is active, listen for clicks outside of the dropdown
    if (dropdownActive) {
      window.addEventListener("click", pageClickEvent);
    }

    return () => {
      // Cleanup the event listener
      window.removeEventListener("click", pageClickEvent);
    };
  }, [dropdownActive]);

  useEffect(() => {
    setVisibleCommhubs(sortCommhubs(sortType, commhubs));
  }, [sortType, searchParams]);

  const handleSearchChange = (data) => {
    setSearchParams({ ...searchParams, ...data });
  };

  const handleSelect = (mac) => {
    const selected = receiveGlobal[mac];
    setSelectedDevices(selectedDevices + (selected ? -1 : 1));
    setReceiveGlobal({ ...receiveGlobal, [mac]: !selected });
  };

  const handleSelectAll = () => {
    const temp = receiveGlobal;
    Object.keys(temp).forEach((x) => {
      temp[x] = allSelectedForUpload ? false : true;
    });
    setAllSelectedForUpload(!allSelectedForUpload);
    setReceiveGlobal(temp);
  };

  const sortCommhubs = (by, devices) => {
    let sorted;
    switch (by) {
      case "serial":
        sorted = [...devices].sort((a, b) => a.serial_num - b.serial_num);
        break;
      case "ping":
        sorted = [...devices].sort((a, b) => b.timestamp - a.timestamp);
        break;
      // case "config":
      //   sorted = [...devices].sort((a, b) => {
      //     const timestampA =
      //       a.mac in commhubSettings
      //         ? commhubSettings[a.mac].timestamp
      //         : new Date(0);
      //     const timestampB =
      //       b.mac in commhubSettings
      //         ? commhubSettings[b.mac].timestamp
      //         : new Date(0);

      //     return timestampB - timestampA;
      //   });
      //   break;
      // case "status":
      //   sorted = [...devices].sort((a, b) => {
      //     const timestampA =
      //       a.mac in commhubStatus
      //         ? commhubStatus[a.mac].timestamp
      //         : new Date(0);
      //     const timestampB =
      //       b.mac in commhubStatus
      //         ? commhubStatus[b.mac].timestamp
      //         : new Date(0);

      //     return timestampB - timestampA;
      //   });
      //   break;
      default:
        sorted = commhubs;
    }
    const regex = new RegExp(searchParams.query, "i");
    return sorted.filter((x) =>
      regex.test(
        searchParams.queryFields === "friendlyName" ? x.serial_num : x.mac
      )
    );
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenGlobal = () => {
    setIsOpen(true);
  };

  const handleCloseGlobal = () => {
    setIsOpen(false);
  };

  const handleToast = (type, message) => {
    setToastMessage(message);
    setToastType(type);
    setTrigger(trigger + 1);
  };

  return (
    <div className="">
      <div className="container">
        <ConfigurationHeader
          selectedButton={"Hubs"}
          previousPath={pathList.devices}
          nextPath={pathList.recruits}
          currentPath="Communication Hubs"
        />
        <div className="block"></div>
        <div className="level">
          <div className="level-item level-right">
            <div className="field is-grouped">
              <div className="control">
                <Button activeColor onClick={handleOpenGlobal} icon={faGlobe}>
                  Configure Global Settings
                </Button>
              </div>
              <div className="control">
                <Button
                  type="checkable"
                  activeColor="is-hraps"
                  value={allSelectedForUpload}
                  onClick={() => handleSelectAll()}
                >
                  Select All
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <dialog open={isOpen}>
            <CommHubDetails
              handleClose={handleCloseGlobal}
              mac="global"
              //turn receiveGlobal into an array of mac addresses whose value in receiveGlobal is true
              receivingDevices={Object.keys(receiveGlobal).flatMap((x) => {
                return receiveGlobal[x] ? [x] : []
              })}
              handleToast={handleToast}
            />
          </dialog>
        </div>
        <div className="project-list">
          <div className="project-list-item">
            <div className="project">
              <SearchFilter
                hideOrg={true}
                config={searchConfig}
                onChange={(d) => handleSearchChange(d)}
              />
            </div>
            <div className="details">
              <div
                className={`dropdown ${dropdownActive ? "is-active" : ""}`}
                ref={dropdownRef}
              >
                <div className="dropdown-trigger">
                  <button
                    className="button"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    onClick={() => setDropdownActive(!dropdownActive)}
                  >
                    <span>Sort By</span>
                    <span className="icon is-small">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                  </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content">
                    <a
                      className={`dropdown-item ${
                        sortType === "serial" ? "is-active" : ""
                      }`}
                      onClick={() => {
                        setSortType("serial");
                      }}
                    >
                      Serial Number
                    </a>
                    <a
                      className={`dropdown-item ${
                        sortType === "status" ? "is-active" : ""
                      }`}
                      onClick={() => {
                        setSortType("status");
                      }}
                    >
                      Most Recent Status
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="is-size-3 has-text-centered column">
            <h3>Loading...</h3>
          </div>
        ) : commhubs.length == 0 ? (
          <div className="is-size-3 has-text-centered column">
            <h3>No Active Commhubs Found</h3>
          </div>
        ) : (
          <div className="columns is-multiline">
            {visibleCommhubs.map((x) => {
              return (
                <div key={x.mac} className="column is-4">
                  <ActiveCommhubCard
                    status={commhubStatus[x.mac]}
                    commhub={x}
                    receiveGlobal={receiveGlobal[x.mac]}
                    onSelect={handleSelect}
                    handleToast={handleToast}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Toaster
        trigger={trigger}
        message={toastMessage}
        type={toastType}
        style={{ "z-index": 100 }}
      />
    </div>
  );
}
