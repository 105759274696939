import React from "react";
import { useState } from "react";

export default function LogfileDownloader(props={}) {
  const [files, setFiles] = useState([]);

  function displayDate(dte) {
    let date = new Date(Number(dte * 1000));
    return Intl.DateTimeFormat(undefined, {
      dateStyle: "full",
      hour12: false,
      timeStyle: "short",
    }).format(date);
  }

  function handleClick(file, info) {
    file["logfileId"] = file._id;
    props.onDataToParent(file, info);
    setFiles([...files]);
  }

  const listItems = props.lfs.map((file, idx) => {
    return (
      <li key={`logile-for-device-${file._id}-${idx}`}>
        <div className="widget-list-item">
          <div className="list-item-data">
            <div className="icon-box is-xs is-rounded is-purple">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-file"
              >
                <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                <polyline points="13 2 13 9 20 9"></polyline>
              </svg>
            </div>
            <div className="item-info">
              <span className="item-title">
                End: {displayDate(file.endTimeUnix)}
              </span>
              <span>Start: {displayDate(file.startTimeUnix)}</span>
              <span>Id: {file._id.slice(-7)}</span>
            </div>
            <div className="item-action">
              <button
                href="#"
                className={`button raised ${
                  file["active"] ? "is-secondary" : "is-outlined"
                } `}
                onClick={() => {
                  handleClick(file, props.deviceInfo);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-download-cloud"
                >
                  <polyline points="8 17 12 21 16 17"></polyline>
                  <line x1="12" y1="12" x2="12" y2="21"></line>
                  <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </li>
    );
  });

  return (
    <div className="column">
      <div className="list-widget">
        <div className="toolbar is-spaced-bottom">
          <div className="toolbar-start">
            <h4 className="is-4 title">Files</h4>
          </div>
          <div className="toolbar-end">
            <button className="button is-solid" onClick={props.onClose}>
              Close
            </button>
          </div>
        </div>
        <ul className="inner-list">
          {props.lfs.length > 0 ? listItems : <h1>No Data</h1>}
          <br />
          <li className="widget-list-item">
            <div className="list-item-data">
              <div className="item-info">
                <span className="item-title">Now</span>
                <span>
                  {displayDate(Math.floor(new Date().getTime() / 1000))}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
