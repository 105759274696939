import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import FormConfirmModal from '../../misc/FormConfirmModal';
import SimpleErrorModal from '../../misc/SimpleErrorModal';
import Button from '../../misc/Button';

import {
    addRecruit,
    getOneRecruit,
    updateRecruit,
} from "../../../actions/recruit-actions";

import { getOrganizations } from "../../../actions/organization-actions";

import _ from 'lodash';

function RecruitEdit(props = {}) {
    const {
        isUpdate = false,
    } = props;

    const recruitId = props.match.params.recruitId;

    // Form fields
    const [name, setName] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [org, setOrg] = useState('');

    // Other states
    const [orgs, setOrgs] = useState([]);
    const [modal, setModal] = useState(null);

    const setters = {
        name: setName,
        displayName: setDisplayName,
        org: setOrg,
    };

    const handleInputChange = (e) => {
        const val = (e.target.type === 'checkbox') ? e.target.checked : e.target.value;
        console.debug(`${e.target.name} change to ${val}`)
        setters[e.target.name](val);
    };

    const onSubmitClicked = (e) => {
        e.preventDefault();

        const data = {
            name,
            displayName,
            org,
        }

        const labels = {
            name: 'Name',
            displayName: 'Roster ID',
            org: 'Organization',
        };

        setModal(<FormConfirmModal
            msg='Confirm form submission?'
            data={data}
            labels={labels}
            onConfirm={() => {
                ((isUpdate) ? updateRecruit(recruitId, data) : addRecruit(data)).then(_v => {
                    props.history.goBack();
                }).catch(err => {
                    setModal(<SimpleErrorModal
                        msg={`Failed to submit form data: ${err}`}
                        onClose={() => setModal(null)}
                    />);
                });
            }}
            onCancel={() => setModal(null)}
        />);
    };

    /*----------------------------------------*/

    useEffect(() => {
        getOrganizations().then((res) => {
            setOrgs(res.map(x => [x, x]));
        }).catch((err) => {
            console.error('Failed to query orgs:', err);
        });

        if (isUpdate) {
            getOneRecruit(recruitId).then((res) => {
                console.debug('Got recruit data', res);
                Object.entries(res).forEach(([k, v]) => {
                    if (k in setters) {
                        setters[k](v);
                    }
                })
            }).catch((err) => {
                console.error('Failed to get recruit data:', err);
            });
        }
    }, []);

    /*----------------------------------------*/

    const submitButtonName = (isUpdate) ? 'Update' : 'Create';

    const orgOpts = orgs.map((t, i) => (
        <option key={`org-opt-${i}`} value={t[0]}>{t[1]}</option>
    ));

    const required = <span style={{color: "red"}}>*</span>;

    return (<>
            <div>
                <div className="block"></div>

                <div className="container">
                    <div className="field">
                        <label className="label">Name {required}</label>
                        <div className="control">
                            <input
                                type="text"
                                className="input"
                                name="name"
                                value={name}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Roster ID</label>
                        <div className="control">
                            <input
                                type="text"
                                className="input"
                                name="displayName"
                                value={displayName}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Organization {required}</label>
                        <div className="control">
                            <div className="select is-fullwidth">
                                <select name="org"
                                    value={org}
                                    onChange={handleInputChange}>
                                    <option disabled={true} value="">Select Organization</option>
                                    {orgOpts}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="field is-grouped">
                        <div className="control">
                            <Button
                                activeColor='is-success'
                                onClick={() => onSubmitClicked({preventDefault: () => {}})}
                            >{submitButtonName}</Button>
                        </div>
                        <div className="control">
                            <Button
                                onClick={() => props.history.goBack()}
                            >Cancel</Button>
                        </div>
                    </div>
                </div>

                {modal}
            </div>
    </>)
}

export default withRouter(RecruitEdit);
