import React, {useState, useEffect} from 'react';
import { useDrag } from 'react-dnd';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckSquare,
    faSquare,
    faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

import { ItemTypes } from './ItemTypes';

import PageSelection from '../../misc/PageSelection';

import { getPageOfDevices } from "../../../actions/device-actions";

/*------------------------------------------------------------------------------------------------*/
/*
 * Device Element and Drag Box
 */

export default function DeviceBox(props) {
    const expectedKeys = ['friendlyName', 'id', 'nativeDeviceId'];
    const noDevice = Object.fromEntries(expectedKeys.map((v) => [v, '']));

    const device = props.device || noDevice;
    const selected = props.selected || false;
    const paired = props.paired || false;
    const debugFlag = props.debugFlag || false;
    const beginDragFunc = props.beginDragFunc || ((_id) => {});
    const endDragFunc = props.endDragFunc || ((_id) => {});
    const onClicked = props.onClickedFunc || ((_id, _state) => {});

    const beginDrag = () => { beginDragFunc(device.id) };
    const endDrag = () => { endDragFunc(device.id) };
    const itemClicked = () => {
        if (!paired) {
            console.debug(`Device ${device.id} clicked. New state ${!selected}`);
            onClicked(device.id, !selected)
        }
    };

    const [collected, drag, _] = useDrag(() => ({
        type: ItemTypes.BOX,
        item: () => {beginDrag(); return {id: device.id} },
        end: endDrag,
    }))

    const opacity = collected.isDragging ? 0.4 : 1;

    const pairedTag = paired
        ? (<div className="tag is-success">Paired</div>)
        : (<div className="tag is-warning">Unpaired</div>);

    const checkClasses = (paired) ? "has-text-success" : (selected) ? "has-text-danger" : "";
    const checkIcon = (paired) ? faThumbsUp : (selected) ? faCheckSquare : faSquare;

    const htmlId = debugFlag ? `${device.nativeDeviceId}` : undefined;

    return (
        <div ref={(paired) ? null : drag} id={htmlId} className="level" style={{opacity}} onClick={itemClicked}>
            <div className="level-item">
                <FontAwesomeIcon className={checkClasses} icon={checkIcon}/>
            </div>

            <div className="level-item">
                <div className="has-text-centered is-size-7">
                    <div className="has-text-weight-bold">Serial #:</div>
                    <div>{device.friendlyName}</div>
                </div>
            </div>

            <div className="level-item">
                <div className="has-text-centered is-size-7">
                    <div className="has-text-weight-bold">MAC Address:</div>
                    <div>{device.nativeDeviceId}</div>
                </div>
            </div>

            <div className="level-item">
                <div className="is-size-7 has-text-centered">
                    <div className="has-text-weight-bold">Status:</div>
                    {pairedTag}
                </div>
            </div>
        </div>
    );
}

/*------------------------------------------------------------------------------------------------*/
/*
 * Device List Component
 */

export function DeviceList(props) {
    const limit = props.limit || 15;
    const debugFlag = props.debugFlag || false;
    const pairedDevices = props.pairedDevices || 0;
    const onSelectedDevices = props.onSelectedDevices || ((_devs) => {});

    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [query, setQuery] = useState('');
    const [devices, setDevices] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState({});
    const [dragging, setDragging] = useState(false);
    const [queryUnpaired, setQueryUnpaired] = useState(false);
    const [queryFields, setQueryFields] = useState('');

    const selectedDeviceIds = Object.keys(selectedDevices);
    const numberOfSelectedDevices = selectedDeviceIds.length;

    const onDeviceToggled = (id, state) => {
        if (state === true) {
            selectedDevices[id] = true;
            setSelectedDevices({
                ...selectedDevices,
                [id]: true,
            });
            console.debug(`Device ${id} enabled`, {...selectedDevices, [id]: true})
        }
        else if (state === false && (id in selectedDevices)) {
            const ret = {...selectedDevices};
            delete ret[id];
            console.debug(`Device ${id} disabled`, ret)
            setSelectedDevices(ret);
        }
    };

    const onDeviceDrag = (state) => {
        console.debug(`Device dragging, ${state}`);
        setDragging(state);
    };

    // Whenever we need to re-query devices
    useEffect(() => {
        const searchParams = [
            ['devicesPerPage', limit],
            ['query', (query !== '') ? query : null],
            ['queryFields', queryFields],
            ['pairStatus', (queryUnpaired) ? 'unpaired' : null],
            ['includeMeta', true],
        ].filter(([_, v]) => v !== null);
        getPageOfDevices(
            page,
            Object.fromEntries(searchParams)
        ).then((res) => {
            setTotal(res.metadata.total ?? 0);
            const devs = res.data;
            if (!devs) {
                throw new Error('Got 0 devices');
            }
            console.debug('Got devices:', devs);
            setDevices(devs.filter((x) => !x.isDeleted));
        }).catch((err) => {
            console.error('Failed to get list of devices:', err);
        });
    }, [query, queryFields, pairedDevices, queryUnpaired, page]);

    // Use callback when unpaired devices have been toggled
    useEffect(() => {
        onSelectedDevices(selectedDevices);
    }, [selectedDevices]);

    // Cancel select if dragged or pairing happened
    useEffect(() => {
        if (dragging) {
            setSelectedDevices({});
        }
    }, [dragging]);
    useEffect(() => {
        setSelectedDevices({});
    }, [pairedDevices]);

    const header = (
        <tr><th>
            <div className={"columns mb-0"}>
                <div className={"column"}>
                    <div className="level">
                        <div className="level-left">
                            <span className="level-item">Devices</span>
                            {
                                (numberOfSelectedDevices > 0)
                                ? <span className="level-item">
                                    {` (${numberOfSelectedDevices} Selected)`}
                                </span>
                                : null
                            }
                        </div>
                        <div>
                            <label>
                                <input type="radio"
                                    name="devQueryType" 
                                    value=""
                                    defaultChecked="true"
                                    onChange={(e) => setQueryFields(e.target.value)}
                                />
                                Serial #
                            </label>
                        </div>
                        <div>
                            <label>
                                <input type="radio"
                                    name="devQueryType" 
                                    value="nativeDeviceId"
                                    onChange={(e) => setQueryFields(e.target.value)}
                                />
                                MAC Address
                            </label>
                        </div>
                        <div className="level-right">
                            <div className="field level-item">
                                <input
                                    type="checkbox"
                                    className="checkbox"
                                    defaultChecked={queryUnpaired}
                                    onChange={(e) => setQueryUnpaired(e.target.checked)} />
                                <label className="label">Unpaired Only</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"columns mb-1"}>
                <div className={"column"}>
                    <input placeholder={"Search for device by serial number or MAC address"}
                           className="input"
                           value={query}
                           onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
            </div>
        </th></tr>
    );

    const body = devices.map((v, i) => {
        const selected = Boolean(v.id in selectedDevices);
        // note: Would have pulled paired status from recruit listing, though that tends to be
        // limited due to queries using a subset of the recruits. This is more reliable in
        // displaying the paired status.
        const paired = !!v.currentRecruit && v.currentRecruit !== '';

        return (
            <tr key={`device-${v.id}-${i}`}><td>
                <DeviceBox
                    device={v}
                    paired={paired}
                    selected={selected}
                    debugFlag={debugFlag}
                    onClickedFunc={onDeviceToggled}
                    beginDragFunc={() => onDeviceDrag(true)}
                    endDragFunc={() => onDeviceDrag(false)}
                />
            </td></tr>
        )
    });

    return (
        <table className="table is-fullwidth is-striped is-hoverable">
            <thead>{header}</thead>
            <tbody>{body}</tbody>
            <tfoot><tr><td>
                <PageSelection
                    page={page}
                    limit={limit}
                    itemTotal={total}
                    onPageChange={setPage}
                />
            </td></tr></tfoot>
        </table>
    );
}
