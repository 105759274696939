import Button from './Button';

/**
 * Properties.
 *
 * - title {String}         The text to use for the title of the modal.
 * - selectText {String}    The text to use for the primary select button [defaults 'Select'].
 * - isOpen {Boolean}       True if the modal is visible otherwise not visible.
 * - onClose {Callable}     Callback for when the modal is closed (x button or submit).
 * - onSubmit {Callable}    Callback for when the submit button is pressed.
 * - otherButtons {List}    List of objects defining the other buttons to create.
 *
 * Buttons example object
 *
 *      {
 *          text: 'Clear',
 *          callback: () => doThing(),
 *      }
 */
export default function Modal(props = {}) {
    const {
        title = '',
        selectText = 'Select',
        selectColor = 'is-success',
        isOpen = false,
        onClose = () => {},
        onSubmit = () => {},
        otherButtons = [],
        bodyBox = true,
    } = props;

    const buttonList = otherButtons.length === 0 ? null : otherButtons.map((x, xi) => (
        <Button key={`extra-bttn-${xi}`} onClick={x.callback}>
            {x.text}
        </Button>
    ))

    return (
        <div className={`modal ${isOpen ? 'is-active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{title}</p>
                    <Button type="delete" onClick={onClose}></Button>
                </header>
                <section className="modal-card-body">
                    {
                        (bodyBox) ? (
                            <div className="box">
                                {props.children}
                            </div>
                        ) : props.children
                    }
                </section>
                <footer className="modal-card-foot">
                    <Button activeColor={selectColor} onClick={() => { onSubmit(); onClose(); }}>
                        {selectText}
                    </Button>
                    {
                        buttonList
                    }
                </footer>
            </div>
        </div>
    );
}
