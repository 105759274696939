import React from "react";
import {Link} from "react-router-dom";

import PathList from "../../util/path-list";
import {getUser, isLoggedIn, logout} from "../../actions/user-actions";

import IconText from "../misc/IconText";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import HrapsLogo from "../misc/logos/HrapsLogo";
import Button from "../misc/Button";

export default class HrapsHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            hamburger: false
        }
    }

    componentDidMount() {
        const user = getUser();
        this.setState({user});
    }

    handleLogout(e) {
        e.preventDefault();
        logout().catch((err) => console.error('Logout failed:', err));
    }
    
    navbar() {
        const loggedIn = isLoggedIn();
        const loginLink = loggedIn ? (
            <Button
                activeColor='is-danger'
                icon={faSignOutAlt}
                onClick={() => this.handleLogout({preventDefault: () => {}})}
            >Log Out</Button>
        ) : <Link to={PathList.login}>Log In</Link>;

        const userName = this.state.user ? `${this.state.user.firstName} ${this.state.user.lastName}` : "Logged Out";
        return (
            <nav className="navbar is-transparent header-bottom-border" role={"navigation"} aria-label={"main-navigation"}>

                <div className="navbar-brand">
                    <Link className={"navbar-item"} to={PathList.portal}>
                        <div><HrapsLogo/></div>
                    </Link>
                    <a role="button" className={this.state.hamburger ? "navbar-burger is-active my-2" : "navbar-burger my-2"} aria-label="menu" aria-expanded="false"
                       data-target="navbarBasicExample" onClick={() => this.setState({hamburger: !this.state.hamburger})}>
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                    </a>
                </div>

                <div id="navbarBasicExample" className={this.state.hamburger ? "is-active navbar-menu" : "navbar-menu"}>
                    <div className="navbar-start"/>
                    <div className="navbar-end">
                        <div className="navbar-item has-dropdown is-hoverable">
                            <h3 className="navbar-link">{userName}</h3>

                            <div className="navbar-dropdown">
                                <div className="navbar-item">{loginLink}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }

    render() {
        return this.navbar();
    }
}
