import {apiCall, METHODS} from "../util/fetch-util";
import {getUserSessionData} from "../util/user-session-util";
import querystring from "querystring";

// to use when we implement inactive commhubs being shown on the page
export async function getAllCommhubs({ query= "", status= "", page= 0, limit= 999, org= "", deviceType = "commhub" } = {}) {
    const userToken = getUserSessionData();

    const searchParams = { query, status, page, limit, org, deviceType};
    const encodedSearchParams = querystring.encode(searchParams);

    const devices = await apiCall(`/devices?userId=${userToken.id}&${encodedSearchParams}`, METHODS.get, {throwOnError: true});

    return devices?.data;
}

export async function getActiveCommhubs() {
    return await apiCall("/live/activeCommhubs", METHODS.get, {throwOnError: true});
}

export async function getCommhubSettings() {
    return await apiCall("/live/activeCommhubConfig", METHODS.get, {throwOnError: true});
}

export async function getCommhubStatus() {
    return await apiCall("/live/activeCommhubStatus", METHODS.get, {throwOnError: true});
}

export async function refreshConfig(mac) {
    const body = {
        mac: mac
    };
    return await apiCall(`live/requestCommhubConfig`, METHODS.post, {body: body, throwOnError: true});
}

export async function refreshStatus(mac) {
    const body = {
        mac: mac
    };
    return await apiCall(`live/requestCommhubStatus`, METHODS.post, {body: body, throwOnError: true});
}

// Create function to prepare data for sending to the server
// type will be either "save" or "send" depending on whether the data is being sent to a device (in which case the keys have spaces) or being saved in the database (camelcase and no spaces)
const prepareData = (type, settings) => {
    if(settings){
        // Convert all the settings to the correct format
        settings.dataMode = settings.dateMode && settings.dataMode === "LoRa Only"? 0 : settings.dataMode === "LTE Only"? 1 : 2;
        settings.serverDestination = settings.serverDestination && settings.serverDestination === "Production"? 0 : 1;
        settings.loraMode = settings.loraMode && settings.loraMode === "Beacon"? 0 : 1;
        settings.otaServerSource = settings.otaServerSource && settings.otaServerSource === "Production"? 0 : 1;
        settings.ledBrightness = settings.ledBrightness && settings.ledBrightness === "Low"? 1 : settings.ledBrightness === "Medium"? 2 : 3;

        return {
            config: type === "save" ? settings:{
                "data mode": settings.dataMode,
                "ota enabled": settings.otaEnabled,
                "server destination": settings.serverDestination,
                "replace timestamps": settings.replaceTimestamps,
                "lora mode": settings.loraMode,
                "flooded msg broad": settings.floodedMsgBroad,
                "gps update interval": settings.gpsUpdateInterval,
                "real time physio interval": settings.realTimePhysioInterval,
                "physio logging interval": settings.physioLoggingInterval,
                "rout list interval": settings.routListInterval,
                "gps flood interval": settings.gpsFloodInterval,
                "real time gps interval": settings.realTimeGpsInterval,
                "gps logging interval": settings.gpsLoggingInterval,
                "real time physio enabled": settings.realTimePhysioEnabled,
                "physio logging enabled": settings.physioLoggingEnabled,
                "real time gps enabled": settings.realTimeGpsEnabled,
                "gps logging enabled": settings.gpsLoggingEnabled,
                "ota server source": settings.otaServerSource,
                "batt shutdown threshold": settings.battShutdownThreshold,
                "lte lora only enabled": settings.lteLoraOnlyEnabled,
                "lte mapping enabled": settings.lteMappingEnabled,
                "led enabled": settings.ledEnabled,
                "led brightness": settings.ledBrightness}
        };
    }
    alert("No settings were passed to prepareData function"); 
}

export async function sendConfig(mac, settings, receivingDevices) {
    const body = mac === "global" ? {...prepareData("send", settings), receivingDevices} : {...prepareData("send", settings), "receivingDevices": [mac]};
    return await apiCall(`live/setCommhubConfig`, METHODS.post, {body: body, throwOnError: true});
}

export async function saveConfig(settings){
    const body = prepareData("save", settings);
    return await apiCall(`live/saveGlobalConfig`, METHODS.post, {body: body, throwOnError: true});
}
