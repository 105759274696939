import { METHODS, apiCall } from "../util/fetch-util";

export async function pair(recruitId, deviceId) {
    const pairData = await apiCall("/pair", METHODS.post, { body: [{ deviceId, recruitId }], throwOnError: true });

    return pairData;
}

export async function pairMultiple(recruitId, deviceIds) {
    const pairs = deviceIds.map(v => ({ recruitId, deviceId: v}));
    const pairData = await apiCall("/pair", METHODS.post, { body: pairs, throwOnError: true });

    return pairData;
}

export async function forcePair(recruitId, deviceId) {
    const pairData = await apiCall("/forcePair", METHODS.post, { body: [{ deviceId, recruitId }], throwOnError: true });

    return pairData;
}


export async function unpair(deviceId) {
    const unpairData = await apiCall("/unpair", METHODS.post, { body: [{ deviceId }], throwOnError: true });

    return unpairData;
}

export async function unpairMultiple(deviceIds) {
    const unpairs = deviceIds.map(v => ({ deviceId: v }));
    const unpairData = await apiCall("/unpair", METHODS.post, { body: unpairs, throwOnError: true });

    return unpairData;
}
