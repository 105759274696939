import { useState, useEffect, useRef } from 'react';

import { isBrowser } from "react-device-detect";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import {
    getPageOfEvents,
} from '../../actions/event-actions';
import {
    getPageOfDevices,
} from '../../actions/device-actions';
import {
    forcePair,
    unpair,
} from '../../actions/pair-actions';

import {connectIOSocket} from "../../util/sails-socket";

import {
    joinLiveCache,
    registerNewDataListener
} from "../../actions/real-time-actions";

import RiskLevelIndicator from '../real-time/RiskLevelIndicator';
import PageSelection from '../misc/PageSelection';
import NotificationModal from '../misc/NotificationModal';
import ConfirmationModal from '../misc/ConfirmationModal';
import Button from '../misc/Button';

/*------------------------------------------------------------------------------------------------*/

function ErrorModal(props = {}) {
    const {
        msg = 'Test error text',
        onClose = () => {},
    } = props;

    return (
        <NotificationModal
            active={true}
            confirmationText={(
                <div>
                    <div className="title">
                        Error
                    </div>

                    <div className="block"></div>

                    <div className="notification has-text-weight-bold has-text-danger">
                        {msg}
                    </div>
                </div>
            )}
            confirmButtonAction={onClose}
            cancelButtonAction={onClose}
            closeButton={false}
        />
    );
}

function PairSwapModal(props = {}) {
    const {
        msg = 'Example modal text',
        onConfirm = () => {},
        onClose = () => {},
    } = props;

    return (
        <ConfirmationModal
            active={true}
            confirmationText={(
                <div>
                    <div className="title">
                        Confirm Pair Swap
                    </div>

                    <div className="block"></div>

                    <div>
                        {msg}
                    </div>
                </div>
            )}
            confirmButtonAction={() => {onClose(); onConfirm();}}
            cancelButtonAction={onClose}
            closeButton={false}
        />
    );
}

/*------------------------------------------------------------------------------------------------*/

/**
 * Register event socket to get realtime data.
 *
 * @param {Object} socketRef        Reference from useRef() to store the socket handle.
 * @param {Object} dataCallback     Callback for publishing received data. `(data) => {}`.
 * @param {Object} eventId          Event ID to register socket with.
 */
async function registerSocket(socketRef, dataCallback, eventId) {
    return connectIOSocket().then((socket_, _io) => {
        console.log("RT: Socket Connected");
        socketRef.current = socket_;
        return joinLiveCache(socket_, {eventId});
    }).then((msg) => {
        console.log("RT: Joining Live Cache:", msg);
        registerNewDataListener(socketRef.current, dataCallback);
    }).catch((err) => {
        console.error('RT: Failed to join live cache', err);
    });
}

/*------------------------------------------------------------------------------------------------*/

function MobileHeader(props = {}) {
    const {
        onClick = () => {},
    } = props;

    const header = isBrowser ? null : (
        <>
        <div className="hero is-info">
            <span
                className='hero is-info'
                style={{
                    float: 'left',
                    padding: '0 .5em 0 .5em',
                    border: '1px solid white',
                }}
                onClick={onClick}
            >
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    size='3x'
                    swapOpacity
                />
            </span>
        </div>
        </>
    );
    return (<>{header}</>);
}

/**
 * Component to standardize the display of a table/list of data.
 */
function RtsTable(props = {}) {
    const {
        idKey = 'id',
        selectedId = null,
        head = [],
        items = [],
        foot = [],
        nameKey = 'name',
        subtextKey = null,
        iconKey = null,
        onSelected = (_id) => {},
        disabled = false,
        controlsFunc = null,
        queryInProgress = false,
    } = props;

    let newItems = items;
    if ((items.length > 0) && (items[0]?.sortBy != null)) {
        newItems = items.sort((x, y) => (
            (x.sortBy === y.sortBy)
            ? 0
            : (x.sortBy < y.sortBy)
            ? -1
            : 1
        ));
    }

    const itemElems = newItems.filter((x) => !!x).map((x) => {
        const selected = (disabled)
            ? 'has-background-grey-lighter'
            : (selectedId && selectedId === x[idKey])
            ? 'is-selected'
            : '';
        const subtext = (!!subtextKey) ? (
            <span className="is-size-6"><em>{x[subtextKey]}</em></span>
        ) : null;
        const icon = (!!iconKey) ? x[iconKey] : null;
        const iconSpacing = (!!iconKey) ? (<b></b>) : null;
        const rightControls = (!!controlsFunc) ? <td>{controlsFunc(x)}</td> : null;
        return (
            <tr
                key={`ti-${x[idKey]}`}
                className={selected}
                onClick={() => (disabled) ? null : onSelected(x[idKey])}
                disabled={disabled}
            >
                <td>
                    {icon} {iconSpacing} <b className="is-size-5">{x[nameKey]}</b>
                    <div> {subtext} </div>
                </td>
                {rightControls}
            </tr>
        );
    });

    const headItems = head.length === 0 ? null : head.map((x, i) => (
        <tr key={`table-head-row-${i}`}>
            <td colSpan={(controlsFunc) ? 2 : 1}>{x}</td>
        </tr>
    ));
    const footItems = foot.length === 0 ? null : foot.map((x, i) => (
        <tr key={`table-foot-row-${i}`}>
            <td colSpan={(controlsFunc) ? 2 : 1}>{x}</td>
        </tr>
    ));
    const spinner = (<tr><td className="has-text-centered">
        <FontAwesomeIcon icon={faSpinner} spin />
    </td></tr>);

    return (
        <table className="table is-hoverable is-fullwidth">
            {headItems ? <thead>{headItems}</thead> : null}
            <tbody>{queryInProgress ? spinner : itemElems}</tbody>
            {footItems ? <tfoot>{footItems}</tfoot> : null}
        </table>
    );
}

/*------------------------------------------------------------------------------------------------*/

function RtsHotSwap(props = {}) {
    const {
        selectedRecruit = null,
        limit = 5,
    } = props;

    const [devices, setDevices] = useState([]);
    const [queryInProgress, setQueryInProgress] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [modal, setModal] = useState(null);
    const [queryTrigger, setQueryTrigger] = useState(0);
    const [filterSel, setFilterSel] = useState('');

    const onSearch = setSearch;

    const radioOpts = {
        any: 'Any',
        serial: 'Serial',
        mac: 'MAC',
    };

    const queryDevices = () => {
        setQueryInProgress(true);
        setDevices([]);
        const qfDict = {
            any: 'friendlyName,nativeDeviceId',
            serial: 'friendlyName',
            mac: 'nativeDeviceId',
        }
        const searchParams = {
            devicesPerPage: limit,
            includeMeta: true,
            query: search,
            queryFields: qfDict[filterSel],
            pairStatus: 'unpaired',
        };
        getPageOfDevices(page, searchParams).then((rc) => {
            console.debug('RES: Got page of devices', page, rc);
            setDevices(rc.data);
            setTotal(rc.metadata.total);
            setQueryInProgress(false);
        }).catch(() => {
            console.error('Failed to get devices');
        });
    };

    const createErrorModal = (msg) => {
        setModal(<ErrorModal msg={msg} onClose={() => setModal(null)}/>);
    }

    const handleClick = (id) => {
        onPairSwap(id);
    };

    /* Note: Only going to do pairing of new devices and not unpair */
    const onPairSwap = (deviceId) => {
        if (!selectedRecruit) {
            return;
        }
        const device = devices.find((r) => r.id === deviceId);
        const msg = (`Pair device ${device.friendlyName} to ${selectedRecruit?.recruitName}?`);
        setModal(<PairSwapModal
            msg={msg}
            onClose={() => setModal(null)}
            onConfirm={() => {
                forcePair(
                    selectedRecruit.recruitId,
                    deviceId,
                ).catch((err) => {
                    createErrorModal(`Failed to pair or unpair: ${err.message ?? err}`);
                }).finally(() => setQueryTrigger(Date.now()));
            }}
        />)
    };

    useEffect(() => {
        queryDevices();
    }, [search, page, queryTrigger, filterSel]);

    useEffect(() => {
        if (devices.length === 0) {
            queryDevices();
        }
    }, [selectedRecruit]);

    const createButton = (x) => (
        <Button activeColor="is-danger" classList={["is-small"]} onClick={() => handleClick(x.id)}>Swap</Button>
    );

    const createPairedTag = (x) => (
        (x.currentRecruit == null) ? null : <span className="tag is-primary is-small">Paired</span>
    )

    const header = selectedRecruit?.id == null ? 'Hot Swap' : `Hot Swap (${selectedRecruit?.name})`;

    const devicesUpdated = devices.map((x) => {
        const ret = {
            ...x,
            alt: (<>
                <div>{x.nativeDeviceId}</div>{createPairedTag(x)}
            </>),
        };
        return ret;
    })

    const pagesel = (<PageSelection
        page={page}
        limit={limit}
        itemTotal={total}
        onPageChange={setPage}
    />);

    const headItems = [
        (<input
            type='text'
            className='input is-small'
            value={search}
            placeholder='Filter Devices'
            onChange={(e) => onSearch(e.target.value)}
        />),
        (<div className='control has-text-centered has-text-weight-bold'>
            {
                Object.entries(radioOpts).map(([k, v]) => (
                    <label key={`filter-sel-${k}`} className='radio'>
                        <input
                            type='radio'
                            name='filtersel'
                            value={k}
                            checked={k === filterSel}
                            onChange={() => setFilterSel(k)}
                        />
                        {v}
                    </label>
                ))
            }
        </div>),
        pagesel,
    ];

    const footItems = [
        pagesel,
    ];

    return (<>
        <div className="mb-2">
            <p className="title">{header}</p>
        </div>

        <RtsTable
            items={devicesUpdated}
            disabled={!selectedRecruit?.id}
            controlsFunc={createButton}
            nameKey='friendlyName'
            subtextKey='alt'
            head={headItems}
            foot={footItems}
            queryInProgress={queryInProgress}
        />

        {modal}
    </>);
}

function RtsDevices(props = {}) {
    const {
        selectedEvent = null,
        devices = [],
        onDeviceSelect = (_rid) => {},
        selectedDevice = null,
        limit = 5,
    } = props;

    const radioOpts = {
        any: 'Any',
        name: 'Name',
        serial: 'Serial',
        mac: 'MAC',
    };

    const [page, setPage] = useState(0);
    const [search, onSearch] = useState('');
    const [filterSel, setFilterSel] = useState('');

    const reg = new RegExp(_.escapeRegExp(search), 'i');
    const useRecruits = ((!!selectedEvent) ? devices : []).filter((r) => {
        if (!search) {
            return true;
        }
        const opts = {
            name: r.recruitName,
            serial: r.rosterId,
            mac: r.nativeDeviceId,
        };
        return (
            (filterSel === 'any') ? Object.values(opts) : [opts[filterSel]]
        ).map((v) => (
            _.isNumber(v) ? `${v}` : v
        )).some((v) => (
            (v?.search?.(reg) ?? -1) > -1
        ));
    });
    const pageOfRecruits = _.slice(useRecruits, page * limit, (page * limit) + limit);

    if (page > Math.ceil(pageOfRecruits / limit)) {
        setPage(0);
    }
    if (!!search && devices.length === 0) {
        onSearch('');
    }

    const pagesel = (<PageSelection
        page={page}
        limit={limit}
        itemTotal={useRecruits.length}
        onPageChange={setPage}
    />);

    const headItems = [
        (<input
            type='text'
            className='input is-small'
            value={search}
            placeholder='Filter Recruits'
            onChange={(e) => onSearch(e.target.value)}
        />),
        (<div className='control has-text-centered has-text-weight-bold'>
            {
                Object.entries(radioOpts).map(([k, v]) => (
                    <label key={`filter-sel-${k}`} className='radio'>
                        <input
                            type='radio'
                            name='filtersel'
                            value={k}
                            checked={k === filterSel}
                            onChange={() => setFilterSel(k)}
                        />
                        {v}
                    </label>
                ))
            }
        </div>),
        pagesel,
    ];
    const footItems = [ pagesel ];

    return (<>
        <div className="mb-2">
            <p className="title">Select a Recruit</p>
        </div>

        <RtsTable
            items={pageOfRecruits}
            subtextKey='subtext'
            iconKey='alt'
            selectedId={selectedDevice}
            onSelected={onDeviceSelect}
            head={headItems}
            foot={footItems}
        />
    </>);
}

function RtsEvents(props = {}) {
    const {
        eventSelected = (_eid, _eobj) => {},
        selectedEvent = null,
        limit = 5,
    } = props;

    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');

    const onEventSelected = (eid) => {
        const obj = (events.find((e) => e.id === eid))
        eventSelected(obj?.id, obj)
    }

    useEffect(() => {
        const curTime = (new Date()).toISOString();
        getPageOfEvents(page, {
            eventsPerPage: limit,
            includeMeta: true,
            sortKey: 'estimatedStartTime',
            startTime: curTime,
            endTime: curTime,
            query: search,
        }).then((ret) => {
            setTotal(ret.metadata.total);
            setEvents(ret.data);
        }).catch(() => {
            console.error('Failed to get events');
        });
    }, [page, search]);

    const pagesel = (<PageSelection
        page={page}
        limit={limit}
        itemTotal={total}
        onPageChange={setPage}
    />);

    const headItems = [
        (<input
            type='text'
            className='input is-small'
            value={search}
            placeholder='Filter Events'
            onChange={(e) => setSearch(e.target.value)}
        />),
        pagesel,
    ];
    const footItems = [ pagesel ];

    return (<>
        <div className="mb-2">
            <p className="title">Select an Event</p>
        </div>

        <RtsTable
            items={events.map((x) => ({...x, alt: `${x.recruits.length} Recruits`}))}
            selectedId={selectedEvent}
            onSelected={onEventSelected}
            subtextKey="alt"
            head={headItems}
            foot={footItems}
        />
    </>);
}

export default function RtsEventSelection(props = {}) {
    const socket = useRef(null);

    const [selectedEvent, setSelectedEvent] = useState(null);
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [dataPl, setDataPl] = useState({});
    const [plSum, setPlSum] = useState('');
    const [selectedRecruit, setSelectedRecruit] = useState(null);

    const deviceSelected = (deviceId) => {
        const item = devices.find((v) => v.id === deviceId);
        console.debug('Device selection', deviceId, item);
        setSelectedDevice(item);
    };

    const socketDisconnect = () => {
        if (!!socket.current) {
            if (socket.current?.isConnected?.()) {
                socket.current.disconnect();
            }
            socket.current = null;
        }
    };

    const mobileBackAction = () => {
        if (selectedDevice) {
            setSelectedDevice(null);
            setSelectedRecruit(null);
        }
        else if (selectedEvent) {
            setSelectedEvent(null);
        }
    }

    const takeoverBackButton = () => {
        if (window.history.state != null) {
            window.history.pushState(null, '', window.location.href);
        }
        window.onpopstate = mobileBackAction;
    };
    const restoreBackButton = () => {
        if (window.history.state == null) {
            window.onpopstate = undefined;
            window.history.back();
        }
    };

    console.debug('Window state', window.history.state);

    /*----------------------------------------*/

    /* Upon mount and unmount */
    useEffect(() => {
        return () => {
            restoreBackButton();
            socketDisconnect();
        };
    }, []);

    /* After user selects an event */
    useEffect(() => {
        {
            console.debug('Resetting devices and selected items');
            setDataPl({});
            setDevices([]);
            setSelectedDevice(null);
            setSelectedRecruit(null);
        }
        socketDisconnect();
        if (!!selectedEvent) {
            registerSocket(socket, setDataPl, selectedEvent.id).catch((err) => {
                console.error('Failed to register socket', err);
            });
        }
    }, [selectedEvent?.id]);

    /* After a payload is received */
    useEffect(() => {
        setPlSum(JSON.stringify(dataPl));
    }, [dataPl])

    /* If the payload actually changed. */
    useEffect(() => {
        console.debug('RT: New payload', plSum);
        const ret = Object.entries(dataPl?.devices?.byId ?? {}).map(([k, v]) => {
            const recruit = Object.values(dataPl?.recruits ?? {}).find((r) => (
                r.devices.map((d) => d.deviceId).includes(k)
            ));
            const recruitName = recruit?.recruit?.recruitName;
            const subtext = (<>
                <div><span className="has-text-weight-bold">Serial #</span>: {v.rosterId}</div>
                <div><span className="has-text-weight-bold">MAC</span>: {v.macPretty}</div>
                <div><span className="has-text-weight-bold">TIME</span>: {v.lastDataTimestamp ? new Date(v.lastDataTimestamp)?.toISOString() : '-'}</div>
            </>)
            return {
                id: k,
                sortBy: recruitName == null ? v.rosterId : recruitName,
                name: recruitName ?? v.rosterId,
                rosterId: v.rosterId,
                recruitId: recruit?.recruit?.recruitId,
                recruitName: recruitName,
                nativeDeviceId: v.macPretty,
                subtext,
                alt: <RiskLevelIndicator riskLevel={v.geoJSON?.properties?.risk} />,
            };
        })
        console.debug('RT: Setting devices', ret);
        setDevices(ret);
    }, [plSum])

    useEffect(() => {
        if ([selectedEvent, selectedDevice, selectedRecruit].every((v) => v == null)) {
            restoreBackButton();
        }
        else {
            takeoverBackButton();
        }
    }, [selectedEvent, selectedDevice, selectedRecruit]);

    /*----------------------------------------*/

    const eventView = (
        <RtsEvents
            eventSelected={(_eid, eobj) => setSelectedEvent(eobj)}
            selectedEvent={selectedEvent?.id}
        />
    )
    const deviceView = (
        <RtsDevices
            devices={devices}
            selectedEvent={selectedEvent?.id}
            selectedDevice={selectedDevice?.id}
            onDeviceSelect={deviceSelected}
        />
    )
    const hotswapView = (
        <RtsHotSwap
            selectedRecruit={selectedDevice}
        />
    )

    const curView = (selectedEvent == null) ? 'event'
            : (selectedDevice == null) ? 'device' : 'hotswap';

    const view = (isBrowser)
        ? (<>
            <div className="block" />

            <div className="container">
                <div className="columns">
                    <div className="column">{eventView}</div>
                    <div className="column">{deviceView}</div>
                    <div className="column">{hotswapView}</div>
                </div>
            </div>
        </>)
        : (<>
            <div hidden={curView !== 'event'}>{eventView}</div>
            <div hidden={curView !== 'device'}>{deviceView}</div>
            <div hidden={curView !== 'hotswap'}>{hotswapView}</div>
        </>);

    return (<>
        <MobileHeader
            onClick={mobileBackAction}
        />
        {view}
    </>);
}
