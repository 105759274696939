import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import MapboxMap from "./MapboxMap";
import RealTimeSidebar from "./RealTimeSidebar";
import RiskLevelIndicator from "./RiskLevelIndicator";
import SquadSelector from "./SquadSelector";
import EventSelector from "./EventSelector";
import DeviceTypeSelector from "./DeviceTypeSelector";

import { mapDataInitialization, mapSetSource } from './map-utils';
import {
    updateDeviceData,
    transformDeviceData,
    transformDeviceDataGeoJSON,
    sanitizeIds,
    checkLastReceivedTimestamp,
} from './data-utils';

import {connectIOSocket} from "../../util/sails-socket";
import {getUser} from "../../actions/user-actions";

import {
    getTrackedDevices,
    joinLiveCache,
    joinLiveTracking,
    registerNewDataListener,
    registerLiveTrackingDataListener,
    getLiveCache,
} from "../../actions/real-time-actions";
import {
    getDevicesByEvent,
    getRecruitsDevices,
    getDeviceTypes,
} from "../../actions/device-actions";

import {getLastArrayValue} from "../../util/format-util";
import {
    NO_DEVICE_SELECTED,
    RISK_LEVEL_COLORS,
    SELECTED_DEVICE_ZOOM_LEVEL,
    riskToColor,
    DEFAULT_SHOW_DEVICE_TYPES
} from "./real-time-constants";
import {createLineString} from "../../util/geojson-util";
import {getUserSessionData} from "../../util/user-session-util";
import {faCalendarAlt, faMobileAlt, faUsers} from "@fortawesome/free-solid-svg-icons";

async function sleep(time) {
    return new Promise((resolve, _) => {
        setTimeout(() => resolve(), time);
    })
}

/*------------------------------------------------------------------------------------------------*/

async function registerSocket(socketRef, dataCallback, {eventId, onDisconnect, timeout} = {}) {
    console.info('registerSocket timeout', timeout)
    return connectIOSocket({ timeout }).then((socket_, _io) => {
        console.log("RT: Socket Connected");
        socketRef.current = socket_;
        return joinLiveCache(socket_, {eventId});
    }).then((msg) => {
        console.log("RT: Joining Live Cache:", msg);
        return registerNewDataListener(socketRef.current, dataCallback);
    }).then((ret) => {
        socketRef.current.on('disconnect', () => onDisconnect());
        return ret;
    }).catch(err => {
        console.error('RT:', err);
        throw err;
    });
}

async function registerTrackingSocket(socketRef, deviceId, dataCallback, { onDisconnect, timeout } = {}) {
    console.info('registerTrackingSocket timeout', timeout)
    return connectIOSocket({ timeout }).then((socket_, _io) => {
        console.log("RT: Tracking Socket Connected");
        socketRef.current = socket_;
        return joinLiveTracking(socket_, deviceId);
    }).then((msg, jwRes) => {
        console.log("RT: Joining Live Tracking:", msg, jwRes);
        return registerLiveTrackingDataListener(socketRef.current, dataCallback);
    }).then((ret) => {
        socketRef.current.on('disconnect', () => onDisconnect());
        return ret;
    }).catch(err => {
        console.error('RT:', err);
        throw err;
    });
}

/*------------------------------------------------------------------------------------------------*/

export default function RealTime(props = {}) {
    const socket = useRef(null);
    const socketTracking = useRef(null);
    const data = useRef({devices: {allIds: [], byId: {}}});
    const idRef = useRef({});
    const allowReconnect = useRef(true);
    const history = useHistory();

    const [dataStr, setDataStr] = useState("{devices: {allIds: [], byId: {}}}");
    const [map, setMap] = useState(null);
    const [rawDeviceIds, setRawDeviceIds] = useState({});
    const [deviceIds, setDeviceIds] = useState([]);
    const [devicesAlt, setDevicesAlt] = useState({});
    const [allDeviceIds, setAllDeviceIds] = useState({});
    const [devicesAltStr, setDevicesAltStr] = useState("{}");
    const [devices, setDevices] = useState([]);
    const [tmpDevices, setTmpDevices] = useState([]);
    const [activeDeviceLocations, setActiveDeviceLocations] = useState([]);
    const [routeLine, setRouteLine] = useState([[0, 0]]);
    const [celsius, setCelsius] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(NO_DEVICE_SELECTED);
    const [menuCollapsed, setMenuCollapsed] = useState(true);
    const [idQueryTrigger, setIdQueryTrigger] = useState(0);
    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState(undefined);
    const [currentEvent, setCurrentEvent] = useState(null);
    const [eventDevices, setEventDevices] = useState(null);
    const [eventRecruits, setEventRecruits] = useState({paired: {}, unpaired :[]});
    const [dataPl, setDataPl] = useState({devices: {allIds: [], byId: {}}});
    const [squadSelect, setSquadSelect] = useState(false);
    const [squad, setSquad] = useState([]);
    const [squadStr, setSquadStr] = useState('[]');
    const [savedSquad, setSavedSquad] = useState(null);
    const [checkLastReceivedTrigger, setCheckLastReceivedTrigger] = useState(0);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [selectedDeviceTypes, setSelectedDeviceTypes] = useState([]);
    const [sdtStr, setSdtStr] = useState('[]');

    const marine = getUserSessionData().firstName === "marine";

    const handleMapOnLoad = ((map) => {
        mapDataInitialization(map, marine);
        mapSetSource(map, 'activeDeviceLocations', activeDeviceLocations);
        setMap(map);
    });

    const handleMenuCollapse = ((e, v) => {
        if (e.preventDefault) {
            e.preventDefault();
        }
        setMenuCollapsed(v);
    });

    const handleDeviceSelected = ((_data, id) => {
        console.debug(`RT: Selected device ${id}`, _data);
        if (!_data.isPoi) {
            setSelectedDevice(id);
        }
        const latlonArray = data.current?.devices?.byId?.[id]?.data?.latlon;
        console.debug('RT: Selected device latlon', latlonArray);
        if (latlonArray && map) {
            const lastPosition = getLastArrayValue(latlonArray);
            if (lastPosition) {
                const coords = [lastPosition[1], lastPosition[0]];
                console.debug('RT:   flying to ', coords);
                map.flyTo( {
                    center: coords,
                    zoom: SELECTED_DEVICE_ZOOM_LEVEL,
                });
            }
        }
        console.debug(`RT: menu state ${menuCollapsed ? "collapsed" : "expanded"}`)
        if (menuCollapsed) {
            setMenuCollapsed(false);
        }
    });

    const handleDeviceUnselected = (() => {
        setSelectedDevice(NO_DEVICE_SELECTED);
    });

    const handleSelectTemperatureMeasurement = (() => {
        setCelsius(!celsius);
    });

    const connectSocket = async (tracking, {callback, eventId, timeout} = {}) => {
        const sock = (tracking) ? socketTracking : socket;
        const onDisconnect = () => {
            console.warn('Socket onDisconnect called!');
            if (sock.current?.close) {
                console.info('Close flag detected');
                sock.current.closed = true;
                return;
            }
            if (allowReconnect.current) {
                setTimeout(() => {
                    console.info('Attempting to reconnect to detached socket...');
                    connectSocket(tracking, {callback, eventId, timeout: 5000}).then(() => {
                        console.info('Reconnected to socket');
                    }).catch(err => {
                        console.info('Reconnect failed, trying in another 5 sec...');
                        onDisconnect();
                    })
                }, [5000]);
            }
        };
        if (tracking) {
            const tmpRefObj = {};
            return registerTrackingSocket(
                tmpRefObj,
                selectedDevice,
                callback,
                { onDisconnect, timeout }
            ).then(() => {
                socketTracking.current = {
                    sock: tmpRefObj.current,
                    onDisconnect,
                    close: false,
                }
            });
        }
        else {
            if (eventId == null) {
                getLiveCache(1).then(data => {
                    setDataPl(data);
                }).catch(error => console.error(error));
            }
            const tmpRefObj = {};
            return registerSocket(
                tmpRefObj,
                setDataPl,
                {eventId, onDisconnect, timeout}
            ).then(() => {
                socket.current = {
                    sock: tmpRefObj.current,
                    onDisconnect,
                    close: false,
                    closed: false,
                }
            });
        }
    };

    const disconnectSocket = async (tracking) => {
        const sock = (tracking) ? socketTracking : socket;
        console.info('Disconnect called: Socket status', sock.current);
        if ((sock.current != null) && (sock.current.sock.isConnected())) {
            console.info('Forcing socket disconnection');
            sock.current.close = true;
            sock.current.sock.off('disconnect', sock.current.onDisconnect);
            sock.current.sock.disconnect();
            while (sock.current.closed === false) {
                sleep(100);
            }
            sock.current = null;
            console.info('Done closing socket')
        }
    };

    /*----------------------------------------*/
    useEffect(() => {
        connectSocket(false);

        getLiveCache(1).then(data => {
            setDataPl(data);
        }).catch(error => console.error(error));

        getDeviceTypes().then((data) => {
            setDeviceTypes(data);
            if (selectedDeviceTypes.length === 0) {
                setSelectedDeviceTypes(data.filter(d => DEFAULT_SHOW_DEVICE_TYPES.includes(d)));
            }
        }).catch((err) => {
            console.error('Failed to get device types:', err);
        });

        return () => {
            allowReconnect.current = false;
            return Promise.all([
                disconnectSocket(false),
                disconnectSocket(true),
            ]);
        }
    }, []);

    useEffect(() => {
        if (currentEvent?.id != event?.id) {
            setSquad([]);
            setSavedSquad(null);
            setSelectedDevice(NO_DEVICE_SELECTED);
            disconnectSocket(false).then(() => {
                return Promise.all([
                    connectSocket(false, {eventId: event?.id}),
                    (event?.id != null) ? getDevicesByEvent(event.id) : null,
                    ((event?.id != null) && event.recruits) ? getRecruitsDevices(event.id) : null,
                ]);
            }).then(([sock, devs, recs]) => {
                setCurrentEvent(event);
                setEventDevices(devs);
                setEventRecruits((recs == null) ? {paired: {}, unpaired :[]} : recs);
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [event?.id]);

    // BEGIN - Convert data objects/lists to strings for use in useEffect()
    useEffect(() => {
        const updated = updateDeviceData(dataPl, data, allDeviceIds);
        data.current = updated;
        setDataStr(JSON.stringify(updated));
    }, [dataPl]);

    useEffect(() =>  setSquadStr(JSON.stringify(squad)) , [squad])

    useEffect(() => setSdtStr(JSON.stringify(selectedDeviceTypes)), [selectedDeviceTypes]);
    // END

    // Interval for updating data when iosocket data is not being received
    useEffect(() => {
        setTimeout(()=> { setCheckLastReceivedTrigger(Date.now()) }, 5500);
    }, [checkLastReceivedTrigger]);

    useEffect(() => {
        const checkLastReceivedSamples = checkLastReceivedTimestamp(tmpDevices);
        setDevices(checkLastReceivedSamples);
    }, [tmpDevices, checkLastReceivedTrigger]);

    // Periodically query active devices
    useEffect(() => {
        const user = getUser();
        getTrackedDevices(user.id, {outputVersion: '2'}).then((rawDeviceIds) => {
            const rawKeys = Object.keys(rawDeviceIds);
            let tmpDeviceIds = Object.entries(rawDeviceIds);
            [].concat(eventDevices ?? [], squad).forEach((x) => {
                if (!rawKeys.includes(x.nativeDeviceId)) {
                    tmpDeviceIds.push([ x.nativeDeviceId, {
                        status: 'missing',
                        deviceType: x.deviceTypeSlug
                    }]);
                }
            })
            tmpDeviceIds = tmpDeviceIds.filter(([_, v]) => (
                selectedDeviceTypes.includes(v.deviceType)
            )).map(([k, v]) => (
                [k, v.status]
            ));
            tmpDeviceIds = Object.fromEntries(tmpDeviceIds);
            setRawDeviceIds(tmpDeviceIds);
        }).catch((err) => {
            console.error('Failed to get device statuses', err.message);
        }).finally(() => {
            setTimeout(() => setIdQueryTrigger(Date.now()), 3000)
        });
    }, [idQueryTrigger]);

    // BEGIN - Data processing routines
    useEffect(() => {
        setAllDeviceIds(sanitizeIds(rawDeviceIds));
        let tmp = JSON.parse(JSON.stringify(rawDeviceIds ?? {}));
        const deviceFilterF = (a, b) => (Object.fromEntries(
            Object.entries(a).filter((x) => (b.map((y) => (y.nativeDeviceId)).includes(x[0])))
        ))
        /* If event is selected, filter down to only devices associated */
        if (eventDevices != null) {
            tmp = deviceFilterF(tmp, eventDevices);
        }
        /* Filter IDs if a squad exists AND we're not currently selecting one */
        if (!squadSelect && squad.length > 0) {
            tmp = deviceFilterF(tmp, squad);
        }
        tmp = sanitizeIds(tmp);
        const formattedDeviceIds = Object.keys(tmp);
        idRef.current = tmp;
        setDevicesAlt(tmp);
        setDeviceIds(formattedDeviceIds);
        setDevicesAltStr(Object.entries(tmp).map(([k, v]) => `${k}-${v}`).join('_'));
    }, [rawDeviceIds, squadSelect]);

    useEffect(() => {
        if (!data.current) {
            return;
        }
        const deviceData = transformDeviceData(data.current, devicesAlt);
        setTmpDevices(deviceData);
        setActiveDeviceLocations(transformDeviceDataGeoJSON(
            data.current,
            devicesAlt,
            selectedDevice
        ));
    }, [dataStr, devicesAltStr, squadStr, selectedDevice]);
    // END

    // BEGIN - Update map sources
    useEffect(() => {
        if (!!map) {
            mapSetSource(map, 'activeDeviceLocations', activeDeviceLocations);
        }
    }, [activeDeviceLocations]);

    useEffect(() => {
        if (!!map) {
            console.debug('New routeLine', routeLine);
            mapSetSource(map, 'route', routeLine);
        }
    }, [routeLine]);
    // END

    // RouteLine: When device is (de)selected
    useEffect(() => {
        if (selectedDevice === NO_DEVICE_SELECTED) {
            disconnectSocket(true).then(() => {
                setRouteLine(createLineString([[0, 0]]));
            });
        }
        else {
            disconnectSocket(true).then(() => {
                return connectSocket(true, {callback: (d) => {
                    setRouteLine(d);
                }});
            });
        }
    }, [selectedDevice]);

    /*----------------------------------------*/

    const sideBarDataProcessing = marine ? {
        marineRisk: (v) => {
            return <RiskLevelIndicator riskLevel={v[1] ?? "X"}
                                       riskColor={v[0] ?? 0}/>;
        }
    } : {
        risk: (v, d) => {
            return <RiskLevelIndicator riskLevel={v ?? "X"}
                riskColor={riskToColor(v, {
                    isInactive: (devicesAlt[d.id] !== 'active'),
                    deviceType: d.deviceType
                })}
            />;
        }
    }

    const optionsData = [
        {
            title: 'Event Selector',
            component: <EventSelector
                events={events}
                onEventsUpdate={setEvents}
                selectedEvent={event}
                onEventSelected={setEvent}
            />,
            summary: ['Event', `${(event) ? event.name : "None Selected"}`],
            icon: faCalendarAlt,
        },
        {
            title: 'Squad Selector',
            component: <SquadSelector
                allDevices={devices.filter((v) => !v.isPoi)}
                squad={squad}
                onSquad={setSquad}
                onSquadSelectChange={setSquadSelect}
                savedSquad={savedSquad}
                onSavedSquad={setSavedSquad}
                event={event}
                idKey={'id'}
                nameKey={'rosterId'}
                numCols={'6'}
            />,
            summary: ['Squad', `${(squad.length === 0) ? 'N/A' : `${squad.length} Recruits`}`],
            icon: faUsers,
        },
        {
            title: 'Device Type Filter',
            component: <DeviceTypeSelector
                deviceTypeList={deviceTypes}
                selectedDeviceTypeList={selectedDeviceTypes}
                onSelectedDeviceTypeListChanged={setSelectedDeviceTypes}
            />,
            summary: ['Device Types', `${selectedDeviceTypes.join(', ')}`],
            icon: faMobileAlt,
        },
    ];

    return (
        <div>
            <div className="is-relative">
                <MapboxMap fullscreen={true}
                           deviceData={devices}
                           selectedDevice={selectedDevice}
                           menuCollapsed={menuCollapsed}
                           handleMapInitialize={map => setMap(map)}
                           handleMapOnLoad={handleMapOnLoad}
                           menuCollapsedCallback={handleMenuCollapse}
                           selectDeviceCallback={handleDeviceSelected}
                           unselectDeviceCallback={handleDeviceUnselected}/>
                <RealTimeSidebar
                    isMarine={marine}
                    deviceData={devices.filter((v) => !v.isPoi)}
                    devicesAlt={devicesAlt}
                    map={map}
                    celsius={celsius}
                    selectedDevice={selectedDevice}
                    menuCollapsed={menuCollapsed}
                    dataProcessing={sideBarDataProcessing}
                    selectDeviceCallback={handleDeviceSelected}
                    unselectDeviceCallback={handleDeviceUnselected}
                    menuCollapsedCallback={handleMenuCollapse}
                    handleSelectTemperatureMeasurementCallBack={handleSelectTemperatureMeasurement}
                    recruits={eventRecruits}
                    optionsData={optionsData}
                />
            </div>
        </div>
    );
}
