import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";

import Button from './Button';

export const ASCENDING = "▲";
export const DECENDING = "▼";

const defaultHeaders = [
    { name: 'Header 1', sortKey: 'headerOne' },
    { name: 'Header 2', sortKey: 'headerTwo' },
    { name: 'Header 3', sortKey: 'headerThree' },
];

const defaultData = [
    ['Data 1', 'Data 2', 'Data 3'],
    [<span>Data 1</span>, <span>Data 2</span>, <span>Data 3</span>],
];


function getPageMax(total, limit) {
    if (!((total > 0) && (limit > 0))) {
        return 0;
    }
    return Math.ceil(total / limit);
}

export default function ApiSortableTable(props) {
    const headers = props.headers || defaultHeaders;
    const data = props.data || defaultData;
    const limit = props.limit || 15;
    const total = props.total || 0;
    const fullwidth = props.fullwidth || true;
    const striped = props.striped || true;
    const onSortChange = props.onSortChange || ((_key, _dir) => {});
    const onPageChange = props.onPageChange || ((_page, _limit) => {});

    const [sortKey, setSortKey] = useState();
    const [sortDir, setSortDir] = useState(-1);
    const [page, setPage] = useState(0);

    const pageMax = getPageMax(total, limit);

    const navChange = (dir) => {
        if ((dir === 'next') && ((page + 1) < pageMax)) {
            setPage(page + 1);
        }
        else if (dir === 'prev' && (page > 0)) {
            setPage(page - 1);
        }
        else if (dir === 'first' && (page > 0)) {
            setPage(0);
        }
        else if (dir === 'last' && (pageMax !== 0) && ((page + 1) !== pageMax)) {
            setPage(pageMax - 1);
        }
    };

    const handleSortChange = (key) => {
        if (!key) {
            return;
        }
        if (sortKey === key) {
            if (sortDir === -1) {
                setSortKey(null);
                setSortDir(1);
            }
            else {
                setSortDir(-1)
            }
            return;
        }
        setSortKey(key);
    };

    useEffect(() => {
        onSortChange(sortKey, sortDir);
    }, [sortKey, sortDir]);

    useEffect(() => {
        onPageChange(page, limit);
    }, [page, limit]);

    console.debug(`Sort info... ${sortKey} ${sortDir}`);

    const colHeaders = headers.map((v, vi) => {
        const hasSort = (!!sortKey && (v.sortKey === sortKey));
        const ind = (hasSort)
            ? ((sortDir > 0) ? ASCENDING : DECENDING)
            : null;
        return (<th key={`tbl-header-col-${vi}`} onClick={() => handleSortChange(v.sortKey)}>
            {v.name}{ind}
        </th>);
    });

    const rows = data.map((v, i) => {
        const cells = v.map((w, j) => (<td key={`${i}-${j}`}>{w}</td>))
        return (<tr key={`${i}`}>{cells}</tr>)
    });

    const buttonsMeta = [
        {name: 'first', icon: faAngleDoubleLeft},
        {name: 'prev', icon: faAngleLeft},
        {name: 'next', icon: faAngleRight},
        {name: 'last', icon: faAngleDoubleRight},
    ];
    const buttons = buttonsMeta.map((v) => (
        <Button
            classList={["pagination-link"]}
            icon={v.icon}
            key={`button-${v.name}`}
            onClick={() => navChange(v.name)}
        />
    ));
    const pageIndicator = (
        <span className="has-text-weight-bold is-italic">
            Page {page + 1} of {pageMax === 0 ? '???' : pageMax}
        </span>
    );

    const classes = [
        "table",
        ...(fullwidth ? ['is-fullwidth'] : []),
        ...(striped ? ['is-striped'] : []),
    ];

    return (<>
        <div className="table-container">
            <table className={classes.join(' ')}>

                <thead><tr>{colHeaders}</tr></thead>

                <tbody>{rows}</tbody>

            </table>
            <nav className="pagination is-right" role="navigation">
                <div className="pagination-next">
                    {pageIndicator}
                </div>
                <div className="pagination-list">
                    {buttons}
                </div>
            </nav>
        </div>
    </>)
}
