import React, { useState } from "react";
import { requestLogfileDownload } from "../../actions/logfile-actions";
import { FeatherSendSvg, FeatherFourDotsSvg, FeatherMailSvg } from "./svgs.jsx";

function ActionBar(props = {}) {
  const [isPosting, setIsPosting] = useState(false);
  const handleEmailChange = (event) => {
    props.onEmailChange(event.target.value);
  };

  const marginInlineStart = {
    marginInlineStart: "1em",
  };

  const actionOncompleted = () => {
    props.onCompleted();
  };

  async function handleDownload() {
    if (localStorage.length > 0) {
      let logfiles = [];
      for (const [key, value] of Object.entries(localStorage)) {
        let valueObj = JSON.parse(value);
        logfiles.push({
          ...valueObj.logfile,
          ...valueObj.device,
        });
        setIsPosting(true);
      }
      try {
        await requestLogfileDownload(logfiles, props.email());
        setTimeout(() => {
          console.log("reached timeout");
        }, 1500);
        setTimeout(() => {
          actionOncompleted();
        }, 1000);
      } catch (error) {
        console.error(error);
      } finally {
        setIsPosting(false);
      }
    } else {
      alert("No logfiles selected");
    }
  }

  return (
    <div className="logfile-action-bar">
      <div className="action-bar-wrapper is-active">
        <div className="action-bar-wrapper-inner">
          <div className="dashboard-action-bar">
            <div className="action-bar-start">
              <div className="field">
                <div className="control has-icon">
                  <input
                    onChange={handleEmailChange}
                    data-selector="static-results"
                    type="email"
                    className="input search-field"
                    placeholder="Email"
                    value={props.email()}
                  />
                  <div className="form-icon">{FeatherMailSvg}</div>
                  <div
                    id="static-results"
                    className="search-results has-slimscroll"
                  ></div>
                </div>
              </div>
            </div>
            <div className="action-bar-end">
              <button
                className="button icon-button is-secondary raised"
                onClick={() => {
                  handleDownload();
                }}
                style={marginInlineStart}
                disabled={isPosting}
              >
                <span className="">{FeatherSendSvg}</span>
              </button>
              <button
                className="action-bar-item panel-trigger mask mask-blob"
                onClick={() => {
                  props.onDataFromChild();
                }}
              >
                {FeatherFourDotsSvg}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActionBar;
