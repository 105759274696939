import React from "react";

import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import ConfigurationHeader from "../ConfigurationHeader";
import ApiSortableTable from "../../misc/ApiSortableTable";
import Button from "../../misc/Button";
import SearchFilter from "../SearchFilter";

import { getPageOfDevices, deactivateDevice, activateDevice } from "../../../actions/device-actions";

import { getUserSessionData } from "../../../util/user-session-util";

import PathList from "../../../util/path-list";

export default class DeviceConfiguration extends React.Component {
    static defaultProps = {
        headers: [
            { name: "Type", sortKey: "deviceType" },
            { name: "Org.", sortKey: "org", title: "Organization" },
            { name: "MAC Address", sortKey: "nativeDeviceId" },
            { name: "Serial #", sortKey: "sortableName", title: "Serial Number" },
            { name: "Status" },
            { name: "State", sortKey: "isDeleted" },
            { name: "Actions" }
        ]
    }

    constructor(props) {
        super(props);

        this.state = {
            devices: [],
            total: 0,
            showInactiveDevices: false,
            searchParams: {
                returnDeviceMap: false,
                query: "",
                status: "",
                page: 0,
                limit: 20,
                org: "",
                deleted: "false",
                sortKey: "",
                sortValue: "",
            },
            searchConfig: [
                {type: 'radio', key: 'queryFields', options: [
                    {label: 'By Serial #', value: '', default: true},
                    {label: 'By MAC', value: 'nativeDeviceId'},
                ]},
                {
                    type: 'select',
                    key: 'pairStatus',
                    label: 'Pair Status',
                    labelValue: '',
                    options: [
                        { text: 'Paired', value: 'paired' },
                        { text: 'Not Paired', value: 'unpaired' }
                    ],
                },
            ],
        };

        this.updateDeviceList = this.updateDeviceList.bind(this);
        this.handleDeactivate = this.handleDeactivate.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
        this.transformDeviceListForTable = this.transformDeviceListForTable.bind(this);
        this.toggleInactiveDeviceFilter = this.toggleInactiveDeviceFilter.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    updateDeviceList() {
        const searchParams = {
            ...this.state.searchParams,
            devicesPerPage: this.state.searchParams.limit,
            includeMeta: true,
        };
        getPageOfDevices(searchParams.page, searchParams).then((p) => {
            const data = p.data;
            const metadata = p.metadata;
            const devices = data || [];
            const total = metadata.total || 0;
            console.log("GOT ALL DEVICES:", devices);
            this.setState({ devices, total });
        }).catch((err) => console.error(err.message));
    }

    componentDidMount() {
      const userToken = getUserSessionData()
      if (!userToken) {
              window.location.href = PathList.login;
              return;
          }
        this.updateDeviceList()
    }

    handleDeactivate(deviceId) {
        deactivateDevice(deviceId).then(() => (
            this.updateDeviceList()
        )).catch((err) => (
            console.error('Failed to deactivate:', err)
        ));
    }

    handleActivate(deviceId) {
        activateDevice(deviceId).then(() => (
            this.updateDeviceList()
        )).catch((err) => (
            console.error('Failed to activate:', err)
        ));
    }

    transformDeviceListForTable(deviceList) {
        return deviceList?.map(v => {
            const editButton = (
                <Button
                    type='link'
                    path={PathList.deviceUpdate(v.id)}
                    classList={['mx-1']}
                >
                    Edit
                </Button>
            );
            const statusUpdateButton = (
                <Button
                    activeColor={v?.isDeleted ? 'is-success' : 'is-danger'}
                    classList={['is-light', 'mx-1']}
                    onClick={() => v?.isDeleted ? this.handleActivate(v.id) : this.handleDeactivate(v.id)}
                >
                {v?.isDeleted ? "Activate" : "Deactivate"}
                </Button>
            );

            return [
                v?.deviceTypeSlug?.toUpperCase(),
                v?.org,
                v?.nativeDeviceId,
                v?.friendlyName,
                v?.currentRecruit ? "Paired" : "Not Paired",
                v?.isDeleted ? "Deactivated" : "Activated",
                <div>{editButton}{statusUpdateButton}</div>
            ];
        });
    }

    toggleInactiveDeviceFilter(e) {
        e.preventDefault();
        const showInactiveDevices = !this.state.showInactiveDevices;
        this.setState({
            showInactiveDevices,
            searchParams: {
                ...this.state.searchParams,
                deleted: (showInactiveDevices) ? '' : 'false',
            },
        }, () => this.updateDeviceList());
    }

    handleOnChange(e) {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                org: e.target.value,
            },
        }, () => this.updateDeviceList());
    }

    handleSearchChange(data) {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...data,
            }
        }, () => {
            console.debug('DC: New search params', this.state.searchParams)
            this.updateDeviceList()
        })
    }

    render() {
        const devices = this.transformDeviceListForTable(this.state.devices);

        return (
            <div>
                <ConfigurationHeader selectedButton={"Devices"} previousPath={PathList.logfiles} nextPath={PathList.commHubs} currentPath="Devices" />

                <div className="block"></div>

                <div className="container">
                    <div className="level">
                        <div className="level-item level-right">
                            <div className="field is-grouped">
                                <div className="control">
                                    <Button
                                        type='checkable'
                                        activeColor='is-hraps'
                                        value={this.state.showInactiveDevices}
                                        onClick={(x) => this.toggleInactiveDeviceFilter({
                                            state: x,
                                            preventDefault: () => {}
                                        })}
                                    >
                                        Show Inactive Devices
                                    </Button>
                                </div>

                                <div className="control">
                                    <Button
                                        type='link'
                                        activeColor='is-success'
                                        icon={faPlusCircle}
                                        path={PathList.deviceCreate}
                                    >
                                        Add New Device
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                    <SearchFilter
                        config={this.state.searchConfig}
                        onChange={(d) => this.handleSearchChange(d)}
                    />
                    </div>

                    <div className="block"></div>

                    <ApiSortableTable
                        headers={this.props.headers}
                        data={devices}
                        limit={this.state.searchParams.limit}
                        total={this.state.total}
                        onPageChange={(page, _limit) => {
                            this.setState({
                                searchParams: {
                                    ...this.state.searchParams,
                                    page,
                                }
                            }, () => this.updateDeviceList())
                        }}
                        onSortChange={(sortKey, sortValue) => {
                            this.setState({
                                searchParams: {
                                    ...this.state.searchParams,
                                    sortKey,
                                    sortValue,
                                }
                            }, () => this.updateDeviceList())
                        }}
                    />
                </div>
            </div>
        );
    }
}
