export const CloseSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-x-square"
  >
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="9" y1="9" x2="15" y2="15"></line>
    <line x1="15" y1="9" x2="9" y2="15"></line>
  </svg>
);

export const BatterySVG = (battery) => {
  return (
    <svg
      width="60"
      height="50"
      viewBox="0 0 50 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="44"
        height="23"
        rx="3"
        ry="3"
        stroke="black"
        fill="none"
        strokeWidth="2"
      />
      <rect
        x="3"
        y="3"
        width={Math.floor(battery * 0.4).toString()}
        height="19"
        rx="2"
        ry="2"
        fill={
          battery > 50
            ? "#4CD964"
            : battery > 10
            ? "rgb(255, 190, 0)"
            : "rgb(255, 0, 0)"
        }
      />
      <rect x="45" y="8" width="3" height="9" fill="black" />
      <text
        x="48%"
        y="87%"
        alignmentBaseline="middle"
        textAnchor="middle"
        fontSize="12"
        fill="black"
      >
        {battery}%
      </text>
    </svg>
  );
};

export const HeartAnimationStyle = (
  <style>
    {`
															@keyframes pulsate {
																0% {
																	transform: scale(1);
																}
																50% {
																	transform: scale(1.2);
																}
																100% {
																	transform: scale(1);
																}
															}

															.pinging {
																fill: rgb(6, 214, 158);
																animation: pulsate 2s ease infinite;
																transform-origin: center;
															}

															.notPinging {
																fill: grey
															}
														`}
  </style>
);

export const CommHubHeartSVG = (timestamp) => {
  const timeSince = (stamp) => {
    const time = Math.floor(stamp / 1000);
    const secondsAgo = Math.floor(Date.now() / 1000) - time;
    const minutesAgo = Math.floor(secondsAgo / 60);
    return secondsAgo < 60 ? `${secondsAgo} secs` : `${minutesAgo} min`;
  };
  return (
    <svg
      width="50px"
      height="50px"
      viewBox="0 0 25 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={
          Math.floor(Date.now() / 1000) - Math.floor(timestamp / 1000) <= 10
            ? "pinging"
            : "notPinging"
        }
        d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
        fill="black"
      />
      <text
        x="49%"
        y="43%"
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        fontSize="5" // Choose a size that fits well within your heart shape
        fontFamily="Arial" // Choose any font-family that suits your design
      >
        {timeSince(timestamp)}
      </text>
    </svg>
  );
};
