export function convertNumberToHexString(number, minDigits = 2) {
    const hex = Math.floor(number).toString(16);
    return hex.length >= minDigits ? hex : Array(minDigits - hex.length).fill(0).concat([hex]).join("");
}

export function getRGBValuesFromString(colorString) {
    // String format is "#RRGGBB" where each color is hexadecimal
    return {
        r: parseInt(colorString.substr(1, 2), 16),
        g: parseInt(colorString.substr(3, 2), 16),
        b: parseInt(colorString.substr(5, 2), 16)
    }
}

export function formatRGBValuesToString(rgb) {
    return `#${convertNumberToHexString(rgb.r)}${convertNumberToHexString(rgb.g)}${convertNumberToHexString(rgb.b)}`;
}

export function calculateLuma(rgb, weights = { r: 0.2126, g: 0.7152, b: 0.0722 }) {
    return ((rgb.r * weights.r) + (rgb.g * weights.g) + (rgb.b * weights.b)) / 255;
}

export function lumaCorrectedSuggestedColor(backgroundColorString, { lumaCalculation = calculateLuma, lumaThreshold = 0.5, darkColor = "#000000", lightColor = "#FFFFFF" } = {}) {
    const bgRgb = getRGBValuesFromString(backgroundColorString);
    const luma = lumaCalculation(bgRgb);
    return luma > lumaThreshold ? darkColor : lightColor;
}

export function blendColorComponent(c1, c2, blend) { // blend should be in the range of [0, 1]
    return ((1 - blend) * c1) + (blend * c2)
}

export function blendColors(rgb1, rgb2, blend) {
    return {
        r: blendColorComponent(rgb1.r, rgb2.r, blend),
        g: blendColorComponent(rgb1.g, rgb2.g, blend),
        b: blendColorComponent(rgb1.b, rgb2.b, blend)
    }
}

export function desaturateColors(color, desaturationAmount = 1.0, { weights = undefined } = {}) {
    const rgb = getRGBValuesFromString(color);
    const luma = calculateLuma(rgb);

    const desaturatedRgb = blendColors(rgb, { r: luma * 255, g: luma * 255, b: luma * 255 }, desaturationAmount);
    return formatRGBValuesToString(desaturatedRgb);
}

export function getAlphaRGBString(color, alpha) { // alpha should be in the range of [0, 1]
    const rgb = getRGBValuesFromString(color);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
}
