import React, {useState, useEffect} from 'react';
import { useDrop } from 'react-dnd';

import { ItemTypes } from './ItemTypes';

import PageSelection from '../../misc/PageSelection';
import Button from '../../misc/Button';

import { pairMultiple, unpair } from '../../../actions/pair-actions';
import { getPageOfRecruits } from '../../../actions/recruit-actions';

/*------------------------------------------------------------------------------------------------*/
/*
 * Recruit Element and Drop Zone
 */

export default function RecruitBox(props) {
    const recruit = props.recruit || {id: ''};
    const debugFlag = props.debugFlag || false;
    const selectedDeviceIds = props.selectedDeviceIds || [];
    const onChange = props.onChange || (() => {});

    const numberOfSelectedDevices = selectedDeviceIds.length;
    const devices = recruit.devices || [];

    const checkCanDrop = (dev) => {
        if (devices.length === 0) {
            return true;
        }
        return !devices.map((d) => d._id).includes(dev.id);
    }

    const doDrop = (dev) => {
        doPair([dev.id]);
    }

    const doPair = (devIds) => {
        pairMultiple(recruit.id, devIds).catch((err) => {
            console.error('Failed to pair devices:', err);
        }).finally(() => {
            onChange();
        });
    }

    const doUnpair = (devId) => {
        unpair(devId).catch((err) => {
            console.error('Failed to unpair devices:', err);
        }).finally(() => {
            onChange();
        });
    }

    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: ItemTypes.BOX,
        drop: doDrop,
        canDrop: checkCanDrop,
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop()
        })
    }));

    const backgroundColor = '#ffdd57';

    const deviceTags = (devices.length > 0)
        ? devices.map((dv, i) => (
            <span className="tag is-success" key={`paired-device-tag-${dv._id}-${i}`}>
                <span id={debugFlag ? dv.friendlyName: undefined}>Device #{dv.friendlyName}</span>
                <Button type='delete' classList={['is-small']} onClick={() => doUnpair(dv._id)}/>
            </span>
        ))
        : (<span className="tag"><span>No Devices Paired</span></span>);

    const dropBin = (
        <Button activeColor={(isOver) ? "is-warning" : "is-danger"}>
            Drop to Pair Device(s)
        </Button>
    );
    const pairButton = (
        <Button activeColor='is-danger' classList={["is-small"]} onClick={() => doPair(selectedDeviceIds)}>
            Click or Drag to Pair Device(s)
        </Button>
    );
    const pairBinButton = ((numberOfSelectedDevices > 0) || canDrop)
        ? ((canDrop) ? dropBin : pairButton)
        : null;

    return (
        <div className="level">
            <div className="level-item level-left">
                <div className="is-size-7">
                    <div className="has-text-weight-bold"><span>{recruit.displayName}</span><span
                        className="mx-1">(Devices Paired: {devices.length || 0})</span></div>
                    <div>
                        <div className="tags">
                            {deviceTags}
                        </div>
                    </div>
                </div>
            </div>

            <div className="level-item level-right">
                <div ref={drop} style={{backgroundColor}} role="Dustbin">
                    {pairBinButton}
                </div>
            </div>
        </div>
    );
}

/*------------------------------------------------------------------------------------------------*/
/*
 * Recruit List Component
 */

export function RecruitList(props) {
    const limit = props.limit || 15;
    const debugFlag = props.debugFlag || false;
    const onPairedDevices = props.onPairedDevices || ((_timestamp) => {});
    const selectedDevices = props.selectedDevices || {};

    const [query, setQuery] = useState('');
    const [recruits, setRecruits] = useState([]);
    const [trigger, setTrigger] = useState(0);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);

    const selectedDeviceIds = Object.keys(selectedDevices);

    useEffect(() => {
        const searchParams = [
            ['recruitsPerPage', limit],
            ['query', (query !== '') ? query : null],
            ['includeMeta', true],
            ['throwOnError', true],
        ].filter(([_, v]) => v !== null);
        console.debug('Search params', searchParams);

        getPageOfRecruits(
            page,
            Object.fromEntries(searchParams),
        ).then((res) => {
            setTotal(res.metadata.total ?? 0);
            const recruits = res.data;
            console.debug('Got Recruits', recruits);
            setRecruits(recruits.filter((x) => !x.isDeleted));
        }).catch((err) => {
            console.error(err);
        });

    }, [query, trigger, page]);

    const header = (
        <tr><th>
            <div className={"columns mb-0"}>
                <div className={"column"}>
                    <span>Recruits</span>
                </div>
            </div>
            <div className={"columns mb-1"}>
                <div className={"column"}>
                    <span>
                        <input placeholder={"Search for recruit by name/identifier"}
                               className={"input"}
                               onChange={(e) => setQuery(e.target.value)}
                        />
                    </span>
                </div>
            </div>
        </th></tr>
    );

    const body = recruits.map((v, i) => {
        return (
            <tr key={`recruit-${v.id}-${i}`}><td>
                <RecruitBox
                    recruit={v}
                    debugFlag={debugFlag}
                    selectedDeviceIds={selectedDeviceIds}
                    onChange={() => {setTrigger(Date.now()); onPairedDevices(Date.now())}}
                />
            </td></tr>
        );
    });

    return (
        <table className="table is-fullwidth is-striped is-hoverable">
            <thead>{header}</thead>
            <tbody>{body}</tbody>
            <tfoot><tr><td>
                <PageSelection
                    page={page}
                    limit={limit}
                    itemTotal={total}
                    onPageChange={setPage}
                />
            </td></tr></tfoot>
        </table>
    );
}
