import React from "react";

// https://github.com/wojtekmaj/react-datetime-picker
import DateTimePicker from "react-datetime-picker";

import Cookies from "js-cookie";

// TODO: This is probably going to need refinement/updating in order to create a consistent and intuitive interface across different browsers...
// Instead of trying to make this component be as perfect as possible right now, it can be refined over time as requirements change

export default class DateTimeSelector extends React.Component {
    static defaultProps = {
        name: "",
        value: "",
        onChange: (_k, _v) => {},
        classList: ["input"]
    }

    onChange(value) {
        console.debug('DateTimeSelector: New Value: ', value);
        this.props.onChange(this.props.name, value);
    }

    render() {
        let value = this.props.value;
        if (value && value.constructor.name != 'Date') {
            console.warn('DateTimeSelector: value should really be a Date object')
            value = new Date(value);
        }
        else if (!value && value !== null) {
            console.warn('DateTimeSelector: value was empty but not null')
            value = null;
        }

        let datePreview;
        try { datePreview = new Date(value).toISOString(); }
        catch (err) { datePreview = "Error"; }

        const locale = Cookies.get('useLocale');
        console.debug('Using locale: ', locale);

        const format = (Cookies.get('use24HrTime') == 'true')
            ? 'MM/dd/y HH:mm:ss'
            : 'MM/dd/y h:mm:ss a';

        return (
            <div>
                <DateTimePicker
                    value={value}
                    disableClock={true}
                    locale={locale}
                    required={true}
                    format={format}
                    calendarType={"US"}
                    onChange={(v) => (this.onChange(v))}
                    />
            <div> {datePreview} </div>
            </div>
        );
    }
}
