import ReactTooltip from 'react-tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

/**
 * Example:
 *
 *      <label>My Label: <ExtraDetails>Some more detailed info...</ExtraDetails></label>
 */
export default function ExtraDetails(props = {}) {
    const {
        children = 'Sample text',
        id = '',
    } = props;

    console.debug('ExtraDetails: props =', props);

    const tmpId = (id !== '') ? id : Math.random().toString(32).substr(2);

    return (
        <span>
            <FontAwesomeIcon data-tip data-for={tmpId} icon={faQuestionCircle} />
            <ReactTooltip id={tmpId}>
                {children}
            </ReactTooltip>
        </span>
    );
}


