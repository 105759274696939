import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";

/**
 * Loading spinner used to indicate that processing/fetching of data is happening.
 *
 *      <LoadingSpinner inProgress={loading}>
 *          <div>My very important data</div>
 *      </LoadingSpinner>
 *
 * @param inProgress {Boolean}      If there is something processing currently.
 * @param children   {DOM}          Content to show if processing is done.
 */
export default function LoadingSpinner(props = {}) {
    const {
        inProgress=false,
    } = props;

    if (inProgress === false) {
        return props.children;
    }
    return (
        <div className="content has-text-centered">
            <FontAwesomeIcon icon={faSpinner} size='4x' spin />
        </div>
    );
}
