import { useState, useEffect } from 'react';

import {
    faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

import ApiSortableTable from '../../misc/ApiSortableTable';
import ConfigurationHeader from "../ConfigurationHeader";
import SearchFilter from "../SearchFilter";
import Button from "../../misc/Button";
import ConfirmationModal from '../../misc/ConfirmationModal';

import PathList from "../../../util/path-list";

import {
    getPageOfSquads,
    deleteSquad,
    restoreSquad,
} from '../../../actions/squad-actions';

export default function SquadConfiguration(props = {}) {
    const headers = [
        { name: 'Name', sortKey: 'name' },
        { name: 'Organization', sortKey: 'org' },
        { name: '# Recruits', sortKey: 'recruitCount' },
        { name: 'Status', sortKey: 'isDeleted' },
        { name: 'Actions' },
    ];
    const filterConfig = [
        { type: 'checkbox', label: 'Include Deleted', key: 'deleted' },
    ];
    const limit = 15;

    const [squads, setSquads] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [sortKey, setSortKey] = useState('');
    const [sortValue, setSortValue] = useState('');
    const [query, setQuery] = useState('');
    const [deleted, setDeleted] = useState('false');
    const [org, setOrg] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [delSquad, setDelSquad] = useState(['','']);

    const querySquads = () => {
        getPageOfSquads(page, {
            includeMeta: true,
            limit,
            sortKey: sortKey ?? '',
            sortValue: !sortKey ? '' : sortValue,
            query,
            deleted,
            org,
        }).then((res) => {
            console.debug('Got Squads:', res);
            setSquads(res.data);
            setTotal(res.metadata.total ?? 0);
            console.debug('Got Squads (total):', res.metadata.total);
        }).catch((err) => {
            console.error('Failed to query squads:', err);
        });
    };

    const onFilterChange = (data) => {
        console.debug(`Filter change data ${JSON.stringify(data)}`);
        setQuery(data.query ?? '');
        setDeleted(data.deleted ? '' : 'false');
        setOrg(data.org ?? '');
    }

    const onDeleteSquad = (sid, name) => {
        setDelSquad([sid, name]);
        setModalVisible(true);
    };

    const onRestoreSquad = (sid) => {
        restoreSquad(sid).finally(() => querySquads());
    }; 

    /*----------------------------------------*/

    useEffect(() => {
        querySquads();
    }, [page, sortKey, sortValue, query, deleted, org]);

    useEffect(() => {
        console.debug(`Current Total ${total}; Page ${page}; SortKey ${sortKey}; SortValue ${sortValue}`);
    }, [total, page, sortKey, sortValue]);

    /*----------------------------------------*/

    const modal = (id, name) => {
        return (
            <ConfirmationModal
                confirmationText={(<p>Delete Squad "{name}"?</p>)}
                confirmButtonAction={() => {setModalVisible(false); deleteSquad(id).finally(() => querySquads())}}
                cancelButtonAction={() => setModalVisible(false)}
                active={modalVisible}
            />
        )
    }

    const createUpdateButton = (v) => {
        return (
            <Button
                type='link'
                path={PathList.squadUpdate(v.id)}
                activeColor='is-success'
                classList={["is-small", "m-1"]}
            >Edit</Button>
        );
    }

    const createDeleteButton = (v) => {
        if (v.isDeleted) {
            return (
                <Button
                    activeColor='is-success'
                    classList={["is-light", "m-1"]}
                    onClick={() => {onRestoreSquad(v.id)}}
                >Activate</Button>
            );
        }
        return (
            <Button
                activeColor='is-danger'
                classList={["is-small", "m-1"]}
                onClick={() => {onDeleteSquad(v.id, v.name)}}
            >Delete</Button>
        );
    };

    const tableData = squads.map((v) => [
        v.name,
        v.org,
        v.recruits?.length ?? 0,
        v.isDeleted ? 'Deleted' : 'Active',
        (<span>
            {createUpdateButton(v)}
            {createDeleteButton(v)}
        </span>)
    ]);

    return (<div className="container">

        <ConfigurationHeader selectedButton='Squads' previousPath={PathList.pois} nextPath={PathList.logfiles} currentPath="Squads" />

        <div className="block"></div>

        <div className="container">
            <div className="level">
                <div className="level-item level-right">
                    <div className="field is-grouped">
                        <div className="control">
                            <Button
                                type='link'
                                path={PathList.squadCreate}
                                icon={faPlusCircle}
                                activeColor='is-success'
                            >Add New Squad</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <SearchFilter
            config={filterConfig}
            onChange={onFilterChange}
        />

        <ApiSortableTable
            headers={headers}
            data={tableData}
            limit={limit}
            total={total}
            sortKey={sortKey}
            sortValue={sortValue}
            onPageChange={(page, _limit) => {
                setPage(page);
            }}
            onSortChange={(sortKey, sortValue) => {
                setSortKey(sortKey);
                setSortValue(sortValue);
            }}
        />
        {modal(delSquad[0], delSquad[1])}
    </div>);
}
