export function insertMissingMACAddressColons(input, { regex = /[\s\S]{1,2}/g, char = ":" } = {}) {
    return (input.match(regex) ?? []).join(char);
}

export function getLastArrayValue(dataArray) {
    return dataArray?.[dataArray?.length - 1]?.value;
}

export function getTimestampInLocalTime(timestamp) {
    return new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'medium', hour12 : false }).format(timestamp);
}

export function getLastArrayTimestampInLocalTime(dataArray) {
    return getTimestampInLocalTime(dataArray?.[dataArray?.length - 1]?.timestamp);
}

export function celsiusToFahrenheit(temperature) {
    return Math.round((temperature * (9/5) + 32) * 10) / 10
}

export function getLatestTimestampData(daraArray, {timestampKey = 'timeOfReceipt'} = {}){
    return daraArray?.length > 0 ? daraArray.reduce((prev, next)=> prev[timestampKey] > next[timestampKey] ? prev : next) : {};
}