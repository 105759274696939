import React from "react";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMobileAlt,
  faSatelliteDish,
  faUser,
  faUsers,
  faCalendarAlt,
  faHandshake,
  faCaretLeft,
  faCaretRight,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";

import PathList from "../../util/path-list";

export default class ConfigurationHeader extends React.Component {
  static defaultProps = {
    navigationButtons: [
      {
        name: "Devices",
        path: PathList.devices,
        icon: <FontAwesomeIcon icon={faMobileAlt} />,
      },
      {
        name: "Hubs",
        path: PathList.commHubs,
        icon: <FontAwesomeIcon icon={faSatelliteDish} />,
      },
      {
        name: "Recruits",
        path: PathList.recruits,
        icon: <FontAwesomeIcon icon={faUser} />,
      },
      {
        name: "Pair",
        path: PathList.pair,
        icon: <FontAwesomeIcon icon={faHandshake} />,
      },
      {
        name: "Events",
        path: PathList.events,
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
      },
      {
        name: "POIs",
        path: PathList.pois,
        icon: <FontAwesomeIcon icon={faMapMarker} />,
      },
      {
        name: "Squads",
        path: PathList.squads,
        icon: <FontAwesomeIcon icon={faUsers} />,
      },
    ],
    selectedButton: "",
    nextPath: "",
    previousPath: "",
    maxVisible: 5,
    currentPath: "Configuration",
  };

  render() {
    const curIdx = this.props.navigationButtons.findIndex(
      (x) => x.name === this.props.selectedButton
    );
    let idxList = [0, 1, 2];
    if (curIdx != null) {
      if (curIdx > Math.floor(idxList.length / 2)) {
        const maxShift = this.props.navigationButtons.length - idxList.length;
        const shiftVal =
          curIdx > maxShift
            ? maxShift
            : curIdx - Math.floor(idxList.length / 2);
        idxList = idxList.map((x) => x + shiftVal);
      }
    }

    const navigationButtonsHtml = idxList
      .map((v) => this.props.navigationButtons[v])
      .map((v, i) => {
        return (
          <li
            className={this.props.selectedButton === v.name ? "is-active" : ""}
            key={`configNav-${v.name}-${i}`}
          >
            <Link disabled={v?.disabled} to={v.path}>
              <span className={v.icon ? "icon-text" : ""}>
                {v.icon ? <span className="icon">{v.icon}</span> : ""}
                <span>{v.name}</span>
              </span>
            </Link>
          </li>
        );
      });

    const prevDisabled =
      !this.props.previousPath || this.props.previousPath === "";
    const nextDisabled = !this.props.nextPath || this.props.nextPath === "";

    return (
      <div className="container my-2">
        <div className="level">
          <div className="level-item level-left">
            <h1 className="title"> {this.props.currentPath}</h1>
          </div>

          <div className="level-item level-right">
            <div className="tabs is-right is-toggle">
              <ul>
                <li>
                  <Link
                    className={prevDisabled ? "has-text-grey-light" : ""}
                    disabled={prevDisabled}
                    to={this.props.previousPath}
                    onClick={prevDisabled ? (e) => e.preventDefault() : null}
                  >
                    <span className="icon-text">
                      <span className="icon">
                        <FontAwesomeIcon icon={faCaretLeft} />
                      </span>
                    </span>
                  </Link>
                </li>

                {navigationButtonsHtml}

                <li>
                  <Link
                    className={nextDisabled ? "has-text-grey-light" : ""}
                    disabled={nextDisabled}
                    to={this.props.nextPath}
                    onClick={nextDisabled ? (e) => e.preventDefault() : null}
                  >
                    <span className="icon-text">
                      <span className="icon">
                        <FontAwesomeIcon icon={faCaretRight} />
                      </span>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to={PathList.configuration}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-settings"
                    >
                      <circle cx="12" cy="12" r="3"></circle>
                      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
