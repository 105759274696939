import React from "react";

import {
    faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from 'js-cookie';

import ConfigurationHeader from "../ConfigurationHeader";
import ApiSortableTable from "../../misc/ApiSortableTable";
import SearchFilter from "../SearchFilter";

import { getPageOfEvents }  from "../../../actions/event-actions";

import { getUserSessionData } from "../../../util/user-session-util";

import PathList from "../../../util/path-list";

import Button from "../../misc/Button";

export default class EventConfiguration extends React.Component {
    static defaultProps = {
        headers: [
            { name: "Status", sortKey: 'status' },
            { name: "Name", sortKey: 'name' },
            { name: "# Recruits", sortKey: 'recruits', title: "Number of Recruits" },
            { name: "Est. Start Time", sortKey: 'actualSTUnix', title: "Estimated Start Time" },
            { name: "Est. End Time", sortKey: 'ETUnix', title: "Estimated End Time" },
            { name: "Actions" }
        ]
    }

    constructor(props) {
        super(props);

        const use24HrTime = Cookies.get('use24HrTime') == 'true';

        this.state = {
            events: [],
            total: 0,
            searchParams: {
                query: '',
                status: '',
                page: 0,
                limit: 20,
                org: '',
                sortKey: '',
                sortValue: '',
                startTime: '',
            },
            use24HrTime,
            searchConfig: [
                // {type: 'select', key: 'status', label: 'Status', labelValue: '', options: [
                //     { text: "Complete", value: 'complete'},
                //     { text: 'Upcoming', value: 'upcoming'},
                //     { text: 'In Progress', value: 'inProgress'},
                //     { text: 'Cancelled', value: 'cancelled'},
                //     { text: 'Overdue', value: 'overdue'},
                // ]},
                {type: 'date', key: 'startTime', label: 'Date Range'},
            ],
        }

        this.updateEventList = this.updateEventList.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    updateEventList() {
        const searchParams = {
            ...this.state.searchParams,
            eventsPerPage: this.state.searchParams.limit,
            includeMeta: true,
        }
        getPageOfEvents(searchParams.page, searchParams).then((resp) => {
            const metadata = resp.metadata || {total: 0}
            console.log("GOT ALL EVENTS:", resp?.data);
            this.setState({ events: resp.data || [], total: metadata.total });
        })
    }

    componentDidMount() {
      const userToken = getUserSessionData()
      if (!userToken) {
              window.location.href = PathList.login;
              return;
          }
        this.updateEventList();
    }

    transformEventsListForTable(eventsList) {
        function to_sentence_case(ccase) {
            var r = ccase.replace(/([A-Z])/g, " $1");
            return r.charAt(0).toUpperCase() + r.slice(1);
        }
        return eventsList.map((v, i) => {


            var tagClass = "is-danger";
            switch (v.status) {
                case "complete":
                    tagClass = "is-success";
                    break;
                case 'upcoming':
                    tagClass = "is-info";
                    break;
                case 'inProgress':
                    tagClass = "is-warning";
                    break;
                case 'cancelled':
                    tagClass = "is-light";
                    break;
                case 'overdue':
                case 'unknown':
                default:
                    tagClass = "is-danger";
                    break;
            }

            const statusTag = <span
                        className={"tag " + tagClass}
                        >{to_sentence_case(v.status)}</span>;

            const editButton = (
                <Button
                    type='link'
                    classList={['mx-1']}
                    path={PathList.eventUpdate(v.id)}
                >
                    Edit
                </Button>
            )
            // TODO: Need to implement event deleting
            const deleteButton = (
                <Button
                    classList={['is-light','mx-1']}
                    activeColor={v?.isDeleted ? " is-success" : " is-danger"}
                    onClick={(e) => e?.preventDefault?.()}
                    disabled
                >
                    Delete
                </Button>
            )

            // Setting hour12 to false displays 24hr time, true displays AM/PM time, need to decide if 24hr time is desired across the app
            const dateFormatOptions = {
                hour12: !this.state.use24HrTime,
                hour: "2-digit",
                minute: "2-digit",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            };

            return [
                statusTag,
                v?.name,
                v?.recruits?.length,
                (new Date(Date.parse(v?.estimatedStartTime))).toLocaleString("en-US", dateFormatOptions),
                (new Date(Date.parse(v?.endTime))).toLocaleString("en-US", dateFormatOptions),
                <div>{editButton}{deleteButton}</div>
            ];
        });
    }

    handleOnChange(e) {
        console.debug(`Org filter change: ${e.target.value}`);
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                org: e.target.value,
            }
        }, () => this.updateEventList());
    }

    handleSearchChange(data) {
        if (data.startTime instanceof Array) {
            const startTime = data.startTime[0];
            const endTime = data.startTime[1];
            data.startTime = startTime;
            data.endTime = endTime;
        }
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                ...data,
            }
        }, () => {
            console.debug('DC: New search params', this.state.searchParams)
            this.updateEventList()
        })
    }

    handleToggle24HourTime() {
        const curState = this.state.use24HrTime;
        const newVal = curState ? 'false' : 'true';
        console.debug('Toggling 24-Hour Time: ', newVal);
        Cookies.set('use24HrTime', newVal);
        this.setState({
            use24HrTime: !curState,
        });
    }

    render() {
        const events = this.transformEventsListForTable(this.state.events);

        const is24Hr = this.state.use24HrTime;

        return (
            <div>
                <ConfigurationHeader
                    selectedButton={"Events"}
                    previousPath={PathList.pair}
                    nextPath={PathList.pois}
                    currentPath="Events"
                />

                <div className="block"></div>

                <div className="container">
                    <div className="level">
                        <div className="level-item level-right">
                            <div className="field is-grouped">
                                <div className="control">
                                    <Button
                                        type='checkable'
                                        activeColor='is-hraps'
                                        value={is24Hr}
                                        onClick={() => this.handleToggle24HourTime()}
                                    >
                                        24-Hour Time
                                    </Button>
                                </div>

                                <div className="control">
                                    <Button
                                        type='link'
                                        path={PathList.eventCreate}
                                        activeColor='is-success'
                                        icon={faPlusCircle}
                                    >
                                        Add New Event
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                    <SearchFilter
                        config={this.state.searchConfig}
                        onChange={(d) => this.handleSearchChange(d)}
                    />
                    </div>

                    <div className="block"></div>

                    <ApiSortableTable
                        headers={this.props.headers}
                        data={events}
                        limit={this.state.searchParams.limit}
                        total={this.state.total}
                        onPageChange={(page, _limit) => {
                            this.setState({
                                searchParams: {
                                    ...this.state.searchParams,
                                    page,
                                }
                            }, () => this.updateEventList())
                        }}
                        onSortChange={(sortKey, sortValue) => {
                            this.setState({
                                searchParams: {
                                    ...this.state.searchParams,
                                    sortKey,
                                    sortValue
                                }
                            }, () => this.updateEventList())
                        }}
                    />
                </div>
            </div>
        );
    }
}
