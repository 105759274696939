import React, { useState, useEffect, useRef } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isBrowser } from "react-device-detect";
import SearchFilter from "./SearchComponent";
import ComhubCard from "./ComhubCard";
import { getPageOfDeviceLogFileMetaData } from "../../actions/logfile-actions";
import {
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../misc/Button";
import EmailActionBar from "./EmailActionBar";
import InfoAside from "./InfoAside";
import PathList from "../../util/path-list";
import ConfigurationHeader from "../configuration/ConfigurationHeader";
//Import toaster component for displaying messages
import Toaster from "../misc/toaster/Toaster";
import QuickDashboard from "../misc/QuickDashboard";

function formatUTC(time) {
  return Math.floor(new Date(time).getTime() / 1000);
}

const getStorageFiles = () => {
  return Object.entries(localStorage)
    .filter(([key, value]) => !!key && key?.includes("logfile"))
    .map(([key, value]) => JSON.parse(localStorage.getItem(key)));
};

function LogFile(props) {
  const today = new Date();
  const tomorrow = new Date(today);
  const threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(today.getMonth() - 3);
  tomorrow.setDate(today.getDate() + 1);
  const [isLoading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(0);
  const [logfiles, setLogfiles] = useState([]);
  const [searchParams, setSearchParams] = useState({
    returnDeviceMap: false,
    query: "",
    status: "",
    queryFields: "friendlyName",
    page: 0,
    limit: 27,
    startTime: formatUTC(threeMonthsAgo),
    endTime: formatUTC(tomorrow),
    org: "",
    deleted: "false",
  });
  const searchConfig = [
    {
      type: "radio",
      key: "queryFields",
      options: [
        { label: "By Name", value: "friendlyName", default: true },
        { label: "By MAC", value: "nativeDeviceId", default: false },
      ],
    },
    { type: "date", key: "startTime", label: "Date Range" },
  ];
  const [sortParams, setSortParams] = useState({
    uploadDate: 1,
  });
  const [page, setPage] = useState(0);
  const [pageMax, setPageMax] = useState(0);
  const ASCENDING = "▲";
  const DESCENDING = "▼";
  const [activeButtons, setActiveButtons] = useState([]);
  const [sidebarOpenedState, setSidebarOpenedState] = useState(false);
  const [sentStatus, setSentStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [trigger, setTrigger] = useState(0);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  //  Functions
  const fetchData = async () => {
    try {
      setLoading(true);
      const page = await getPageOfDeviceLogFileMetaData(searchParams.page, {
        ...searchParams,
        ...sortParams,
        filesPerPage: searchParams.limit,
        includeMeta: true,
      }).then((res) => {
        setLoading(false);
        if (res) {
          setHasData(res.total);
        }
        return res;
      });
      setLogfiles(page.collection);
      setPageMax(getPageMax(page.total, page.limit));
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const getEmailAddress = () => {
    return email;
  };

  const emailHasBeenSent = () => {
    setToastMessage("Email has been sent");
    setToastType("success");
    setTrigger(trigger + 1);
    setSentStatus(true);
    setSidebarOpenedState(true);
    setTimeout(() => {
      localStorage.clear();
    }, 1500);
  };

  const selectFiles = (file, info) => {
    file["logfileId"] = `${file._id}`;
    if (localStorage.getItem(`logfile-${file["logfileId"]}`) !== null) {
      localStorage.removeItem(`logfile-${file["logfileId"]}`);
      file["active"] = false;
      setToastMessage("File removed from download list");
      setToastType("warning");
      setTrigger(trigger + 1);
      return;
    }

    localStorage.setItem(
      `logfile-${file["logfileId"]}`,
      JSON.stringify({
        logfile: JSON.parse(JSON.stringify(file)),
        device: info ? JSON.parse(JSON.stringify(info)) : {},
      })
    );
    setToastMessage("File added to download list");
    setToastType("success");
    setTrigger(trigger + 1);
    file["active"] = true;
  };

  const handleSidebarToggle = () => {
    setSentStatus(false);
    setEmail("")
    setSidebarOpenedState(!sidebarOpenedState);
  };

  function getPageMax(total, limit) {
    if (!(total > 0 && limit > 0)) {
      return 0;
    }
    return Math.ceil(total / limit);
  }

  useEffect(() => {
    window.addEventListener("beforeunload", localStorage.clear());
    return () => {
      window.removeEventListener("beforeunload", localStorage.clear());
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  // Previous object reference
  const prevSortParamsRef = useRef(sortParams);
  const prevSearchParamsRef = useRef(searchParams);
  const prevPageRef = useRef(page);

  useEffect(() => {
    // Compare previous and current sortParams objects
    if (prevSortParamsRef.current !== sortParams) {
      fetchData();
    }
    prevSortParamsRef.current = sortParams;
  }, [sortParams]);

  useEffect(() => {
    // Compare previous and current searchParams objects
    if (prevSearchParamsRef.current !== searchParams) {
      fetchData();
    }
    prevSearchParamsRef.current = searchParams;
  }, [searchParams]);

  useEffect(() => {
    // Compare previous and current page objects
    if (prevPageRef.current !== page) {
      fetchData();
    }
    prevPageRef.current = page;
  }, [page]);

  // Change Handlers
  function handleSearchChange(data) {
    if (data.startTime instanceof Array) {
      const startTime = data.startTime[0];
      const endTime = data.startTime[1];
      data.startTime = formatUTC(startTime);
      data.endTime = formatUTC(endTime);
    } else {
      data.startTime = formatUTC(data.startTime);
    }

    setSearchParams((searchParams) => ({ ...searchParams, ...data }));
  }

  const navChange = (dir) => {
    let tempPage = page
    if (dir === "next" && page + 1 < pageMax) {
      tempPage += 1;
      setPage(page + 1);
    } else if (dir === "prev" && page > 0) {
      setPage(page - 1);
      tempPage -= 1;
    } else if (dir === "first" && page > 0) {
      tempPage = 0;
      setPage(0);
    } else if (dir === "last" && pageMax !== 0 && page + 1 !== pageMax) {
      tempPage = pageMax -1;
      setPage(pageMax - 1);
    }
    setSearchParams((searchParams) => ({ ...searchParams, ...{ page: tempPage } }));
  };

  const isActive = (buttonId) => {
    return activeButtons.includes(buttonId);
  };

  const handleSortChange = (buttonId) => {
    if (!buttonId) {
      return;
    }

    setActiveButtons((prevActiveButtons) => {
      // toggle the active button state
      return prevActiveButtons.includes(buttonId)
        ? prevActiveButtons.filter((id) => id !== buttonId)
        : [...prevActiveButtons, buttonId];
    });

    setSortParams((sortParams) => {
      let newSortParams = { ...sortParams };
      newSortParams[buttonId] = Number(newSortParams[buttonId]) * -1;
      return { ...sortParams, ...newSortParams };
    });
  };

  // Elements
  const ico = (buttonId) => {
    return sortParams[buttonId] === 1 ? ASCENDING : DESCENDING;
  };

  const buttonsMeta = [
    { name: "first", icon: faAngleDoubleLeft },
    { name: "prev", icon: faAngleLeft },
    { name: "next", icon: faAngleRight },
    { name: "last", icon: faAngleDoubleRight },
  ];

  const buttons = buttonsMeta.map((v) => (
    <span key={`button-${v.name}`}>
      <Button
        classList={["pagination-link"]}
        icon={v.icon}
        onClick={() => navChange(v.name)}
      />
    </span>
  ));

  const pageIndicator = (
    <span className="has-text-weight-bold is-italic">
      Page {page + 1} of {pageMax === 0 ? "???" : pageMax}
    </span>
  );

  const uiMessage =
    !isLoading && hasData === 0 ? (
      <div className="is-size-3 has-text-centered column">
        <h3>No Logfiles Found</h3>
      </div>
    ) : (
      <div className="is-size-3 has-text-centered column">
        <h3>Loading...</h3>
      </div>
    );

  return (
    <div className="">
      <br />
      <QuickDashboard title={"Commhub Log Files"} links={[]} />
      <div className="container">
        <br />
        <div className="project-list">
          <div className="project-list-item">
            <div className="project">
              <SearchFilter
                hideOrg={true}
                config={searchConfig}
                onChange={(d) => handleSearchChange(d)}
              />
            </div>
            <div className="details">
              <button
                id="uploadDate"
                className={
                  isActive("uploadDate")
                    ? "button is-primary"
                    : " button is-solid"
                }
                onClick={() => handleSortChange("uploadDate")}
              >
                Upload Date
                {ico("uploadDate")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <DndProvider backend={isBrowser ? HTML5Backend : TouchBackend}>
        <div className="container is-light">
          <div className="contact-grid">
            <nav className="pagination is-right" role="navigation">
              <div className="pagination-next">{pageIndicator}</div>
              <div className="pagination-list">{buttons}</div>
            </nav>
            <div className="columns is-multiline b-columns-half-table-p">
              {
                !isLoading && logfiles.length > 0
                  ? logfiles.map((items, idx) => {
                      return (
                        <div
                          className="ecommerce-dashboard column is-one-third"
                          key={`#logfile-${items._id}-${idx}`}
                        >
                          <ComhubCard
                            onDataFromChild={selectFiles}
                            comhubId={items._id}
                            key={`#logfile-${items._id}-${idx}`}
                            lfs={items.logfiles}
                            info={items.deviceInfo[0]}
                            prime={items.uploadDate}
                          />
                        </div>
                      );
                    })
                  : uiMessage
              }
              {}
            </div>
          </div>
          <EmailActionBar
            email={getEmailAddress}
            onEmailChange={handleEmailChange}
            onDataFromChild={handleSidebarToggle}
            onActionFromChild={selectFiles}
            onCompleted={emailHasBeenSent}
          />
          <Toaster trigger={trigger} message={toastMessage} type={toastType} />
          {getStorageFiles() ? (
            <InfoAside
              isOpen={sidebarOpenedState}
              toggleSidebar={handleSidebarToggle}
              storage={getStorageFiles()}
              isComplete={sentStatus}
              onDataFromChild={handleSidebarToggle}
              email={email}
            />
          ) : (
            <span></span>
          )}
        </div>
        <br />
      </DndProvider>
    </div>
  );
}

export default LogFile;
