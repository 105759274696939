import markerImage from "./nato_single.png";
import imageBackgroundSolid from "./icons/map-marker-solid.png";
import imageCircleSolid from "./icons/circle-solid.png";
import imageOban from "./icons/oban.png";
import imageSlateSafety from "./icons/slatesafety_shield.png";
import imageLifeLens from "./icons/lifelens.png";
import imageCommHub from "./icons/commhub-solid.png";
import imageFaucet from "./icons/faucet-solid.png";
import imagePlay from "./icons/play-solid.png";
import imageSquare from "./icons/square-solid.png";

import {createLineString} from "../../util/geojson-util";
import {ENV} from "../../util/env-util";

export const MAP_IMAGES = {
    'marker-icon': markerImage,
    'background-icon': imageBackgroundSolid,
    'default-icon': imageCircleSolid,
    'oban-icon': imageOban,
    'slatesafety-icon': imageSlateSafety,
    'lifelens-icon': imageLifeLens,
    'commhub-icon': imageCommHub,
    'waterstation-icon': imageFaucet,
    'startPoint-icon': imagePlay,
    'endPoint-icon': imageSquare,
};

const TEXT_FONTS_ = {
    regular: (ENV.mapsEnable === "1")
        ? ['Noto Sans Regular', 'Open Sans Regular']
        : ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    bold : (ENV.mapsEnable === "1")
        ? ['Noto Sans Bold', 'Open Sans Bold', 'Noto Sans Regular']
        : ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    italic : (ENV.mapsEnable === "1")
        ? ['Noto Sans Italic', 'Open Sans Italic', 'Noto Sans Regular']
        : ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
};

const MAP_SOURCES = {
    route: {
        type: "geojson",
        data: createLineString([0, 0]),
    },
    activeDeviceLocations: {
        type: 'geojson',
        cluster: true,
        clusterMaxZoom: 10,
        clusterRadius: 80,
        data: null,
    },
    selectedDeviceLocation: {
        type: 'geojson',
        cluster: false,
        data: null,
    },
};

const MAP_SOURCE_SETTERS = {
    route: (d) => d,
    activeDeviceLocations: (d) => ({
        'type': 'FeatureCollection',
        'features': d
    }),
}

const MAP_LAYERS = {
    route: {
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
            'line-join': 'round',
            'line-cap': 'round'
        },
        paint: {
            'line-color': '#0000FF',
            'line-opacity': 0.8,
            'line-width': 4
        }
    },
    'background-icons': {
        id: 'background-icons',
        type: 'symbol',
        source: 'activeDeviceLocations',
        filter: ['!', ['has', 'point_count']],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': 'background-icon',
            'icon-size': ['get', 'iconSize'],
            'icon-anchor': 'bottom',
        },
        paint: {
            'icon-color': '#FFF',
            'icon-opacity': ['get', 'iconOpacity'],
        }
    },
    'marker-icons': {
        id: 'marker-icons',
        type: 'symbol',
        source: 'activeDeviceLocations',
        filter: ['!', ['has', 'point_count']],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': ['get', 'icon'],
            'text-allow-overlap': true,
            'icon-size': ['get', 'iconSize'],
            'icon-anchor': 'bottom',
            'text-field': ['get', 'rosterId'],
            'text-anchor': 'bottom',
            'text-transform': 'uppercase',
            'text-font': TEXT_FONTS_.regular,
            'text-size': 12
        },
        paint: {
            'icon-color': ['get', 'riskColor'],
            'icon-opacity': ['get', 'iconOpacity'],
            'text-translate': [0, 25],
            'text-color': '#FFF',
            'text-halo-color': '#000',
            'text-halo-width': 5
        }
    },
    clusters: {
        id: 'clusters',
        type: 'circle',
        source: 'activeDeviceLocations',
        filter: ['has', 'point_count'],
        paint: {
            'circle-color': '#FFF',
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                20,
                100,
                30,
                750,
                40
            ]
        }
    },
    'cluster-count': {
        id: 'cluster-count',
        type: 'symbol',
        source: 'activeDeviceLocations',
        filter: ['has', 'point_count'],
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': TEXT_FONTS_.bold,
            'text-size': 12,
        },
        paint: {
            'text-color': '#000'
        }
    },
}

/*------------------------------------------------------------------------------------------------*/

class ZoomCtrl {
    onAdd(map) {
        this._map = map;

        const elem = document.createElement('div');
        elem.className = 'mapboxgl-ctrl';
        elem.textContent = this.zoomText();
        const style = {
            fontSize: '8pt',
            background: '#FFF',
            opacity: 0.7,
            paddingLeft: '1em',
            paddingRight: '1em',
            border: '1px solid black',
        };
        Object.entries(style).forEach(([k, v]) => { elem.style[k] = v; });
        this._container = elem;

        this.zoomChange = this.zoomChange.bind(this);

        map.on('zoom', this.zoomChange);

        return this._container;
    }

    onRemove() {
        this.map.off('zoom', this.zoomChange);
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    zoomChange() {
        if (this._map == null) { return; }
        this._container.textContent = this.zoomText(this._map.getZoom());
    }

    zoomText(v) {
        if (v == null) { return 'Zoom: ?????' }
        const val = this._map.getZoom().toFixed(2);
        return `Zoom: ${val}`;
    }
}

class CoordsCtrl {
    onAdd(map) {
        this._map = map;

        const elem = document.createElement('div');
        elem.className = 'mapboxgl-ctrl';
        elem.textContent = this.getText();
        const style = {
            fontSize: '8pt',
            background: '#FFF',
            opacity: 0.7,
            paddingLeft: '1em',
            paddingRight: '1em',
            border: '1px solid black',
        };
        Object.entries(style).forEach(([k, v]) => { elem.style[k] = v; });
        this._container = elem;

        this.mouseMove = this.mouseMove.bind(this);

        map.on('click', this.mouseMove);

        return this._container;
    }

    onRemove() {
        this.map.off('zoom', this.zoomChange);
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    mouseMove(e) {
        if (this._map == null) { return; }
        this._container.textContent = this.getText(e.lngLat);
    }

    getText(v) {
        const dta = {lat: '??????', lng: '??????'};
        if (v != null) {
            dta.lat = v.lat.toFixed(6);
            dta.lng = v.lng.toFixed(6);
        }
        return [dta.lat, dta.lng].map((v) => v.toString()).join(', ');
    }
}


/*------------------------------------------------------------------------------------------------*/

function loadImages(map) {
    Object.entries(MAP_IMAGES).forEach(([name, imageSrc]) => {
        map.loadImage(
            imageSrc,
            function (error, image) {
                if (error) {
                    throw error;
                }
                // Add the image to the map style.
                map.addImage(name, image, {"sdf": "true"});
            }
        );
    });
}

function createSources(map) {
    Object.entries(MAP_SOURCES).forEach(([name, config]) => {
        if (['geojson'].includes(config.type)) {
            map.addSource(name, config);
        }
        else {
            throw new Error(`Unhandled Map source ${name} of type ${config.type}`);
        }
    });
}

function loadMapLayers(map, isMarine_) {
    const riskColorField = isMarine_ ? 'marineRiskColor' : 'riskColor'
    MAP_LAYERS['marker-icons'].paint['icon-color'] = ['get', riskColorField];
    Object.values(MAP_LAYERS).forEach((v) => {
        map.addLayer(v);
    })
}

function initZoomLabel(map) {
    map.addControl(new ZoomCtrl(), 'bottom-right');
}

function initCoordsLabel(map) {
    map.addControl(new CoordsCtrl(), 'bottom-right');
}

export function mapDataInitialization(map, isMarine) {
    loadImages(map);
    createSources(map);
    loadMapLayers(map, isMarine);
    if (ENV.webClientDebug == "1") {
        initZoomLabel(map);
        initCoordsLabel(map);
    }
}

export function mapSetSource(map, name, source) {
    if (!(name in MAP_SOURCE_SETTERS)) {
        throw new Error(`Unable to find ${name} in configured map sources`);
    }
    map?.getSource(name)?.setData(MAP_SOURCE_SETTERS[name](source));
}
