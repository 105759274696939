import querystring from "querystring";
import { getUserSessionData } from "../util/user-session-util";
import { METHODS, apiCall, CONTENT_TYPES } from "../util/fetch-util";
import { getAllPages } from "../util/query-utils";

/**
 * @deprecated Since 2022-11-10, Use `getPageOfRecruits()` instead.
 */
export async function getAllRecruits({ returnRecruitMap=false, otherOpts={}, pageMode=false } = {}) {
    const data = await getAllPages(getPageOfRecruits, { limitField: 'recruitsPerPage', otherOpts });
    if (returnRecruitMap) {
        return Object.fromEntries(data.map((r) => [r.id, r]));
    }
    return data;
}

export async function getAllRecruitsById() {
    return await getAllRecruits({ returnDeviceMap: true });
}

export async function getOneRecruit(recruitId) {
    const recruit = await apiCall(`/recruits/${recruitId}`, METHODS.get, { throwOnError: true});

    return recruit;
}

export async function getPageOfRecruits(page,
                                        {
                                            query = "",
                                            search = "",
                                            status = "",
                                            recruitsPerPage = 15,
                                            org = "",
                                            userId = "",
                                            deleted = "",
                                            sortKey = "",
                                            sortValue = "",
                                            queryFields = "",
                                            includeMeta = false,
                                            throwOnError = false,
                                        } = {}) {
    // This function may not need to exist since we aren't paging the recruit list, but is provided as a legacy to when it used to work that way...
    const searchParams = {
        query,
        search,
        status,
        page,
        limit: recruitsPerPage,
        org,
        deleted,
        sortKey,
        sortValue,
        queryFields,
        ...{userId: userId || getUserSessionData().id},
    };
    const encodedSearchParams = querystring.encode(searchParams);

    const devices = await apiCall(`/recruits?${encodedSearchParams}`, METHODS.get, { throwOnError });

    return (includeMeta) ? devices : devices?.data;
}

export async function activateRecruit(recruitId) {
    return await apiCall(`/recruits/${recruitId}/activate`, METHODS.get, { throwOnError: true, accept: CONTENT_TYPES.plain }); // TODO: Backend needs to make this endpoint a POST not a GET.
}

export async function deactivateRecruit(recruitId) {
    return await apiCall(`/recruits/${recruitId}/destroy`, METHODS.delete, { throwOnError: true, accept: CONTENT_TYPES.plain });
}

export async function addRecruit(recruitData) {
    const body = {
        recruits: [
            {
                name: recruitData.name,
                displayName: recruitData.displayName,
                org: recruitData.org
            }
        ]
    };

    return await apiCall(`/recruits`, METHODS.post, { body, throwOnError: true });
}

export async function updateRecruit(recruitId, recruitData) {
    const body = {
        name: recruitData.name,
        displayName: recruitData.displayName,
        org: recruitData.org
    };

    return await apiCall(`/recruits/${recruitId}/update`, METHODS.post, {
        body,
        throwOnError: true,
        accept: CONTENT_TYPES.plain,
    });
}
