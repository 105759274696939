import React from "react";
import { lumaCorrectedSuggestedColor } from "../../util/css-util";
import { riskToColor } from './real-time-constants';

export default function RiskLevelIndicator(props) {
    const riskLevel = props.riskLevel || "X";
    let riskColor = props.riskColor;
    if (riskColor == null) {
        riskColor = riskToColor(riskLevel);
    }

    const textColor = lumaCorrectedSuggestedColor(riskColor);

    const style = {
        color: textColor,
        border: `1px solid ${textColor}`,
        borderRadius: "10%",
        backgroundColor: riskColor,
        padding: '0 .5em 0 .5em',
    };

    return (
        <span style={style} className="has-text-centered has-text-weight-bold">
            {riskLevel}
        </span>
    );
}
