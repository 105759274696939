import React from "react";
import { Route, Redirect } from "react-router-dom";

import PathList from "../../util/path-list";

import { isLoggedIn } from "../../actions/user-actions";

export default class ProtectedRoute extends React.Component {
    static defaultProps = {
        checkAuthentication: () => isLoggedIn()
    }

    render() {
        if (this.props.checkAuthentication() === true) {
            if (this.props.children) {
                return <Route path={this.props.path} exact={this.props.exact}>{this.props.children}</Route>;
            }

            if (this.props.render) {
                return <Route path={this.props.path} exact={this.props.exact} render={this.props.render} />;
            }

            if (this.props.component) {
                return <Route path={this.props.path} exact={this.props.exact} component={this.props.component} />;
            }

            throw new Error("No destination component specified in ProtectedRoute!");
        }

        console.log("Authentication failed.");
        // Authentication failed
        return <Route path={this.props.path} exact={this.props.exact} render={(props) => <Redirect to={PathList.login} />} />;
    }
}