export function createGeoJson({ type = "Point", positionData = [0, 0], name = undefined, properties = {} } = {}) {
    const output = {
        type: "Feature",
        properties,
        geometry: {
            type,
            coordinates: positionData
        }
    };

    if (name) {
        output.properties = { name };
    }

    return output;
}

export function createPoint(latitude, longitude, { name = undefined, properties = {} } = {}) {
    return createGeoJson({ type: "Point", positionData: [longitude, latitude], name, properties });
}

export function createLineString(positions, { name = undefined, properties = {} } = {}) {
    return createGeoJson({ type: "LineString", positionData: positions, name, properties });
}

export function processPositionHistory(latitudes, longitudes) {
    if (latitudes.length !== longitudes.length) {
        throw new Error(`Latitude array and Longitude array must be the same length! [${latitudes?.length} != ${longitudes?.length}]`);
    }

    return latitudes.map((v, i) => [longitudes[i], v]);
}