import {useState} from 'react';

export const ASCENDING = "▲";
export const DECENDING = "▼";

export const DIR_ASC = 0;
export const DIR_DESC = 1;
export const DIR_NONE = 2;

/**
 * Parse out the defaultSort input into an array of tuples where the first value is the sortKey and
 * second is the direction.
 *
 *      ['-risk', 'rosterId'] => [['risk', DIR_ASC], ['rosterId', DIR_DESC]]
 *
 * @param value {String or Array}       Sort key(s) and if prefixed with `-` it means decending.
 * @returns                             Array of tuples specifying the order.
 */
function getDefaultSort(value) {
    function parse(v) {
        v = (v.startsWith('-'))
            ? [v.slice(1), DIR_DESC]
            : [v, DIR_ASC];
        return v;
    }
    if (value == null) {
        return [[null, DIR_ASC]];
    }
    if (value instanceof Array) {
        return value.map(parse);
    }
    return [parse(value)];
}

/**
 * @param props {Object}
 *      - headers {Array}:
 *          Array of objects defining the definiton of each column. Should contain the following:
 *          name, title (_Optional_), & sortAlgorithm (data key used in the data objects).
 *      - data {Array}:
 *          Array of data objects. `sortAlgorithm` defines the keys to use to pull data from the
 *          provided objects.
 *      - topData {Array}:
 *          Additional data to enforce always being at the top of the table.
 *      - bottomData {Array}:
 *          Additional data to enforce always being at the bottom of the table.
 *      - sortable {Boolean}:
 *          (true) Enable sorting of the table by clicking on table headers.
 *      - xformHandler {Callable}:
 *          Called for each data item to prepare the visual output of the data. `data` is the
 *          individual data item, `key` is the key used to obtain it, `obj` is the original object.
 *      - rowClickCallback {Callable}:
 *          Called when a row is clicked, `obj` is the original object used for the row. `e` is the
 *          table element event.
 */
export default function SortableTableAlt(props = {}) {
    const {
        headers = [],
        data = [],
        topData = [],
        bottomData = [],
        sortable = false,
        defaultSort = null,
        allowDeselect = false,
        xformHandler = ((data, _type, _obj) => (data)),
        rowClickCallback = (_obj, _e) => {},
    } = props;

    const sortOrders = getDefaultSort(defaultSort);

    const [sortKey, setSortKey] = useState((allowDeselect) ? null : sortOrders[0][0]);
    const [sortDir, setSortDir] = useState((allowDeselect) ? DIR_ASC : sortOrders[0][1]);

    const getSortIcon = (key) => {
        if (![DIR_ASC, DIR_DESC].includes(sortDir)) {
            return null;
        }
        const icon = (sortDir === DIR_ASC) ? ASCENDING : DECENDING;
        return (key === sortKey) ? icon : null;
    };

    const onHeaderClicked = (key) => {
        if (key === sortKey) {
            setSortDir((sortDir + 1) % ((allowDeselect) ? 3 : 2));
        }
        else {
            setSortKey(key);
            if (sortDir === DIR_NONE) {
                setSortDir(DIR_ASC);
            }
        }
    }

    const sortFunc = ((a, b, { sortIdx = -1 } = {}) => {
        if (!sortKey && sortIdx === -1) { sortIdx = 0; }
        const tmpSortKey = (sortIdx === -1) ? sortKey : sortOrders[sortIdx][0];
        const a_ = a?.[tmpSortKey];
        const b_ = b?.[tmpSortKey];
        if (a_ === b_) {
            sortIdx += 1;
            if (sortIdx in sortOrders) {
                return sortFunc(a, b, {sortIdx});
            }
            return 0;
        }
        if (a_ == null || a_ === '') { return 1; }
        if (b_ == null || b_ === '') { return -1; }
        let ret = a_ > b_ ? 1 : -1;
        const tmpSortDir = (sortIdx === -1) ? sortDir : sortOrders[sortIdx][1];
        if (tmpSortDir === DIR_DESC) { ret = ret * -1 }
        return ret;
    });
    const nonsortFunc = ((a, b) => {
        return sortFunc(a, b, {sortIdx: 0});
    });

    /*----------------------------------------*/

    const hdrs = headers.map((v, i) => (
        <th key={`sortable-table-header-${i}`} title={v?.title || v.name} onClick={() => onHeaderClicked(v.sortAlgorithm)}>
            {v.name}{getSortIcon(v.sortAlgorithm)}
        </th>
    ));

    const handleData = (dta_, idx) => {
        dta_ = (sortable && [DIR_ASC, DIR_DESC].includes(sortDir) && !!sortKey)
            ? dta_.sort(sortFunc)
            : dta_.sort(nonsortFunc);
        dta_ = dta_.map((v) => [
            headers.map((col) => (
                xformHandler(v[col.sortAlgorithm], col.sortAlgorithm, v)
            )),
            (e) => rowClickCallback(v, e),
        ]);
        dta_ = dta_.map(([v, cb], i) => (
            <tr key={`sortable-table-body-${idx}-${i}-${Date.now()}`} onClick={cb}>
                {
                    v.map((w, j) => (
                        <td key={`sortable-table-body-line-${idx}-${j}`}>
                            {w}
                        </td>
                    ))
                }
            </tr>
        ));
        return dta_;
    };

    const dta = [].concat(...[topData, data, bottomData].map(handleData));

    const classes = 'table is-striped';
    return (
        <div className="table-container">
        <table className={classes}>
            {hdrs?.length > 0 ? <thead><tr>{hdrs}</tr></thead> : null}
            {dta?.length > 0 ? <tbody>{dta}</tbody> : null}
        </table>
        </div>
    );
}
