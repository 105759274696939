import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import SelectList from "../../misc/SelectList";

import {
    getSquad,
    createSquad,
    updateSquad,
} from '../../../actions/squad-actions';
import {
    getAllRecruits,
} from '../../../actions/recruit-actions';
import {
    getOrganizations,
} from '../../../actions/organization-actions';

import ConfirmationModal from '../../misc/ConfirmationModal';
import NotificationModal from '../../misc/NotificationModal';
import Button from '../../misc/Button';

function FormConfirmModal(props = {}) {
    const {
        msg = 'Confirm Submit?',
        data = {},
        labels = {},
        onConfirm = () => {},
        onCancel = () => {},
    } = props;

    const body = Object.entries(data).map(([k, v], i) => {
        const label = labels[k] ?? k;
        if (v instanceof Array) {
            return (<div key={`confdiag-${i}-${k}`}>
                <span className="has-text-weight-bold">
                    {label}:
                </span>
                <table className="table is-fullwidth is-striped">
                    <thead><tr><th>#</th><th>{label}</th></tr></thead>
                    <tbody>{
                        v.map((x, j) => (<tr key={`tbl-${k}-${i}-${j}`}><td>{j}</td><td>{x}</td></tr>))
                    }</tbody>
                </table>
            </div>);
        }
        return (<div key={`confdiag-${i}-${k}`}>
            <div>
                <span className="has-text-weight-bold">
                    {label}:
                </span> <span>
                    {v}
                </span>
            </div>
        </div>)
    });

    const fullBody = (
        <div>
            <div className="title">{msg}</div>

            <div className="block"></div>

            {body}
        </div>
    );

    return <ConfirmationModal
        active={true}
        confirmationText={fullBody}
        confirmButtonAction={onConfirm}
        cancelButtonAction={onCancel}
        closeButton={false}
    />;
}

function ErrorModal(props = {}) {
    const {
        msg = 'Confirm Submit?',
        onConfirm = () => {},
        onCancel = () => {},
    } = props;

    return <NotificationModal
        active={true}
        confirmationText={msg}
        confirmButtonAction={onConfirm}
        cancelButtonAction={onCancel}
        closeButton={false}
    />;
}


function SquadCreate(props = {}) {
    const {
        isUpdate = false,
    } = props;

    console.debug('Props', props);

    const squadId = props.match.params.squadId;


    const [name, setName] = useState('');
    const [org, setOrg] = useState('');
    const [orgList, setOrgList] = useState([]);

    const [recruits, setRecruits] = useState([]);
    const [selectedRecruits, setSelectedRecruits] = useState([]);
    const [modal, setModal] = useState(null);

    const recruitsList = recruits.map((x) => ({value: x.id, display: x.name}));
    const selectedRecruitsList = selectedRecruits.filter((x) => !!x).map((x) => x.id);
    const submitButtonText = (isUpdate) ? 'Update' : 'Create';

    const onSelectRecruit = (id, alt) => {
        console.debug('Recruits select', id, alt);
        if (alt === 'all') {
            setSelectedRecruits(recruits);
        }
        else {
            const recruit = recruits.find((x) => x.id === id);
            setSelectedRecruits(selectedRecruits.concat(recruit));
        }
    };
    const onUnselectRecruit = (id, alt) => {
        console.debug('Recruits deselect', id, alt);
        if (alt === 'all') {
            setSelectedRecruits([]);
        }
        else {
            setSelectedRecruits(selectedRecruits.filter((x) => x.id === id));
        }
    };

    const onSubmit = () => {
        const data = {
            name,
            org,
            recruits: selectedRecruitsList,
        };

        const labels = {
            name: 'Name',
            org: 'Organization',
            recruits: 'Recruits',
        };

        setModal(<FormConfirmModal
            data={{...data, recruits: selectedRecruits.map((x) => x.name)}}
            labels={labels}
            onConfirm={() => {
                ((isUpdate) ? updateSquad(squadId, data) : createSquad(data)).then((res) => {
                    console.debug('Event create response:', JSON.stringify(res));
                    setModal(null);
                    props.history.goBack();
                }).catch((err) => {
                    setModal(<ErrorModal
                        msg={`Failed to create/update: ${err}`}
                        onConfirm={() => setModal(null)}
                        onCancel={() => setModal(null)}
                    />)
                    console.error('Failed to create/update:', err);
                });
            }}
            onCancel={() => setModal(null)}
        />)

    };

    /*----------------------------------------*/

    useEffect(() => {
        getAllRecruits().then((res) => {
            console.debug('Got recruits', res);
            setRecruits(res);
        }).catch((err) => {
            console.error('Failed to query list of recruits: ', err);
        });

        getOrganizations().then((res) => {
            console.debug('Got Orgs', res);
            setOrgList(res);
        }).catch((err) => {
            console.error('Failed to query list of orgs: ', err);
        });

        if (isUpdate) {
            getSquad(squadId).then((res) => {
                console.debug('Got squad', res);
                setName(res.name);
                setOrg(res.org ?? '');
                setSelectedRecruits(res.recruits);
            }).catch((err) => {
                console.error(`Failed to query squad ${squadId}: `, err);
            });
        }
    }, []);

    /*----------------------------------------*/

    const orgOptList = orgList.map((x, i) => (
        <option key={`org-opt-${i}`} value={x}>{x}</option>)
    )

    return (<div>
        <div className="block"></div>

        <div className="container">
            <div className="field">
                <div className="control">
                    <label className="label">Squad Name</label>
                    <input
                        className="input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                </div>
            </div>

            <div className="field">
                <div className="control">
                    <label className="label">Organization</label>
                    <select
                        name="org"
                        className="input"
                        value={org}
                        onChange={(e) => setOrg(e.target.value)}
                    >
                        <option disabled value="">Select Organization</option>
                        {orgOptList}
                    </select>


                </div>
            </div>

            <SelectList
                label={"Recruits"}
                list={recruitsList}
                selected={selectedRecruitsList}
                onSelect={id => onSelectRecruit(id)}
                onUnselect={id => onUnselectRecruit(id)}
                onSelectAll={() => onSelectRecruit(null, 'all')}
                onUnselectAll={() => onUnselectRecruit(null, 'all')}
            />

            <div className="field is-grouped">
                <div className="control">
                    <Button
                        activeColor='is-success'
                        onClick={onSubmit}
                    >{submitButtonText}</Button>
                </div>
                <div className="control">
                    <Button
                        onClick={() => props.history.goBack()}
                    >Cancel</Button>
                </div>
            </div>
        </div>

        {modal}

    </div>)
}

export default withRouter(SquadCreate);
