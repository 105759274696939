import React from "react";

import PathRouter from "../misc/PathRouter";

import Configuration from "./Configuration";

import DeviceConfiguration from "./device/DeviceConfiguration";
import DeviceEdit from "./device/DeviceEdit";

import RecruitConfiguration from "./recruit/RecruitConfiguration";
import RecruitEdit from "./recruit/RecruitEdit";

import PairConfiguration from "./pair/PairConfiguration";

import EventConfiguration from "./event/EventConfiguration";
import EventEdit from "./event/EventEdit";

import PoiEdit from "./poi/PoiEdit";
import PoiConfiguration from "./poi/PoiConfiguration";

import SquadConfiguration from "./squad/SquadConfiguration";
import SquadCreate from "./squad/SquadCreate";

import PathList from "../../util/path-list";
import CommHubsConfiguration from "./commhubs/CommHubsConfiguration";
import CommHubDetails from "./commhubs/CommHubDetails";

export default class ConfigurationRouter extends React.Component {
    static defaultProps = {
        routes: [
            {
                component: <DeviceEdit isUpdate={false} />,
                route: PathList.deviceCreate,
                exact: true
            },
            {
                component: <DeviceEdit isUpdate={true} />,
                route: PathList.deviceUpdate(),
                exact: true
            },
            {
                component: <DeviceConfiguration />,
                route: PathList.devices
            },
            {
                component: <CommHubDetails />,
                route: PathList.commHubUpdate(),
                exact: true
            },
            {
                component: <CommHubsConfiguration />,
                route: PathList.commHubs
            },
            {
                component: <RecruitEdit isUpdate={false} />,
                route: PathList.recruitAdd,
                exact: true
            },
            {
                component: <RecruitEdit isUpdate={true} />,
                route: PathList.recruitUpdate(),
                exact: true
            },
            {
                component: <RecruitConfiguration />,
                route: PathList.recruits
            },
            {
                component: <PairConfiguration />,
                route: PathList.pair
            },
            {
                component: <EventEdit isUpdate={false} />,
                route: PathList.eventCreate,
                exact: true
            },
            {
                component: <EventEdit isUpdate={true} />,
                route: PathList.eventUpdate(),
                exact: true
            },
            {
                component: <EventConfiguration />,
                route: PathList.events
            },
            {
                component: <PoiEdit isUpdate={false} />,
                route: PathList.poiCreate,
                exact: true
            },
            {
                component: <PoiEdit isUpdate={true} />,
                route: PathList.poiUpdate(),
                exact: true
            },
            {
                component: <PoiConfiguration />,
                route: PathList.pois
            },
            {
                component: <SquadCreate isUpdate={false} />,
                route: PathList.squadCreate,
                exact: true
            },
            {
                component: <SquadCreate isUpdate={true} />,
                route: PathList.squadUpdate(),
                exact: true
            },
            {
                component: <SquadConfiguration />,
                route: PathList.squads
            },
            {
                component: <Configuration />,
                route: PathList.configuration
            }
        ]
    }

    render() {
        return <PathRouter routes={this.props.routes} redirect={PathList.configuration} />;
    }
}
