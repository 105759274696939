import React from "react";

import PathRouter from "../misc/PathRouter";

import PathList from "../../util/path-list";

import Retrospective from "./EventTable";
import EventDownload from "./EventDownload";
import EventPlot from "./EventPlot";
import RetrospectiveDashboard from "./RetrospectiveDashboard";
import Logfile from "../logfile/LogFile";
export default class RetrospectiveRouter extends React.Component {
    static defaultProps = {
        routes: [
            {
                component: <EventDownload />,
                route: PathList.eventDownload(),
                exact: true,
            },
            {
                component: <EventPlot />,
                route: PathList.eventView(),
                exact: true,
            },
            {
                component: <Logfile />,
                route: PathList.logfiles,
            },
            {
                component: <Retrospective />,
                route: PathList.eventTable,
            },
            {
                component: <RetrospectiveDashboard />,
                route: PathList.retrospective
            },
        ],
    }

    render() {
        return <PathRouter routes={this.props.routes} redirect={PathList.retrospective} />;
    }
}
